
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import AlphabetCards from "../../../components/AlphabetCards";
import GeogebraDraw_Temp from "../../../components/GeogebraDraw_Temp";
import VAEComponentBeginner from  "../../../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../../../blocks/VAEComponentGraduate.js";
import MyCard from  "../../../components/MyCard.js";
import SVGArrow from  "../../../components/SVGArrow.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import InteractiveLearningJourney from  "../../../components/InteractiveLearningJourney.js";
import SimpleModalBlockly from  "../../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player'
import { FaHourglassHalf } from 'react-icons/fa';
import AIBanner from "../../../components/AIBanner";
import TwoPartRow from "../../../components/TwoPartRow";
export const beg_topics = [{
  "title": "What's a VAE?",
  "content": "A VAE is like a smart artist: it takes a picture, makes a simple version, and recreates the picture."
}, {
  "title": "Input to Output Journey",
  "content": "VAEs take data, make it basic, then use that to make new, similar things."
}, {
  "title": "Latent Space: VAE's Imagination",
  "content": "Latent space is where a VAE mixes features to create or rebuild data."
}, {
  "title": "VAE in Real Life",
  "content": "VAEs help make video games look real, design new medicines, and create music."
}, {
  "title": "VAEs Simplified",
  "content": "VAEs learn to summarize complex data and then make similar data on their own."
}, {
  "title": "The Role of VAEs in AI",
  "content": "VAEs let machines understand and create new things based on old information."
}];
export const vae_blocks = [{
  "title": "Original Data",
  "content": "The starting point with real-world information that we want to understand or compress."
}, {
  "title": "Model - Data Compressor",
  "content": "Reduces the complexity of data, focusing on essential features."
}, {
  "title": "Simplified Data",
  "content": "The result of compression, holding the core patterns of the original data."
}, {
  "title": "Model - Data Decompressor",
  "content": "Expands simplified data back into a detailed form."
}, {
  "title": "Remember Model",
  "content": "The process that learns to recall or recreate data from its simplified form."
}, {
  "title": "Generated Data",
  "content": "The final output resembling the original data, recreated from the compressed version."
}, {
  "title": "Recall Model",
  "content": "Uses learned patterns to regenerate data during and after training."
}, {
  "title": "Learning Process",
  "content": "The method by which the model improves its compression and recreation over time."
}, {
  "title": "Measure of Error",
  "content": "An assessment of how far the generated data is from the original data."
}, {
  "title": "Loss",
  "content": "A quantification of the difference between the original and generated data, guiding improvement."
}];
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const DecoderVi = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/TRANSFORMERS/VAE_SHERLOCK.mp4' mdxType="ReactPlayer" />
  </div>;
export const goals = [{
  id: 'LG01',
  description: "Introduction to Variational Autoencoders: Get familiar with the concept of Variational Autoencoders (VAEs), their unique characteristics, and their applications in machine learning."
}, {
  id: 'LG02',
  description: "VAE Architecture: Understand the architecture of VAEs, including the encoder, decoder, and the latent space. Learn about the roles of each of these components in the functioning of the VAE."
}, {
  id: 'LG03',
  description: "The Gaussian Distribution: Learn about the Gaussian distribution, its properties, and how it is used to represent data in VAEs. Understand how multivariate Gaussian distribution plays a key role in defining the latent space."
}, {
  id: 'LG04',
  description: "The Reparameterization Trick: Grasp the concept of the reparameterization trick and its role in making backpropagation possible in VAEs. Understand how it introduces randomness in the process."
}, {
  id: 'LG05',
  description: "KL Divergence: Understand the Kullback-Leibler Divergence and how it measures the difference between the learned distribution and the prior distribution in the latent space."
}, {
  id: 'LG06',
  description: "Training a VAE: Learn the entire process of training a VAE, including the role of the optimizer, the computation of loss, and the backpropagation process."
}];
export const ggbParameters = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const topics = [{
  title: 'LEGO',
  component: () => <div style={{
    height: '800px',
    maxHeight: '95vh',
    position: 'relative',
    width: '100%'
  }}>
    <VAEComponentBeginner mdxType="VAEComponentBeginner" /> 
    <div style={{
      position: "absolute",
      top: "10%",
      left: "25%",
      zIndex: 1
    }}>
        <Marker mleft="50%" mtop="25%" text="1" type="video" mdxType="Marker">
            <div>
                <HowlerRead soundSrc="https://storage.googleapis.com/chapters-ifm/ML/one_Sigmoidfunctionis.mp3" mdxType="HowlerRead" />
            </div>
        </Marker>
    </div>
  </div>
}, {
  title: 'What are VAEs',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <br />
        <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/VAE_intro_canva.mp4" title="Introduction to Variational Autoencoders: Powering Generative Models" description="In this segment, we introduce Variational Autoencoders (VAEs), a powerful class of generative models with applications in a variety of domains. We explore the basics of VAEs, its structure, and how it differs from traditional autoencoders. Get a taste of the wide range of applications of VAEs, from image generation and anomaly detection to reinforcement learning and more. Buckle up for an exciting journey into the world of VAEs." link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/intro_thumbnail.png" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Sketching VAE: A Pen-and-Paper Task',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
          <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/familairizew_pen_paper.mp4" title="Mapping the VAE Architecture: An Interactive Exercise" description="We delve into the structure of Variational Autoencoders (VAEs) in this hands-on segment. Follow along as we draw, label, and discuss each primary component of the architecture, including the dimensions of data flowing through the system. Get ready to gain a deeper understanding of the latent space and its role in VAEs." link="" mdxType="VideoMediaCard" />
                <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/KL_Divergence_label.mp4" title="A Brief Introduction: KL Divergence, Reconstruction Error, and Learned Weights in VAEs" description="In this quick segment, we place markers on the important elements of our VAE network - KL Divergence, Reconstruction Error, and learned weights. Stay tuned as we prepare to delve deeper into these in upcoming discussions." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: "Multivariate Gaussian Distributions",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
   <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/multivariate_distribution.mp4" title="Visualizing Multivariate Gaussian: Understanding VAE's μ and σ Vectors" description="Join us as we dive into the world of bivariate Gaussian distributions, exploring how changes in μ and σ vectors shape our Gaussian landscapes. Through this understanding, you'll gain insights into the critical role these parameters play within the Variational Autoencoder (VAE) architecture." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Encoder',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/encoder.m4v" title="Visualizing Multivariate Gaussian: Understanding VAE's μ and σ Vectors" description="Join us as we dive into the world of bivariate Gaussian distributions, exploring how changes in μ and σ vectors shape our Gaussian landscapes. Through this understanding, you'll gain insights into the critical role these parameters play within the Variational Autoencoder (VAE) architecture." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Resampling/Reparameterization trick',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
              <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/reparameterization.m4v" title="VReparameterization: Adding Randomness & Facilitating Backpropagation" description="Discover the critical role of the reparameterization trick in Variational Autoencoders. Learn how it introduces necessary randomness into our model, leading to robust and versatile representations in the latent space. Stay tuned as we delve into a practical example in our upcoming segment!" link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Decoder/Reconstructor',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
              <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/Decoder_video.m4v" title="Decoder" description="Discover the critical role of the reparameterization trick in Variational Autoencoders. Learn how it introduces necessary randomness into our model, leading to robust and versatile representations in the latent space. Stay tuned as we delve into a practical example in our upcoming segment!" link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'KL Divergence/Posterior Collapse',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
                  <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/KLD_example.m4v" title="Kullback-Leibler Divergence in VAEs" description="The mathematics behind the Kullback-Leibler Divergence in Variational Autoencoders and how it aligns the learned distribution to a standard normal distribution, with a simplified one-dimensional example." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'The Training Loop',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
                      <br />
   <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/training_loop.m4v" title="The Training Loop: Where Learning Happens" description="Exploring the essentials of the training loop in Variational Autoencoders - iterating over data batches, passing images through the model, calculating the loss, and updating the model's weights. This iterative process is at the core of training a VAE model." link="" mdxType="VideoMediaCard" />
  </div>
}];
export const alphabetWordsVAEPost = [{
  letter: 'K',
  words: ['KL Divergence in VAEs'],
  quiz: [{
    question: 'What is the role of KL Divergence in VAE?',
    options: ['It measures the similarity between two distributions', 'It helps in optimizing the weights of the network', 'It provides a way to regularize the encoder network', 'All of the above'],
    answer: 'All of the above'
  }, {
    question: 'What does a lower KL Divergence value signify?',
    options: ['The two distributions are similar', 'The two distributions are different', 'The network is not trained properly', 'None of the above'],
    answer: 'The two distributions are similar'
  }]
}, {
  letter: 'R',
  words: ['Reparameterization Trick in VAEs'],
  quiz: [{
    question: 'Why is the reparameterization trick important in VAEs?',
    options: ['It makes the backpropagation through random nodes possible', 'It helps to regularize the encoder network', 'It helps in reducing the KL Divergence', 'None of the above'],
    answer: 'It makes the backpropagation through random nodes possible'
  }, {
    question: 'What does the reparameterization trick introduce in the process of training VAEs?',
    options: ['Optimization', 'Randomness', 'Overfitting', 'Underfitting'],
    answer: 'Randomness'
  }]
}, {
  letter: 'L',
  words: ['Latent Space in VAEs'],
  quiz: [{
    question: 'What is the role of Latent Space in a VAE?',
    options: ['It encodes the input data', 'It represents the data in a lower-dimensional space', 'It helps to compute the loss', 'Both A and B'],
    answer: 'Both A and B'
  }, {
    question: 'In VAEs, what does the latent space contain?',
    options: ['Encoded representation of the input', 'Decoded representation of the output', 'Both A and B', 'None of the above'],
    answer: 'Encoded representation of the input'
  }]
}, {
  letter: 'T',
  words: ['Training a VAE'],
  quiz: [{
    question: 'What is the primary objective during the training of a VAE?',
    options: ['Minimizing the KL Divergence', 'Maximizing the likelihood of the data', 'Both A and B', 'None of the above'],
    answer: 'Both A and B'
  }, {
    question: 'What role does an optimizer play in training a VAE?',
    options: ['It helps to compute the loss', 'It helps to update the weights', 'Both A and B', 'None of the above'],
    answer: 'It helps to update the weights'
  }]
}
// Other letters can follow a similar pattern, each one focused on a different aspect of VAEs
];
export const alphabetWordsVAE = [{
  letter: 'A',
  words: ['Applications of VAEs'],
  quiz: [{
    question: 'What are some potential applications of VAEs?',
    options: ['Generating synthetic data', 'Image recognition', 'Audio processing', 'All of the above'],
    answer: 'All of the above'
  }, {
    question: 'What makes VAEs especially suitable for generating synthetic data?',
    options: ['Their architecture', 'Their ability to model complex, multi-modal data', 'Their training process', 'All of the above'],
    answer: 'All of the above'
  }]
}, {
  letter: 'B',
  words: ['Backpropagation in VAEs'],
  quiz: [{
    question: 'What makes backpropagation possible in VAEs?',
    options: ['The Reparameterization Trick', 'The architecture of VAEs', 'The Gaussian Distribution', 'The KL Divergence'],
    answer: 'The Reparameterization Trick'
  }, {
    question: 'Why is backpropagation important in training VAEs?',
    options: ['It updates the weights', 'It reduces loss', 'It increases accuracy', 'All of the above'],
    answer: 'All of the above'
  }]
}, {
  letter: 'C',
  words: ['Components of a VAE'],
  quiz: [{
    question: 'What are the main components of a VAE?',
    options: ['Encoder and Decoder', 'Optimizer and Loss Function', 'Latent Space', 'All of the above'],
    answer: 'All of the above'
  }, {
    question: 'What is the role of the latent space in a VAE?',
    options: ['It encodes the input data', 'It decodes the encoded data', 'It represents the data in a lower-dimensional space', 'It computes the loss'],
    answer: 'It represents the data in a lower-dimensional space'
  }]
}, {
  letter: 'G',
  words: ['Gaussian Distribution in VAEs'],
  quiz: [{
    question: 'Why does VAE use Gaussian Distribution?',
    options: ['It helps in generating random numbers', 'It represents data in latent space', 'Both A and B', 'None of the above'],
    answer: 'Both A and B'
  }, {
    question: 'What role does multivariate Gaussian distribution play in VAE?',
    options: ['It helps to model the correlations between different features in the latent space', 'It helps to improve the efficiency of VAE', 'It helps to reduce the loss during training', 'None of the above'],
    answer: 'It helps to model the correlations between different features in the latent space'
  }]
}, {
  letter: 'K',
  words: ['KL Divergence in VAEs'],
  quiz: [{
    question: 'What is the role of KL Divergence in VAE?',
    options: ['It measures the similarity between two distributions', 'It helps in optimizing the weights of the network', 'It provides a way to regularize the encoder network', 'All of the above'],
    answer: 'All of the above'
  }, {
    question: 'What does a lower KL Divergence value signify?',
    options: ['The two distributions are similar', 'The two distributions are different', 'The network is not trained properly', 'None of the above'],
    answer: 'The two distributions are similar'
  }]
}, {
  letter: 'R',
  words: ['Reparameterization Trick in VAEs'],
  quiz: [{
    question: 'Why is the reparameterization trick important in VAEs?',
    options: ['It makes the backpropagation through random nodes possible', 'It helps to regularize the encoder network', 'It helps in reducing the KL Divergence', 'None of the above'],
    answer: 'It makes the backpropagation through random nodes possible'
  }, {
    question: 'What does the reparameterization trick introduce in the process of training VAEs?',
    options: ['Optimization', 'Randomness', 'Overfitting', 'Underfitting'],
    answer: 'Randomness'
  }]
}, {
  letter: 'L',
  words: ['Latent Space in VAEs'],
  quiz: [{
    question: 'What is the role of Latent Space in a VAE?',
    options: ['It encodes the input data', 'It represents the data in a lower-dimensional space', 'It helps to compute the loss', 'Both A and B'],
    answer: 'Both A and B'
  }, {
    question: 'In VAEs, what does the latent space contain?',
    options: ['Encoded representation of the input', 'Decoded representation of the output', 'Both A and B', 'None of the above'],
    answer: 'Encoded representation of the input'
  }]
}, {
  letter: 'T',
  words: ['Training a VAE'],
  quiz: [{
    question: 'What is the primary objective during the training of a VAE?',
    options: ['Minimizing the KL Divergence', 'Maximizing the likelihood of the data', 'Both A and B', 'None of the above'],
    answer: 'Both A and B'
  }, {
    question: 'What role does an optimizer play in training a VAE?',
    options: ['It helps to compute the loss', 'It helps to update the weights', 'Both A and B', 'None of the above'],
    answer: 'It helps to update the weights'
  }]
}
// Other letters can follow a similar pattern, each one focused on a different aspect of VAEs
];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const SomeComponent = makeShortcode("SomeComponent");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const Marker = makeShortcode("Marker");
const HowlerRead = makeShortcode("HowlerRead");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Header = makeShortcode("Header");
const ImgMediaCard = makeShortcode("ImgMediaCard");
const layoutProps = {
  beg_topics,
vae_blocks,
ggbParameters2,
DecoderVi,
goals,
ggbParameters,
opts,
topics,
alphabetWordsVAEPost,
alphabetWordsVAE
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <br />
    <br />

    <AIBanner titleLine1="Variational AutoEncoder" titleLine2="" description="VAEs' take large, complex data and distill it into its essence, much like compressing a thick book into a compelling summary" mdxType="AIBanner">
  {/* This is the additional content that will be shown when 'Show More' is clicked */}
  <div>
  <TwoPartRow cardsContent={beg_topics} mdxType="TwoPartRow" />
  </div>
    </AIBanner>
    <SomeComponent mdxType="SomeComponent" />
    <br />

    <br />
    <br />
    <h1>{`Start`}</h1>
    <br />
    <InteractiveLearningJourney time={5} arrowColor="green" mdxType="InteractiveLearningJourney" />
    <h1 style={{
      color: "green"
    }}>1. Exploration Stage </h1>
    <AIBanner titleLine1="Step1" titleLine2="VAE Components" description="A warmup exercise" mdxType="AIBanner">
  <div>
  <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/ML/2023/VAE/beginner_vae_blocks.mp4" title="Replicate this" description="" link="" thumbnail="" mdxType="VideoMediaCard" />
  <TwoPartRow cardsContent={vae_blocks} mdxType="TwoPartRow" />
  </div>
    </AIBanner>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      // this will give space between your markers
      alignItems: 'center',
      backgroundColor: 'transparent',
      // making the background transparent
      color: 'white',
      // text color
      padding: '20px',
      // inner padding
      overflowY: 'auto',
      // add a scrollbar when content is too tall
      position: 'relative',
      // make the div position relative
      height: '80px',
      // set the height of the div
      width: '500px'
    }}>
      <Marker left="50%" top="20%" mleft="50%" mtop="25%" text="1" title="Task" mdxType="Marker">
  <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
    <HowlerRead soundSrc="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/_VariationalAutoencoders%2C.mp3" mdxType="HowlerRead" />
    <img src="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VAE_QUIZ.png" alt="Quiz Image" style={{
            width: '450px',
            height: '400px',
            marginTop: '20px'
          }} />
  </div>
  </Marker>
    </div>
    <div style={{
      width: '81%',
      height: '100vh',
      backgroundColor: '#424242',
      // dark grey
      color: 'white',
      // text color
      margin: 'auto',
      // center the box
      padding: '20px',
      // inner padding
      overflowY: 'auto',
      // add a scrollbar when content is too tall
      position: 'relative'
    }}>
  <VAEComponentBeginner mdxType="VAEComponentBeginner" />
    </div>
    <InteractiveLearningJourney time={3} arrowColor="green" mdxType="InteractiveLearningJourney" />
    <h1 style={{
      color: "orange"
    }}>2. Development Stage</h1>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
  <TimeEstimate time={3} mdxType="TimeEstimate" />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
    <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/slide-1_3.m4v" title="Introduction to Variational Autoencoders: Powering Generative Models" description="AVE structure, and how it differs from traditional autoencoders.Applications of VAEs, from image generation and anomaly detection to reinforcement learning and more." link="" thumbnail="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/intro_thumbnail.png" mdxType="VideoMediaCard" />
      </div>
      <br />
      <br />
      <br />
 <SVGArrow color='orange' width='200px' mdxType="SVGArrow" />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
    <br />
         <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/familairizew_pen_paper.mp4" title="Mapping the VAE Architecture: An Interactive Exercise" description="We delve into the structure of Variational Autoencoders (VAEs) in this hands-on segment. Follow along as we draw, label, and discuss each primary component of the architecture, including the dimensions of data flowing through the system. Get ready to gain a deeper understanding of the latent space and its role in VAEs." link="" mdxType="VideoMediaCard" />
      </div>
      <br />
      <br /> 
      <br />
      <br />
 <SVGArrow color='orange' width='200px' mdxType="SVGArrow" />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
    <br />
            <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/encoder.m4v" title="Visualizing Multivariate Gaussian: Understanding VAE's μ and σ Vectors" description="Data compressor with neural networks." link="" mdxType="VideoMediaCard" />
      </div>
      <br />
      <br />
      <br />
 <SVGArrow color='orange' width='200px' mdxType="SVGArrow" />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/reparameterization.m4v" title="Reparameterization: Adding Randomness & Facilitating Backpropagation" description="Discover the critical role of the reparameterization trick in Variational Autoencoders. Learn how it introduces necessary randomness into our model, leading to robust and versatile representations in the latent space. Stay tuned as we delve into a practical example in our upcoming segment!" link="" mdxType="VideoMediaCard" />
 <br />
      <br />
      <br />
      <br />
      <br />
 <SVGArrow color='orange' width='200px' mdxType="SVGArrow" />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <div style={{
        position: 'relative',
        width: '100%'
      }}>
  <br />
  <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/multivariate_distribution.mp4" title="Discovering the 3D Gaussian Bell Curve" description="Exploring how means and standard deviations shape multivariate Gaussian distributions. Watch the landscape of hills transform as we adjust the parameters in real-time. This forms the foundation for understanding latent space distributions in Variational Autoencoders." link="/multi_gaussian" mdxType="VideoMediaCard" />
  <br />
      </div>
 <SVGArrow color='orange' width='200px' mdxType="SVGArrow" />
      <br />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
    <br />
             <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/Decoder_video.m4v" title="Decoder" description="Translation: Regerenate data" link="" mdxType="VideoMediaCard" />
      </div>
 <br />
      <br />
 <SVGArrow color='orange' width='200px' mdxType="SVGArrow" />
      <br />
    <TimeEstimate time={3} mdxType="TimeEstimate" />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
    <br />
           <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/KL_Divergence_label.mp4" title="A Brief Introduction: KL Divergence, Reconstruction Error, and Learned Weights in VAEs" description="Translation: Compare distributions." link="/kl_divergence" mdxType="VideoMediaCard" />
   
      </div>
      <h1 style={{
        color: "red"
      }}>3. Mastery Stage </h1>
 <br />
      <br />
 <SVGArrow color='red' width='200px' mdxType="SVGArrow" />
      <br />
   <TimeEstimate time={3} mdxType="TimeEstimate" />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
    <br />
          <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/VAE/training_loop.m4v" title="The Training Loop: Where Learning Happens" description="Learning : Calculating the loss, and updating the model's weights." link="" mdxType="VideoMediaCard" />
      </div>
 <br />
 <br />
      <br />
 <SVGArrow color='red' width='200px' mdxType="SVGArrow" />
      <br />
  <TimeEstimate time={5} mdxType="TimeEstimate" />


 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/TRANSFORMERS/VAE_geogebra.json" ggbParameters={ggbParameters2} appId="id_vae_play" email="pavanmirla@gmail.com" data={[{
          component: <DecoderVi mdxType="DecoderVi" />,
          button: "vbutton"
        }]} mdxType="GeogebraDraw" /> 
  </div>
 <br />
 <br />
      <br />
 <SVGArrow color='red' width='200px' mdxType="SVGArrow" />
      <br />
    </div>
    <br />
    <br />
    <br />
    <br />





    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Header mdxType="Header"> PUT YOUR VAE KNOWLEDGE TO TEST! </Header>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        // this will give space between your markers
        alignItems: 'center',
        backgroundColor: 'transparent',
        // making the background transparent
        color: 'white',
        // text color
        padding: '20px',
        // inner padding
        overflowY: 'auto',
        // add a scrollbar when content is too tall
        position: 'relative',
        // make the div position relative
        height: '80px',
        // set the height of the div
        width: '500px'
      }}>
        <Marker left="15%" top="20%" mleft="50%" mtop="25%" text="1" title="Task" mdxType="Marker">
  <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
    <HowlerRead soundSrc="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/_Welcomeback.mp3" mdxType="HowlerRead" />
    <img src="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VAE-Quiz_Expert.png" alt="Quiz Image" style={{
              width: '450px',
              height: '400px',
              marginTop: '20px'
            }} />
  </div>
  </Marker>
      </div>
      <div style={{
        width: '81%',
        height: '100vh',
        backgroundColor: '#424242',
        // dark grey
        color: 'white',
        // text color
        margin: 'auto',
        // center the box
        padding: '20px',
        // inner padding
        overflowY: 'auto',
        // add a scrollbar when content is too tall
        position: 'relative'
      }}>
        <VAEComponentGraduate mdxType="VAEComponentGraduate" />
      </div>
      <br />
      <br />
      <br />
      <br />
 <SVGArrow color='red' width='200px' mdxType="SVGArrow" />
      <br />
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
  <div style={{
        position: 'relative',
        width: '100%'
      }}>
 <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/covid_images.png" title="Real world use case for VAE." description="Detailed Pytorch code walkthrough. Data preparation to Model Inference for Covid cases anamoly detection " link="" mdxType="ImgMediaCard" />
      </div>
 <br />
 <br />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;