import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  titleSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  titleText: {
    fontWeight: 'bold',
    // color: theme.palette.secondary.light,
    color: '#333'
  },
}));

const TitleSection = ({ title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.titleSection}>
      <Typography variant="h5" className={classes.titleText}>
        {title}
      </Typography>
    </Box>
  );
};

export default TitleSection;
