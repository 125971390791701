
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import AlphabetCards from "../../../components/AlphabetCards";
import GeogebraDraw_Temp from "../../../components/GeogebraDraw_Temp";
import VAEComponentBeginner from  "../../../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../../../blocks/VAEComponentGraduate.js";
import MyCard from  "../../../components/MyCard.js";
import SVGArrow from  "../../../components/SVGArrow.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import SimpleModalBlockly from  "../../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player'
import { FaHourglassHalf } from 'react-icons/fa';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <br />
    <iframe src="https://www.chatbase.co/chatbot-iframe/FNeT6QIqcdbuutoA-dKWe" width="100%" style={{
      height: "100%",
      minHeight: "700px"
    }} frameBorder="0"></iframe>
    <br />
    <br />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;