
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import AlphabetCards from "../../../components/AlphabetCards";
import GeogebraDraw_Temp from "../../../components/GeogebraDraw_Temp";
import VAEComponentBeginner from  "../../../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../../../blocks/VAEComponentGraduate.js";
import MyCard from  "../../../components/MyCard.js";
import SVGArrow from  "../../../components/SVGArrow.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import SimpleModalBlockly from  "../../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player'
import AIBanner from "../../../components/AIBanner.js";
import FlipCardGrid from "../../../components/FlipCardGrid.js";
import { FaHourglassHalf } from 'react-icons/fa';
import MetaTags from '../../../components/MetaTags';
import StripeBuyButton from "../../../components/StripeBuyButton.js";
import PaymentPage from "../../../components/PaymentPage.js";
export const cardsContent = [{
  "title": "What are Bond Yields?",
  "content": "Bond yields represent the return an investor will receive by holding the bond to maturity. It is essentially the amount of interest paid by bond issuers to bondholders."
}, {
  "title": "Bond Yields Determination",
  "content": "Bond yields reflect economic health, expected inflation, and future interest rates."
}, {
  "title": "Federal Funds Rate Meaning",
  "content": "It's the overnight lending rate set by the central bank, crucial for monetary policy."
}, {
  "title": "Term Structure of Interest Rates",
  "content": "Yield curves represent bond yields across different maturities; short-term yields resonate with current central bank rates, while long-term yields reflect inflation and growth expectations."
}, {
  "title": "Expectations Impact on Bond Yields",
  "content": "Long-term bond yields rise with expectations of future inflation or higher short-term rates."
}, {
  "title": "Federal Funds Rate Expectations",
  "content": "Set by central banks based on future inflation, employment predictions, and broader economic objectives."
}, {
  "title": "Economic Indicators' Influence",
  "content": "Strong economic data can lead to higher bond yields, signaling expected inflation and tighter monetary policy."
}, {
  "title": "Supply and Demand Dynamics",
  "content": "High demand for bonds like U.S. Treasuries leads to higher prices and lower yields."
}, {
  "title": "Federal Funds Rate & Demand",
  "content": "Supply and demand can affect the actual federal funds rate, but the target is set by the central bank."
}, {
  "title": "Role of External Factors",
  "content": "Global conditions, geopolitical events, and worldwide interest rates can sway both bond yields and federal funds rate decisions."
}, {
  "title": "Inflation's Influence on Bonds",
  "content": "Expected inflation hikes lead to rising bond yields, ensuring real returns for investors."
}, {
  "title": "Federal Funds Rate & Inflation",
  "content": "Central banks tweak this rate to maintain a target inflation level."
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoMediaCard = makeShortcode("VideoMediaCard");
const ImgMediaCard = makeShortcode("ImgMediaCard");
const layoutProps = {
  cardsContent
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <br />
    <MetaTags title="Our Misison" description="is to Democratizing Financial Knowledge" imageUrl="https://storage.googleapis.com/chapters-ifm/YieldCurves4/yield_curves.png" pageUrl="https://perceptron.solutions/yield_curves" mdxType="MetaTags" />
    <br />
 <AIBanner titleLine1="Our Misison" titleLine2="is to Democratize Financial Knowledge" description="Every individual, regardless of their background, should have access to clear and comprehensive market insights." mdxType="AIBanner" />
    <br />
    <br />
    <br />
    <br />
    <br />
  <div style={{
      position: 'relative',
      width: '100%'
    }}>
    <br />
            <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/PATREON/perceptron_markets.mp4" title="Introduction" description="" link="" mdxType="VideoMediaCard" />
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <StripeBuyButton mdxType="StripeBuyButton" />

 <AIBanner titleLine1="Examples" titleLine2="" description="" mdxType="AIBanner" />
    <br />
    <br />
    <br />
  <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/PATREON/yield_curves_intro_2.jpg" title="Derivatives of Activation Functions" description="A curated visual exploration of economic shifts, as told by annual yield curves and federal fund rates from 1980 to 2023. " link="yield_curves" mdxType="ImgMediaCard" />
    <br />
    <br />
    <br />
 <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/puts_slide.png" title="Brief Introduction to Put Option. Puts 101" description="Interactive that shows Put option from the buyer and seller perspetive. Option exercise and assignment are explained with interactive explainations" link="intro_puts" mdxType="ImgMediaCard" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;