import React from 'react';

const RedirectButton = ({ url, label }) => {
  const handleRedirect = () => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  // Styles for the button
  const buttonStyle = {
    backgroundColor: '#007BFF',  // Bootstrap's primary blue
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    boxShadow: '0 2px 5px rgba(0, 123, 255, 0.5)',  // Slight shadow for depth
    outline: 'none',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.2s ease-in-out',
  };

  // Styles for the button container
  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  };

  return (
    <div style={buttonContainerStyle}>
      <button style={buttonStyle} onClick={handleRedirect}>
        {label}
      </button>
    </div>
  );
};

export default RedirectButton;
