import React from 'react';
import styled from 'styled-components';
import Highlight from 'react-highlight';
import 'highlight.js/styles/monokai.css'; // You can change the style as needed

const CodeWrapper = styled.div`
    font-family: 'Source Code Pro', monospace;
    color: #fafafa;
    padding: 20px;
    border-radius: 1px;
    display: block;
    overflow-x: auto;
    margin-right: 25px;
    margin-left: 25px;
    white-space: pre-wrap; // Enable line wrapping
    word-wrap: break-word; // Break long words
`;

const CodeBlock = ({ language, children }) => {
  return (
    <CodeWrapper>
      <Highlight language={language}>
        {children}
      </Highlight>
    </CodeWrapper>
  );
};

export default CodeBlock;
