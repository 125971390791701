import React from 'react';
import App from '../utilities/App';

const topics = ["Lymphatic system", "Skeletal system", "Muscles", "Heart", "Blood vessels", "Respiratory system", "Beginner quiz", "Intermediate Quiz"];

function AnatomyApp() {
  return <App topics={topics} baseRoute="anatomy" />;
}

export default AnatomyApp;
