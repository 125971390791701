import React, { useState } from 'react';

export const StudentInfoComponent = ({ addBlock }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [question, setQuestion] = useState('');
    const [category, setCategory] = useState('General');
    const categories = ['General', 'Admission', 'Coursework', 'Timetable', 'Other'];

    const handleSubmit = () => {
      addBlock(`Name: ${name}`, 'user');
      addBlock(`Email: ${email}`, 'user');
      addBlock(`Question: ${question}`, 'user');
      addBlock(`Category: ${category}`, 'user');
      addBlock('Thanks for providing the information. How can I assist you further?', 'system');
    };

    return (
      <div style={{ padding: '20px', borderRadius: '10px', boxShadow: '0px 0px 15px rgba(0,0,0,0.1)', maxWidth: '400px', margin: '0 auto' }}>
        <input 
          type="text" 
          placeholder="Name" 
          value={name} 
          onChange={(e) => setName(e.target.value)}
          style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }} 
        />
        <input 
          type="email" 
          placeholder="Email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }} 
        />
        <textarea 
          placeholder="What do you want to ask?" 
          value={question} 
          onChange={(e) => setQuestion(e.target.value)}
          rows="4"
          style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }}
        />
        <select 
          value={category} 
          onChange={(e) => setCategory(e.target.value)}
          style={{ width: '100%', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ccc' }}>
          {categories.map((cat) => (
            <option key={cat} value={cat}>
              {cat}
            </option>
          ))}
        </select>
        <button onClick={handleSubmit} style={{ background: '#007BFF', color: '#ffffff', padding: '10px 20px', borderRadius: '5px', border: 'none', cursor: 'pointer', boxShadow: '0px 0px 10px rgba(0, 120, 255, 0.2)' }}>Submit</button>
      </div>
    );
};
