// FeedbackContent.js
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './FeedbackContent.css'; // Make sure to create this CSS file for additional styling

const FeedbackContent = ({ text, imageUrl, linkUrl, linkText, videoUrl }) => {
  const [openFullScreen, setOpenFullScreen] = useState(false); // State to handle full-screen mode

  const handleImageClick = () => {
    setOpenFullScreen(true); // Open the image in full-screen mode
  };

  const handleClose = () => {
    setOpenFullScreen(false); // Close the full-screen view
  };

  return (
    <div className="feedback-content">
      {text && <p>{text}</p>}
      {imageUrl && (
        <>
          <img src={imageUrl} alt="Feedback" onClick={handleImageClick} style={{ cursor: 'zoom-in' }} />
          <p className="click-to-enlarge">Click to enlarge</p>
        </>
      )}
      {linkUrl && linkText && <a href={linkUrl}>{linkText}</a>}
      {videoUrl && (
        <video controls>
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {/* Full-screen image dialog */}
      <Dialog
        open={openFullScreen}
        onClose={handleClose}
        aria-labelledby="full-screen-image-dialog"
        fullWidth
        maxWidth="lg"
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: '10px', top: '10px', color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        <img src={imageUrl} alt="Enlarged feedback" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </div>
  );
};

export default FeedbackContent;
