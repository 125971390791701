import React, { useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { useWindowWidth, useWindowHeight } from '@wojtekmaj/react-hooks';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFDocumentWrapper = styled.div`
  display: flex;
  justify-content: center; // Center the canvas horizontally
  align-items: center; // Center the canvas vertically
  height: calc(100vh - 100px); // Adjust height based on your navigation wrapper height
`;

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  // background-color: #f8f9fa; // Light grey background, change as needed
  // border-radius: 5px; // Optional rounded corners
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional subtle shadow
  margin-bottom: 20px; // Ensure some space between navigation and content
  // z-index could be set here if needed for layering purposes
  z-index: 1000; // Example z-index, only necessary if there's an overlapping issue
  position: relative; // z-index works on positioned elements (positioned != static)
`;


const DownloadWrapper = styled.div`
  text-align: center; // Center the button text
  margin-top: 20px; // Add margin on top for spacing
  
  & > a {
    display: inline-block; // Make the anchor inline-block to center it
    text-decoration: none; // Remove text decoration
    
    & > button {
      padding: 10px 20px; // Add some padding to the button
      border: none; // Optional: remove the border
      background-color: #007bff; // Use your desired button color
      color: white; // Button text color
      border-radius: 5px; // Optional: rounded corners
      cursor: pointer; // Change cursor on hover
      font-size: 16px; // Adjust font size as needed
    }
  }
`;


export default function LoadPdf(props) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const windowHeight = useWindowHeight();

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    function previousPage() {
      setPageNumber(pageNumber - 1);
    }

    function nextPage() {
      setPageNumber(pageNumber + 1);
    }

    const NavigationButtons = () => (
      <NavigationWrapper>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </NavigationWrapper>
    );

    // Calculate the scale based on the window height
    const scale = windowHeight ? (windowHeight / 1) / 792 : 1; // 792 is an average height of a PDF page, adjust the divisor for your PDF

    return (
      <div>
          {numPages && <NavigationButtons />} 
          <PDFDocumentWrapper>
              <Document
                file={props.file}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={"Please wait..."}
              >
                <Page pageNumber={pageNumber} scale={scale} />
              </Document>
          </PDFDocumentWrapper>
          {numPages && <NavigationButtons />} 
          <DownloadWrapper>
            <a href={props.file} download>
              <button type="button">Download PDF</button>
            </a>
           </DownloadWrapper>
      </div>
  );
}
