import React from 'react';
import "./markdown.css"
const MarkdownWithVideo = ({ htmlContent, videoSrc }) => {
  return (
    <div className="markdown-with-video">
      <div
        className="markdown-content"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
      <video className="video-content" controls src={videoSrc}></video>
    </div>
  );
};

export default MarkdownWithVideo;
