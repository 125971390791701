import React, { useState, useContext } from 'react';
import { Card, CardContent, Typography, Grid, Modal, Button, Box } from '@material-ui/core';
import { createMuiTheme, ThemeProvider, styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


const theme = createMuiTheme({
  palette: {
    mode: 'light',
  },
});

const ScoreContext = React.createContext();

const StyledModal = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

function QuizModal({ open, handleClose, quiz }) {
  const [attempted, setAttempted] = useState(quiz.map(() => false)); // Track attempted questions
  const { updateGlobalScore } = useContext(ScoreContext);

  const handleAnswer = (selectedOption, correctAnswer, index) => {
    if (!attempted[index]) {
      setAttempted(prevAttempted => {
        const newAttempted = [...prevAttempted];
        newAttempted[index] = true;
        return newAttempted;
      });

      if (selectedOption === correctAnswer) {
        updateGlobalScore(true);
      } else {
        updateGlobalScore(false);
      }
    }
  }

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
    >
      <Box bgcolor="black" p={3} color="white" style={{ position: 'relative' }}>
      <IconButton 
          onClick={handleClose} 
          style={{ 
            color: 'white', 
            position: 'absolute', 
            right: '10px', // Adjust this value as needed
            top: '10px' // Adjust this value as needed
          }}
        >
          <CloseIcon />
        </IconButton>
    
        {quiz.map((q, index) => (
          <div key={index}>
            <Typography variant="h6" style={{ color: 'white' }}>{q.question}</Typography>
            {q.options.map((option, indexOption) => (
            <Button 
            key={indexOption} 
            variant="outlined" 
            color="primary" 
            onClick={() => handleAnswer(option, q.answer, index)}
            disabled={attempted[index]}
            style={{ 
              margin: '5px', 
              textTransform: 'none', 
              color: attempted[index] ? 'gray' : '#90caf9' // Changes color to gray if the question has been attempted
            }}
          >
            {option}
          </Button>
          
            ))}
          </div>
        ))}
      </Box>
    </StyledModal>
  );
}

function LetterCard({ letter, words, quiz }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid item xs={6} sm={4} md={3} lg={2} xl={1}>
      <Card onClick={handleOpen} style={{ backgroundColor: '#424242' }}>
        <CardContent>
          <Typography variant="h4" component="h2" style={{ color: 'white' }}>{letter}</Typography>
          <hr />
          <Typography variant="body2" component="p">
            {words.map((word, index) => (<div key={index} style={{ color: 'white' }}>{word}</div>))}
          </Typography>
        </CardContent>
      </Card>
      {quiz && <QuizModal open={open} handleClose={handleClose} quiz={quiz} />}
    </Grid>
  );
}

function AlphabetCards({ alphabetWords }) {
  const [globalScore, setGlobalScore] = useState({ correct: 0, incorrect: 0 });

  const updateGlobalScore = (isCorrect) => {
    if (isCorrect) {
      setGlobalScore(prevScore => ({ ...prevScore, correct: prevScore.correct + 1 }));
    } else {
      setGlobalScore(prevScore => ({ ...prevScore, incorrect: prevScore.incorrect + 1 }));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <ScoreContext.Provider value={{ updateGlobalScore }}>
        <Typography 
          variant="h5" 
          style={{ color: 'white', marginTop: '20px', marginBottom: '20px', lineHeight: '1.5', textAlign: 'center' }}
        >
          Score: 
          <br /> 
          Correct - {globalScore.correct}
          <br />
          Incorrect - {globalScore.incorrect}
        </Typography>
        <Grid container spacing={2}>
          {alphabetWords.map(({letter, words, quiz}) => 
            <LetterCard letter={letter} words={words} quiz={quiz} key={letter} />
          )}
        </Grid>
      </ScoreContext.Provider>
    </ThemeProvider>
  );
}


export default AlphabetCards;
