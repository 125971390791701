import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PaymentContext from './paymentContext'; // Update this path to your actual PaymentContext path

function PrivateRoute({ children, ...rest }) {
  const { paymentFlag } = useContext(PaymentContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        paymentFlag ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/payment",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
