import React from 'react';

const LinkedInEmbed = ({ src, height, title }) => {
  return (
    <div style={{ 
      width: '100%', 
      maxWidth: '600px', 
      margin: '20px auto', 
      padding: '20px', 
      backgroundColor: '#fff', 
      border: '1px solid #ddd', 
      borderRadius: '8px', 
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' 
    }}>
      <iframe 
        src={src} 
        height={height} 
        width="100%" 
        frameBorder="0" 
        allowFullScreen 
        title={title} 
        style={{ width: '100%', borderRadius: '8px' }} 
      ></iframe>
      <p style={{ textAlign: 'center', marginTop: '10px' }}>
        <a href={src.replace('embed/', '')} target="_blank" rel="noopener noreferrer" style={{ color: '#0073b1', textDecoration: 'none', fontWeight: 'bold' }}>
          View Comments on LinkedIn
        </a>
      </p>
    </div>
  );
}

export default LinkedInEmbed;
