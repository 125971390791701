import React, { useState, useEffect } from 'react';
import firebase from "../firebase/firebaseIndex";
import PaymentContext from './paymentContext';

const PaymentProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [errors, setErrors] = useState([]);
  const [paymentFlag, setPaymentFlag] = useState(null);

  useEffect(() => {
    // Listener for authentication state
    const unsubscribeAuth = firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        setUser(null);
        setPaymentFlag(null);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    // Check payment status when the user state changes and is not null
    if (user) {
      // First, try to get the payment status from localStorage
      const cachedPaymentFlag = localStorage.getItem('paymentFlag');

      if (cachedPaymentFlag !== null) {
        setPaymentFlag(cachedPaymentFlag === 'true');
      } else {
        checkPaymentStatus(user.email);
      }
    }
  }, [user]);

  const checkPaymentStatus = async (email) => {
    try {
      const db = firebase.firestore();
      const paymentSnapshot = await db.collection("payments")
                                      .where('email', '==', email)
                                      .limit(1)
                                      .get();

      if (!paymentSnapshot.empty) {
        setPaymentFlag(true);
        localStorage.setItem('paymentFlag', 'true');
      } else {
        setPaymentFlag(false);
        localStorage.setItem('paymentFlag', 'false');
      }
    } catch (error) {
      setErrors(prevErrors => [...prevErrors, "Error checking payment status: " + error.message]);
    }
  };

  return (
    <PaymentContext.Provider value={{ 
        user, setUser, errors, setErrors, paymentFlag, setPaymentFlag, checkPaymentStatus 
    }}>
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentProvider;
