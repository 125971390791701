
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import LearningGoals from "../../components/LearningGoals";
import FlipCardGrid from "../../components/FlipCardGrid";
import TitleSection from "../../components/TitleSection";
import SubtitleSection from "../../components/SubtitleSection";
import ImgMediaCard from "../../components/ImgMediaCard";
import VideoMediaCard from "../../components/VideoMediaCard";
import GoogleForm from "../../components/GoogleForm";
import LoadPdf from "../../components/LoadPdf";
import TimeEstimate from  "../../components/TimeEstimate.js";
import InteractiveLearningJourney from  "../../components/InteractiveLearningJourney.js";
import SimpleModalBlockly from  "../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player'
import { FaHourglassHalf } from 'react-icons/fa';
import AIBanner from "../../components/AIBanner";
import TwoPartRow from "../../components/TwoPartRow";
import "../../markdown-styles/styles.css";
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: "green"
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const cardsContentServices = [{
  title: 'Personalized Learning',
  content: "AI can provide customized training programs based on a player's strengths, weaknesses, and style of play, enhancing the learning curve."
}, {
  title: 'Detailed Analysis',
  content: "With its ability to process and analyze massive amounts of data quickly, AI can offer detailed insights about a player's performance, such as swing velocity, footwork, shot placement, and even subtle technique improvements."
}, {
  title: 'Monitoring Progress',
  content: "AI systems can track a player's performance over time, providing objective, data-driven assessments of their progress and areas that need improvement."
}, {
  title: 'Strategic Insights',
  content: "By analyzing patterns and tendencies in a player's game, AI can provide strategic recommendations for match play."
}, {
  title: 'Accessibility',
  content: 'With AI, a high level of coaching and feedback can be available anytime, anywhere, making quality coaching more accessible to all.'
}, {
  title: 'Efficiency',
  content: "AI can instantly analyze and provide feedback on a player's performance, saving valuable time compared to traditional coaching methods."
}, {
  title: 'Injury Prevention',
  content: 'AI can identify potentially harmful movements or habits that might lead to injury over time, promoting safer training.'
}];
export const buttons = [{
  label: "Introduction",
  time: 0
}, {
  label: "What is optimization?",
  time: .09
}, {
  label: "cardboard experiment",
  time: .36
}, {
  label: "Volume as a function of height",
  time: 1.14
}, {
  label: "First derivative",
  time: 1.41
}, {
  label: "What is critical point?",
  time: 1.59
}, {
  label: "What is slope of tangent line?",
  time: 2.09
}, {
  label: "Solving for height h to optimize volume",
  time: 2.15
}, {
  label: "Summary",
  time: 2.51
}];
export const beg_topics = [{
  "title": "Who is the audience?",
  "content": "This workshop is tailored for finance professionals eager to harness AI for data-driven insights and decision-making."
}, {
  "title": "What are key benefits of learning AI for Finance?",
  "content": "Learning AI for Finance equips professionals with predictive analytics, risk assessment tools, algorithmic trading strategies, and techniques for converting qualitative data in unstructured formats into quantitative information for model integration."
}, {
  "title": "Which Finance Domains can be Covered?",
  "content": "Participants have the opportunity to select from a diverse array of topics within the financial sphere, including but not limited to stocks, equities, fixed income, and an extensive range of derivatives such as options and futures. This selection process ensures a tailored experience that can address specific interests and needs, all while leveraging AI to enhance analytical precision and strategic decision-making in these domains."
}, {
  "title": "What are the Workshop Modules/ topics?",
  "content": "The workshop covers modules from financial data analysis to machine learning model deployment for real-world finance applications.\
    Modules include time series forecasting, recommendation engines, search and retrieval algorithms, generative AI models, and advanced portfolio management strategies, using tools like Python and Pytorch."
}, {
  "title": "What are the instructor credentials?",
  "content": "Pavan Mirla is an AI professional with extensive experience in financial markets and machine learning technologies. Having served as a Senior Quant Analyst at prominent buy-side firms like CPP Investments with $400B AUM and Manulife Asset Management with $500B AUM, he has led data science and AI teams at John Hancock Innovation Labs. Pavan now consults for quant funds, aiding in the integration of AI into their investment research and quantitative modeling."
}, {
  "title": "Is this in remote offering?",
  "content": "Yes, our workshop is available remotely, providing flexible learning options for global participants."
}, {
  "title": "Can audience suggest custom case studies with custom datasets?",
  "content": "Participants can propose custom case studies and datasets for a tailored learning experience that addresses specific challenges."
}, {
  "title": "How to contact us?",
  "content": "Email at office@perceptron.solutions."
}];
export const goals = [{
  id: 'LG01',
  description: "Understand the concept of optimization in the context of maximizing volume."
}, {
  id: 'LG02',
  description: "Identify the optimization function and its role in optimization problems."
}, {
  id: 'LG03',
  description: "Recognize the constraints in an optimization problem and understand their significance."
}, {
  id: 'LG04',
  description: "Grasp the concept of a derivative and how it can be applied in optimization."
}, {
  id: 'LG05',
  description: "Comprehend the idea of a tangent and its relationship with derivatives."
}, {
  id: 'LG06',
  description: "Master the formula for derivatives and its application in solving optimization problems."
}, {
  id: 'LG07',
  description: "Understand what an inflection point is and how to identify it."
}, {
  id: 'LG08',
  description: "Define a critical point and explain its relevance in optimization."
}];
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" playing controls config={{
      file: {
        attributes: {
          id: "audio-element"
        }
      },
      playerVars: {}
    }} width="320px" height="180px" mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>
        What is optimization
      </button>
      <br />
      <button onClick={() => ref.current.seekTo(20)}>
        What is critical point?
      </button>
    </div>;
};
export const ClipVideo = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" startTime={10} endTime={20} playing controls mdxType="ReactPlayer" />
  </div>;
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const AppHeader = makeShortcode("AppHeader");
const VideoPlayer = makeShortcode("VideoPlayer");
const SVGArrow = makeShortcode("SVGArrow");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
cardsContentServices,
buttons,
beg_topics,
goals,
Player,
ClipVideo,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
















    <br />
    <br />
    <br />
    <br />
    <AppHeader mdxType="AppHeader" />

    <AIBanner titleLine1="Workshop Series on AI for Finance" titleLine2="" description="Finance professionals will be able to build custom AI models after attending this workshop series.This offering is an interactive, in-person workshop, carefully crafted after consulting with busy professionals. It's structured to accommodate those who cannot allocate extensive hours at a time for learning." mdxType="AIBanner">
  {/* This is the additional content that will be shown when 'Show More' is clicked */}
  <div>
  <TwoPartRow cardsContent={beg_topics} mdxType="TwoPartRow" />
  </div>
    </AIBanner>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
  <VideoPlayer buttons={[]} width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/workshops/nikola/nicola.mp4" mdxType="VideoPlayer" />
    </div>
    <br />
    <br />
    <h1>{`Time Series with AI Workshop Sample`}</h1>
    <br />
    <br />
    <br />
    <LoadPdf file="https://storage.googleapis.com/chapters-ifm/workshops/nikola/nicola.pdf" mdxType="LoadPdf" />

    <br />
    <br />
    <br />
    <>
      <TitleSection title="What Next?" mdxType="TitleSection" />
      <SubtitleSection subtitle="Connect with us at office@perceptron.solutions for a personalized demo" mdxType="SubtitleSection" />
    </>
    <div>
      <br />
      <br />
 <SVGArrow color='red' width='200px' mdxType="SVGArrow" />
      <br />
    </div>
    <TitleSection title="Testimonials" mdxType="TitleSection" />
 <br />
 <br />
    <LoadPdf file="https://storage.googleapis.com/chapters-ifm/workshops/testimonials.pdf" mdxType="LoadPdf" />
 <br />
 <br />




    <iframe className="appointment-iframe" src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3vDShjImJUPKz8kMO0X55N9VbTD95GU_SrOP8TQMc1g0XsQja5dBfL2j-y9IN2JxIXUlBLBFgl?gv=true" frameborder="0"></iframe>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;