// import React, { useEffect, useState } from 'react';
// import SearchBar from './SearchBar';
// import Filters from './Filters';
// import ArticleNew from './SpreadsheetCard';  // Import the updated ArticleNew component
// import { Box } from '@mui/material'; // Import MUI Box component
// // Make the component accept a spreadsheet URL as a prop
// const MainPage = ({ sheetUrl }) => {
//   const [articles, setArticles] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [selectedFilters, setSelectedFilters] = useState([]);
//   const [rawTSV, setRawTSV] = useState(''); // New state for storing raw TSV data

//   useEffect(() => {
//     if (sheetUrl) {
//       const fetchDataFromSheet = async () => {
//         try {
//           const response = await fetch(sheetUrl);
//           const data = await response.text(); // Fetch as plain text (TSV)
//           setRawTSV(data);  // Store the raw TSV data

//           // Parse TSV data into a usable JSON format
//           const parsedData = tsvToJson(data);
//           setArticles(parsedData);
//         } catch (error) {
//           console.error('Error fetching data from Google Sheets:', error);
//         }
//       };

//       fetchDataFromSheet();
//     }
//   }, [sheetUrl]); // The effect depends on the sheetUrl prop

//   const tsvToJson = (tsv) => {
//     const lines = tsv.split('\n');
//     const headers = lines[0].split('\t'); // Split by tab character
//     return lines.slice(1).map((line) => {
//       const values = line.split('\t'); // Split each line by tab
//       const article = {};
//       headers.forEach((header, index) => {
//         article[header.trim()] = values[index] ? values[index].trim() : ''; // Handle potential undefined values
//       });
//       article.tags = article.tags ? article.tags.split(',').map((tag) => tag.trim()) : [];
//       return article;
//     });
//   };

//   // Ensure all tags are unique and sort them
//   const allFilters = [...new Set(articles.flatMap((article) => article.tags))].sort();

//   const handleSearch = (term) => {
//     setSearchTerm(term);
//   };

//   const handleToggleFilter = (filter) => {
//     setSelectedFilters((currentFilters) =>
//       currentFilters.includes(filter)
//         ? currentFilters.filter((f) => f !== filter)
//         : [...currentFilters, filter]
//     );
//   };

//   const handleClearAllFilters = () => {
//     setSelectedFilters([]);
//   };

//   const getFilteredArticles = () => {
//     return articles.filter(
//       (article) =>
//         (selectedFilters.length === 0 || article.tags.some((tag) => selectedFilters.includes(tag))) &&
//         (article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//           article.content.toLowerCase().includes(searchTerm.toLowerCase()))
//     );
//   };

//   const filteredArticles = getFilteredArticles();


//   return (
//     <div>
//       <div className="search-bar-container">
//         <SearchBar onSearch={handleSearch} />
//       </div>
//       <Filters
//         filters={allFilters}
//         selectedFilters={selectedFilters}
//         onToggleFilter={handleToggleFilter}
//         onClearAll={handleClearAllFilters}
//       />
//       {/* Use MUI's Box component with responsive styles */}
//       <Box
//         className="card-grid"
//         sx={{
//           display: 'grid',
//           gap: 2,
//           padding: 2,
//           gridTemplateColumns: {
//             xs: 'repeat(1, 1fr)', // 1 column on extra-small screens
//             sm: 'repeat(2, 1fr)', // 2 columns on small screens
//             md: 'repeat(3, 1fr)', // 3 columns on medium and larger screens
//           },
//         }}
//       >
//         {filteredArticles.map((article) => (
//           <ArticleNew
//             key={article.id}
//             title={article.title}
//             content={article.content}
//             link={article.link}
//             video={article.video}
//             image={article.image}
//           />
//         ))}
//       </Box>
//     </div>
//   );
// };

// export default MainPage;


import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material'; // Import useMediaQuery
import SearchBar from './SearchBar';
import Filters from './Filters';
import ArticleNew from './SpreadsheetCard';  // Adjust the import path if needed

const MainPage = ({ sheetUrl }) => {
  const [articles, setArticles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [rawTSV, setRawTSV] = useState('');

  // Detect if the screen width is less than or equal to 600px
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    if (sheetUrl) {
      const fetchDataFromSheet = async () => {
        try {
          const response = await fetch(sheetUrl);
          const data = await response.text();
          setRawTSV(data);

          // Parse TSV data into a usable JSON format
          const parsedData = tsvToJson(data);
          setArticles(parsedData);
        } catch (error) {
          console.error('Error fetching data from Google Sheets:', error);
        }
      };

      fetchDataFromSheet();
    }
  }, [sheetUrl]);

  const tsvToJson = (tsv) => {
    const lines = tsv.split('\n');
    const headers = lines[0].split('\t');
    return lines.slice(1).map((line) => {
      const values = line.split('\t');
      const article = {};
      headers.forEach((header, index) => {
        article[header.trim()] = values[index] ? values[index].trim() : '';
      });
      article.tags = article.tags ? article.tags.split(',').map((tag) => tag.trim()) : [];
      return article;
    });
  };

  // Ensure all tags are unique and sort them
  const allFilters = [...new Set(articles.flatMap((article) => article.tags))].sort();

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleToggleFilter = (filter) => {
    setSelectedFilters((currentFilters) =>
      currentFilters.includes(filter)
        ? currentFilters.filter((f) => f !== filter)
        : [...currentFilters, filter]
    );
  };

  const handleClearAllFilters = () => {
    setSelectedFilters([]);
  };

  const getFilteredArticles = () => {
    return articles.filter(
      (article) =>
        (selectedFilters.length === 0 || article.tags.some((tag) => selectedFilters.includes(tag))) &&
        (article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          article.content.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  const filteredArticles = getFilteredArticles();

  return (
    <div>
      <div className="search-bar-container">
        <SearchBar onSearch={handleSearch} />
      </div>
      {/* Conditionally render Filters based on screen size */}
      {!isSmallScreen && (
        <Filters
          filters={allFilters}
          selectedFilters={selectedFilters}
          onToggleFilter={handleToggleFilter}
          onClearAll={handleClearAllFilters}
        />
      )}
      {/* Use MUI's Box component with responsive styles */}
      <Box
        className="card-grid"
        sx={{
          display: 'grid',
          gap: 2,
          padding: 2,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)', // 1 column on extra-small screens
            sm: 'repeat(2, 1fr)', // 2 columns on small screens
            md: 'repeat(3, 1fr)', // 3 columns on medium and larger screens
          },
        }}
      >
        {filteredArticles.map((article) => (
          <ArticleNew
            key={article.id}
            title={article.title}
            content={article.content}
            link={article.link}
            video={article.video}
            image={article.image}
          />
        ))}
      </Box>
    </div>
  );
};

export default MainPage;

