import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import styled from '@emotion/styled';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    padding: theme.spacing(5),
    justifyContent: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: '1.2rem',
    color: '#333', // This is your main title color for client names
  },
  content: {
    fontWeight: 300,
    fontSize: '1rem',
    color: '#555', // Slightly lighter than the title for better contrast
  },
  card: {
    boxShadow: '0px 6px 10px rgba(0,0,0,0.1)',
    borderRadius: '10px',
  },
  sectionHeader: {
    fontWeight: 700,
    fontSize: '1.5rem',
    color: '#333',
    marginBottom: theme.spacing(4), // Adds space below the section header
  },
}));

const StyledCard = styled(Card)`
  height: 100%;
  background-color: #f5f5f5; // Light gray background to match the theme
  box-shadow: 0px 0px 8px rgba(0,0,0,0.1); // Soft shadow for depth
  border-radius: 10px; // Keeps the rounded corners as previously defined
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out; // Smooth transition for hover effect

  &:hover {
    transform: translateY(-5px) scale(1.02); // Slight upward shift and scale up for hover
    box-shadow: 0px 15px 25px rgba(0,0,0,0.2); // Deeper shadow on hover for emphasis
  }
`;


const ClientHighlights = ({ clients }) => {
  const classes = useStyles();

  return (
    <>
    <br/>
    <br/>
      <Typography variant="h4" component="h2" className={classes.sectionHeader}>
        Our Clients
      </Typography>
      <Grid container spacing={4} className={classes.gridContainer}>
        {clients.map((client, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <StyledCard className={classes.card}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                  {client.name}
                </Typography>
                <Typography variant="body2" component="p" className={classes.content}>
                  {client.services}
                </Typography>
                <Typography variant="body2" component="p" className={classes.content}>
                  {client.duration}
                </Typography>
                <Typography variant="body2" component="p" className={classes.content}>
                  {client.location}
                </Typography>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ClientHighlights;
