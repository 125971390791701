
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import AlphabetCards from "../../../components/AlphabetCards";
import GeogebraDraw_Temp from "../../../components/GeogebraDraw_Temp";
import VAEComponentBeginner from  "../../../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../../../blocks/VAEComponentGraduate.js";
import MyCard from  "../../../components/MyCard.js";
import SVGArrow from  "../../../components/SVGArrow.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import SimpleModalBlockly from  "../../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player'
import { FaHourglassHalf } from 'react-icons/fa';
import OneBlocklyFile from  "../../../blocks/OneBlocklyFile.js";
import QuantizationPEFTBlockly from  "../../../blocks/QuantizationPEFTBlockly.js";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Header = makeShortcode("Header");
const Marker = makeShortcode("Marker");
const HowlerRead = makeShortcode("HowlerRead");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`How to Fine Tune LLAMA2 with PEFT/LoRA`}</h1>
    <br />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
    <h1 style={{
      color: "green"
    }}>1. Exploration Stage </h1>
    <Header mdxType="Header">Play, Build, Learn: Your First VAE Made Easy!</Header>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      // this will give space between your markers
      alignItems: 'center',
      backgroundColor: 'transparent',
      // making the background transparent
      color: 'white',
      // text color
      padding: '20px',
      // inner padding
      overflowY: 'auto',
      // add a scrollbar when content is too tall
      position: 'relative',
      // make the div position relative
      height: '80px',
      // set the height of the div
      width: '500px'
    }}>
      <Marker left="50%" top="20%" mleft="50%" mtop="25%" text="1" title="Task" mdxType="Marker">
  <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
    <HowlerRead soundSrc="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/_VariationalAutoencoders%2C.mp3" mdxType="HowlerRead" />
    <img src="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VAE_QUIZ.png" alt="Quiz Image" style={{
            width: '450px',
            height: '400px',
            marginTop: '20px'
          }} />
  </div>
  </Marker>
    </div>
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={3} mdxType="TimeEstimate" />
      <br />
      <br />
 <SVGArrow color='green' width='200px' mdxType="SVGArrow" />
      <br />
      <br />
      <br />
      <br />
    </div>
  <QuantizationPEFTBlockly mdxType="QuantizationPEFTBlockly" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <a href="https://colab.research.google.com/drive/1_TIrmuKOFhuRRiTWN94iLKUFu6ZX4ceb?usp=sharing#scrollTo=vT0XjNc2jYKy"> AutoGPTQ </a>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;