import React from 'react';
import App from '../utilities/App';

const topics = ["Perceptron", "Multi Layer Perceptron"];

function PerceptronApp() {
  return <App topics={topics} baseRoute="perceptron_app" />;
}

export default PerceptronApp;
