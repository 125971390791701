import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import ReactPlayer from 'react-player';
import { FaSyncAlt } from 'react-icons/fa';
import Filters from './Filters';
const ArticleNew = ({ title, content, link, video, image }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="flip-card">
      <div className={`flip-card-inner ${isFlipped ? 'flipped' : ''}`}>
        <div className="flip-card-front" style={styles.front}>
          <h2 style={styles.title}>{title}</h2>
          {video ? (
            <div className="media-container" style={styles.videoContainer}>
              <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
                controls
              />
            </div>
          ) : image ? (
            <div className="media-container" style={styles.imageContainer}>
              <img src={image} alt={title} style={styles.image} />
            </div>
          ) : null}
          <FaSyncAlt onClick={handleFlip} style={styles.flipIconFront} />
        </div>
        <div className="flip-card-back" style={styles.back}>
          <div style={styles.backContent}>
            <p style={styles.content}>{content}</p>
          </div>
          <FaSyncAlt onClick={handleFlip} style={styles.flipIconBack} />
        </div>
      </div>
      {link && (
        <a href={link} className="read-more" target="_blank" rel="noopener noreferrer" style={styles.readMore}>
          Read More
        </a>
      )}
      <div style={styles.bottomPadding} />
    </div>
  );
};

const MainPage = ({ jsonUrl }) => {
  const [articles, setArticles] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(jsonUrl);
        const data = await response.json();
        setArticles(data);
      } catch (error) {
        console.error('Error fetching data from JSON file:', error);
      }
    };

    if (jsonUrl) {
      fetchData();
    }
  }, [jsonUrl]);

  const allFilters = [...new Set(articles.flatMap((article) => article.tags))].sort();

  const handleToggleFilter = (filter) => {
    setSelectedFilters((currentFilters) =>
      currentFilters.includes(filter)
        ? currentFilters.filter((f) => f !== filter)
        : [...currentFilters, filter]
    );
  };

  const handleClearAllFilters = () => {
    setSelectedFilters([]);
  };

  const getFilteredArticles = () => {
    return articles.filter(
      (article) =>
        selectedFilters.length === 0 ||
        article.tags.some((tag) => selectedFilters.includes(tag))
    );
  };

  const filteredArticles = getFilteredArticles();

  return (
    <div style={styles.container}>
      {!isSmallScreen && (
        <Filters
          filters={allFilters}
          selectedFilters={selectedFilters}
          onToggleFilter={handleToggleFilter}
          onClearAll={handleClearAllFilters}
        />
      )}
      <Box
        className="card-grid"
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',                    // 1 column for extra small screens
            sm: 'repeat(2, 1fr)',         // 2 columns for small screens
            md: 'repeat(3, 1fr)',         // 3 columns for medium and larger screens
          },
          gap: 2,
          padding: 2,
          justifyItems: 'center',
          width: '100%',
        }}
      >
        {filteredArticles.map((article) => (
          <ArticleNew
            key={article.id}
            title={article.title}
            content={article.content}
            link={article.link}
            video={article.video}
            image={article.image}
          />
        ))}
      </Box>
    </div>
  );
};

// Styles
const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#FFF1E0',
  },
  front: {
    display: 'flex',
    color: 'gray',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF1E0',
    borderRadius: '8px',
    padding: '16px',
  },
  back: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backfaceVisibility: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#007BFF',
    color: 'white',
    borderRadius: '8px',
    padding: '16px',
  },
  backContent: {
    marginTop: '13%',
    width: '100%',
    padding: '0 16px',
  },
  title: {
    marginTop: '13%',
    fontSize: '1.8em',
    marginBottom: '16px',
    textAlign: 'center',
    color: 'black',
    fontWeight: 300, // Light font weight
    fontFamily: 'Roboto, sans-serif',
    fontStretch: 'condensed', // Optional: Use if the font supports it
  },
  videoContainer: {
    position: 'relative',
    paddingTop: '56.25%',
    width: '100%',
    marginBottom: '16px',
    pointerEvents: 'auto',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  imageContainer: {
    width: '100%',
    height: 'auto',
    marginBottom: '16px',
    overflow: 'hidden',
    borderRadius: '8px',
    pointerEvents: 'auto',
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  content: {
    fontSize: '1.2em',
    fontFamily: 'Arial, sans-serif',
    color: '#fff',
    textAlign: 'center',
    lineHeight: '1.5',
  },
  readMore: {
    display: 'block',
    color: '#007BFF',
    textDecoration: 'none',
    marginTop: '16px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  flipIconFront: {
    marginTop: 'auto',
    cursor: 'pointer',
    color: '#007BFF',
    fontSize: '1.5rem',
  },
  flipIconBack: {
    marginTop: 'auto',
    cursor: 'pointer',
    color: '#FFFFFF',
    fontSize: '1.5rem',
  },
  bottomPadding: {
    height: '20px',
  },
};

// CSS for card flip functionality
const flipCardStyles = `
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 1000px;
    margin: 16px;
    position: relative;
    max-width: 300px;
    flex: 1 1 300px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: '#FFF1E0';
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .flip-card-inner.flipped {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 8px;
  }

  .flip-card-back {
    transform: rotateY(180deg);
  }
`;

// Adding the custom styles to the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = flipCardStyles;
document.head.appendChild(styleSheet);

export default MainPage;