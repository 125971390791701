import React from 'react';
import SVGArrow from './SVGArrow'; // Assuming SVGArrow is a separate component
import TimeEstimate from './TimeEstimate'; // Assuming TimeEstimate is a separate component

const InteractiveLearningJourney = ({ time, arrowColor }) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <br />
      <br />
      <TimeEstimate time={time} />
      <br />
      <br />
      
      <SVGArrow color={arrowColor} width='200px' />
      <br />
      <br />
    </div>
  );
}

// Define default props in case they are not provided
InteractiveLearningJourney.defaultProps = {
  time: 3, // Default time if not provided
  arrowColor: 'green' // Default arrow color if not provided
};

export default InteractiveLearningJourney;
