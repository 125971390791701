import React, { useState, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Button } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

export default function FullscreenVideoPlayer({ videoUrl }) {
    const [isFullscreen, setIsFullscreen] = useState(false);
    const playerWrapperRef = useRef(null);

    const handleFullscreenToggle = () => {
        const player = playerWrapperRef.current;
        if (!isFullscreen) {
            if (player.requestFullscreen) {
                player.requestFullscreen();
            } else if (player.mozRequestFullScreen) { /* Firefox */
                player.mozRequestFullScreen();
            } else if (player.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                player.webkitRequestFullscreen();
            } else if (player.msRequestFullscreen) { /* IE/Edge */
                player.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
        setIsFullscreen(!isFullscreen);
    };

    return (
        <div ref={playerWrapperRef} style={{ position: 'relative', height: '100vh' }}>
            <ReactPlayer
                url={videoUrl}
                playing={true}
                controls={true}
                width='100%'
                height='100%'
            />
            <Button
                style={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    zIndex: 1000,
                    color: 'white',
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    '&:hover': {
                        backgroundColor: 'rgba(0,0,0,0.8)'
                    }
                }}
                onClick={handleFullscreenToggle}
            >
                {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </Button>
        </div>
    );
}
