import React from 'react';
import { Button, Drawer, List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

const chapters = [
  { title: 'Chapter 1', sections: ['Section 1.1', 'Section 1.2'] },
  // ... additional chapters
];

const TopMenu = ({ isTopMenuOpen, setIsTopMenuOpen, setSelectedChapter }) => {
  return (
    <>
      <Button onClick={() => setIsTopMenuOpen(!isTopMenuOpen)}>
        Toggle Top Menu
      </Button>
      <Drawer anchor="top" open={isTopMenuOpen} onClose={() => setIsTopMenuOpen(false)}>
        <List>
          {chapters.map((chapter, chapterIndex) => (
            <ListItem 
              button 
              key={chapterIndex}
              onClick={() => setSelectedChapter(chapter)}
            >
              <Link to={`/chapter/${chapterIndex}/section/0`}>
                {chapter.title}
              </Link>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default TopMenu;
