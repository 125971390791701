import React from 'react';
import SignUpComponent from './SignUpComponent';
import SignInComponent from './SignInComponent';

function App() {
  return (
    <div>
      <SignUpComponent />
      <SignInComponent />
    </div>
  );
}

export default App;
