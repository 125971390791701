import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TopMenu from './TopMenu';
import SideMenu from './SideMenu';
import ContentArea from './ContentArea';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
  },
});

const App = () => {
  const [isTopMenuOpen, setIsTopMenuOpen] = useState(true);
  const [selectedChapter, setSelectedChapter] = useState(null);


  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userEmail = user.email;

        const paymentSnapshot = await firebase.firestore()
          .collection('payments')
          .where('email', '==', userEmail)
          .limit(1)
          .get();

        if (!paymentSnapshot.empty) {
          // User has made a payment
          console.log("User has made a payment.");
          // ... (Your code to allow access, etc.)
        } else {
          // No payment found for user
          console.log("No payment found for this user.");
          // ... (Your code to restrict access, prompt for payment, etc.)
        }
      } else {
        // User is signed out.
        // ... (Your code to show login screen or prompt)
      }
    });

    // Cleanup the listener on component unmount
    return () => unsubscribe();
  }, []);
  
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <TopMenu 
          isTopMenuOpen={isTopMenuOpen} 
          setIsTopMenuOpen={setIsTopMenuOpen} 
          setSelectedChapter={setSelectedChapter}
        />
        <SideMenu 
          selectedChapter={selectedChapter} 
        />
        <Switch>
          <Route path="/chapter/:chapterIndex/section/:sectionIndex">
            <ContentArea />
          </Route>
        </Switch>
      </ThemeProvider>
    </Router>
  );
};

export default App;
