
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import SearchSpreadSheet from "../../../common/SearchSpreadSheet.js"
import FlipCardsLocalData from "../../../common/FlipCardsLocalData.js"
export const SHEET_TSV_URL = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vQmI-WJLd3EVaJelYisukVWEmCFU8MfC62e30iznmqWue35jpzZj0r8I4tnGdfySKy42GMV6Adl53HD/pub?output=tsv';

const layoutProps = {
  SHEET_TSV_URL
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <FlipCardsLocalData jsonUrl={"/articles.json"} mdxType="FlipCardsLocalData" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;