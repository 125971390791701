import React, { useState } from 'react';
import firebase from "../firebase/firebaseIndex";

const GoogleSignInButton = () => {
  const [currentUser, setCurrentUser] = useState(null);
  
  const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    try {
      const result = await firebase.auth().signInWithPopup(provider);
      setCurrentUser(result.user);
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const buttonStyle = {
    backgroundColor: '#4285F4',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px'
  };

  const noteStyle = {
    padding: '20px',
    backgroundColor: '#f7f7f7',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
    textAlign: 'center',
    marginBottom: '10px',
    color: 'black'
  };

  if (currentUser) {
    return (
      <div style={{ width: '300px', margin: '0 auto', marginTop: '50px' }}>
        <div style={noteStyle}>
          <p>Welcome, <strong>{currentUser.displayName}</strong> ({currentUser.email})!</p>
          <p>The next step is to make a subscription payment for full access.</p>
          {/* You can add your StripeBuyButton component below this comment */}
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: '300px', margin: '0 auto', marginTop: '50px' }}>
      <div style={noteStyle}>
        <p><strong>Note:</strong> Signing in with Google is mandatory. Additionally, a subscription payment is required for full access.</p>
      </div>
      <button style={buttonStyle} onClick={signInWithGoogle}>
        Sign in with Google
      </button>
    </div>
  );
};

export default GoogleSignInButton;
