import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={850}
    height={1100}
    style={{
      backgroundColor: "#fff",
    }}
    viewBox="-0.5 -0.5 1050 1100"
    {...props}
  >
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M55 150.5h10v890h10.5l-15.5 19-15.5-19H55Z"
      pointerEvents="all"
    />
    <path fill="none" d="M150 50h570v90H150z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 568,
            height: 1,
            paddingTop: 95,
            marginLeft: 151,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 32,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                fontWeight: 700,
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "Goal:\xA0 Fine tuning and incorporating proprietary domain data in Large Language Models"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={435}
        y={105}
        fontFamily="Helvetica"
        fontSize={32}
        fontWeight="bold"
        textAnchor="middle"
      >
        {"Goal:\xA0 Fine tuning and incorporating..."}
      </text>
    </switch>
    <path fill="none" d="M120 183h510v60H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 213,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"Fine-tuning LLMs for finance transforms them from generic language'
              }
              <br />
              {
                "processors to specialized financial analysts, capable of sifting through years of financial data"
              }
              <br />
              <div>
                <span
                  style={{
                    backgroundColor: "initial",
                  }}
                >
                  {'and providing actionable insights in real time."'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={217} fontFamily="Helvetica" fontSize={12}>
        {
          '"Fine-tuning LLMs for finance transforms them from generic language...'
        }
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 307h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 307-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 420h170v90H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 427,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L14A: Building retrieval Augmented Generation (RAG) systems with finance data."
              }
              <br />
              {"Setting up Vector Databases"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={225}
        y={439}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L14A: Building retrieval Aug..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 460h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 460-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M330 420h170v90H330z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 427,
            marginLeft: 331,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L15B: Building RAG system on Local Computer without any external dependencies with complete privacy or building RAG on cloud"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={415}
        y={439}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L15B: Building RAG system on..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 601h180v110H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 178,
            height: 1,
            paddingTop: 608,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L16A: Building Autonomous Agent that specialize in one task and automate the implementaion using AuoGen and AutoGPT"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={230}
        y={620}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L16A: Building Autonomous Agen..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 655.5h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 655.5-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M350 266h170v90H350z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 273,
            marginLeft: 351,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L13B: Getting familiar with LLM ecosystem and Open source models on Google and Hugging face Model repository"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={435}
        y={285}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L13B: Getting familiar with..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M340 601h190v110H340z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 188,
            height: 1,
            paddingTop: 608,
            marginLeft: 341,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L16B: Effectively using LLM Chain tools and index development tools like Langchain and LlamaIndex and haystack for retirieval of information"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={435}
        y={620}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L16B: Effectively using LLM Cha..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 750h200v110H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 198,
            height: 1,
            paddingTop: 757,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L17A: Deploying custom OpenAI GPTs or Open source alternatives\xA0 and Function Calling with your data. In depth coverage of OpenAi APIs in finance context"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={240}
        y={769}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L17A: Deploying custom OpenAI GPT..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 840h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 840-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M370 750h150v110H370z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 148,
            height: 1,
            paddingTop: 757,
            marginLeft: 371,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"L17B: Integrating legacy systems with LLMs for intelligence."}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={445}
        y={769}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L17B: Integrating legacy..."}
      </text>
    </switch>
    <path fill="none" d="M125 530h470v40H125z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 1,
            height: 1,
            paddingTop: 550,
            marginLeft: 360,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                "\"Agents driven by LLMs bring us closer to AI that doesn't just process information but"
              }
              <br />
              <div
                style={{
                  textAlign: "left",
                }}
              >
                <span
                  style={{
                    backgroundColor: "initial",
                  }}
                >
                  {'also navigates and interacts with the real world"'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={360}
        y={554}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {
          "\"Agents driven by LLMs bring us closer to AI that doesn't just process informa..."
        }
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M520 420h170v90H520z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 168,
            height: 1,
            paddingTop: 427,
            marginLeft: 521,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L15C: Evaluating RAG Models and using Knowledge graphs for MetaData"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={605}
        y={439}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L15C: Evaluating RAG Models..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 268h180v90H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 178,
            height: 1,
            paddingTop: 275,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L13A: LLM Fine tuning Data preparation.Zero-Shot learning, Few-Short Learning, One-Shot Learning"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={230}
        y={287}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L13A: LLM Fine tuning Data pre..."}
      </text>
    </switch>
    <path fill="none" d="M130 366h460v40H130z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 386,
            marginLeft: 132,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                "RAG enables large language models (LLMs) to access and incorporate up-to-date"
              }
              <br />
              {"and relevant information from external sources"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={132} y={390} fontFamily="Helvetica" fontSize={12}>
        {
          "RAG enables large language models (LLMs) to access and incorporate up-to-da..."
        }
      </text>
    </switch>
    {/* <switch>
      <a
        xlinkHref="https://www.drawio.com/doc/faq/svg-export-text-problems"
        target="_blank"
        transform="translate(0 -5)"
      >
        <text x="50%" y="100%" fontSize={10} textAnchor="middle">
          {"Text is not SVG - cannot display"}
        </text>
      </a>
    </switch> */}
  </svg>
)
export default SvgComponent
