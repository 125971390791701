import React, { Suspense } from 'react';
import { useParams } from 'react-router-dom';

const ContentArea = () => {
  const { chapterIndex, sectionIndex } = useParams();

  const MDXContent = React.lazy(() => 
    import(`./content/chapter${chapterIndex}/section${sectionIndex}.mdx`)
  );

  return (
    <main>
      <Suspense fallback={<div>Loading...</div>}>
        <MDXContent />
      </Suspense>
    </main>
  );
};

export default ContentArea;
