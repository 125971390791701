import React from "react";
import Box from "@mui/material/Box";
import AppHeader from "./AppHeader";
import AIBanner from "./AIBanner";
import VideoMediaCard from "./VideoMediaCard";
import VideoPlayer from './VideoPlayerSimple'; // Adjust path if needed
import FlipCardGrid from "./FlipCardGrid";
import TwoPartRow from "./TwoPartRow";
import GenericAccordion from './GenericAccordion'
import ImgMediaCard from "./ImgMediaCard";
import ContactUs from "./ContactUs";
import ButtonMailto from "./ButtonMailto";
import CustomCard from "./CustomCard";
import { Grid } from "@material-ui/core";
import ImageSwitcher from "./ImageSwitcher";
import CanvaEmbed from "./CanvaEmbed";
import ImageCard from "./ImageCard";
import RedirectButton from "./RedirectButton";
import LinkedInEmbed from "./LinkedInEmbed";
import MissionStatement from "./MissionStatement";

import Header from "./Header";
import ClientHighlights from "./ClientHighlights";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";

import FloatingContactButton from './common/FloatingContactButton'
import CourseWork from "./common/CourseWork.js"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    gap: "10px",
  },
  button: {
    fontSize: "16px",
    letterSpacing: "0.5px",
    textTransform: "none",
    textAlign: "center",
    width: "300px",
    height: "75px",
    fontSize: "18px",
    fontWeight: "500",
    fontFamily: '"Roboto", sans-serif',
  },
  caption: {
    fontSize: "14px", // Smaller font size for caption
    color: "#333", // Greyish color, can be adjusted
    textAlign: "center",
    maxWidth: "300px", // Aligns with the button width
    marginTop: "8px", // Space between button and caption
    fontFamily: '"Roboto", sans-serif',
  },
});

import About from "!babel-loader!@mdx-js/loader!./Topics/dl/mdx-content/about-me.mdx";
import Services from "!babel-loader!@mdx-js/loader!./Topics/dl/mdx-content/services.mdx";

const LandingPage = () => {
  const classes = useStyles();
  const cardsContentAIProducts = [
    {
      title: "Front Office",
      content:
        "Market Diagnostics: Enhance market analysis and decision-making with predictive analytics.",
    },
    {
      title: "Middle Office",
      content:
        "Risk Management: Improve risk assessment and mitigation through advanced AI models.",
    },
    {
      title: "Back Office",
      content:
        "Operations: Streamline back-office operations with AI-driven automation and efficiency tools.",
    },
  ];


  const cardsContent = [
    {
      title: 'Quantitative Analysis',
      content: 'Utilize mathematical models to make investment decisions.',
    },
    {
      title: 'Machine Learning',
      content: 'Apply algorithms that improve through experience with data.',
    },
    {
      title: 'Backtesting',
      content: 'Test trading strategies using historical data.',
    },
    // Add more cards as needed
  ];

  
  const cardsContentAIResearch = [
    {
      title: "Investment Research",
      content:
        "Offering recommendations on algorithms and data preparation methodologies to integrate additional signals using longitudinal panel data across equities.",
    },

    {
      title: "Incorporating Derivatives",
      content:
        "Generate additional income on existing assets while hedging downside risks with derivatives. We provide research and informed recommendations.",
    },
  ];

  const asset_management_problems = [
    {
      title: "AI Agents",
      content:
        "Customized AI agents can help you to: \n\n- Replace legacy systems with thinking agents that reduce costs and are efficient and trainable.\n\n- Develop planning and replanning agents to automate workflows, research, and verification processes.\n\n- Preserve customer revenue, be available 24/7 to serve customers.",
      tags: ["AI Agents", "RAG", "Workflow Automation", "Multi-Agent Systems"],
    }
    // {
    //   title: "Adaptive Quantitative Models",
    //   content:
    //     "- Development of customized quantitative models using AI and NLP for alpha generation.\n\n- Transformer-based models for multivariate time-series forecasting.\n\n- Graph neural networks for market predictions, portfolio optimization, and risk management.",
    //   tags: ["Quantitative Models", "AI", "NLP", "Reinforcement Learning"],
    // },
    // {
    //   title: "Event Knowledge Graphs",
    //   content:
    //     "- Creation of knowledge graphs for investment research based on unstructured text, including real-time search, retrieval, and query capabilities.\n\n - These knowledge graphs can connect disparate data points, providing a holistic view of market trends and insights. \n\n - Market diagnostics leverage these capabilities to offer better market insights and identify emerging opportunities. ",
    //   tags: [
    //     "Real-Time Monitoring",
    //     "Market Diagnostics",
    //     "Search and Retrieval",
    //   ],
    // }
    // {
    //     "title": "Back Office",
    //     "content": "- Solutions for data inconsistencies to ensure accuracy and consistency\n\n- Streamlined operations to improve efficiency and reliability\n\n- Tools for process improvement to overcome various operational issues",
    //     "tags": ["Data Inconsistencies", "Operational Problems", "Process Improvement"]
    // }
  ];


  const investment_management_problems = [

    {
      title: "Event Knowledge Graphs",
      content:
        "Customized AI agents can help you to: \n\n- Create knowledge graphs for investment research to enhance market insights..\n\n - Track markets and identify anomalies using AI agents to optimize decision-making.  \n\n - Automate parsing of earnings reports and company filings to reduce manual effort and improve efficiency. ",
      tags: [
        "Real-Time Monitoring",
        "Market Diagnostics",
        "Search and Retrieval",
      ],
  }
  ];



  const cardsContentAIWorkshop = [
    {
      title: "Machine Learning for Non-Programmers",
      content:
        "With interactive explainers and bite-sized sessions, intuitive math tutorials, anyone can learn to build advanced ML models.",
    },

    {
      title: "Fine-Tuning Large Language Models",
      content:
        "Incorporate your domain knowledge and proprietary datasets to build APIs you can use yourself. Suitable for both non-programmers and those proficient in programming.",
    },
    {
      title: "Transformer Models",
      content:
        "Construct advanced AI models using transformer technology for forecasting, time-series modeling, predictive analysis, and more.",
    },
  ];

  const circle_of_competence = [
    {
      title: "Investment Management Domain Experience",
      content:
        "Extensive experience at leading asset management firms including Manulife Asset Management, Genus Capital Management, and CPP Investment Board. In-depth understanding of investment strategies, portfolio management, and risk assessment.",
      tags: [
        "Manulife Asset Management",
        "Genus Capital Management",
        "CPP Investment Board",
        "Investment Strategies",
        "Portfolio Management",
        "Risk Assessment",
      ],
    },
    {
      title: "AI Modeling Expertise",
      content:
        "Expertise in advanced neural networks, transformers-based multivariate forecasting, and natural language processing (NLP) for extracting insights from unstructured data. Proven track record in developing AI-driven solutions for generating alpha opportunities.",
      tags: [
        "Neural Networks",
        "Transformers",
        "Multivariate Forecasting",
        "NLP",
        "Alpha Generation",
        "AI-Driven Solutions",
      ],
    },
    {
      title: "Data Modeling Expertise",
      content:
        "Skilled in search and retrieval capabilities for real-time information access and constructing robust data models for knowledge graphs. Experienced in building in-house sector-specific systems within a short span to enhance data consistency and operational efficiency.",
      tags: [
        "Search and Retrieval",
        "Real-Time Information",
        "Knowledge Graphs",
        "Data Models",
        "In-House Systems",
        "Data Consistency",
        "Operational Efficiency",
      ],
    },
  ];

  const AboutUsContent = [
    {
      title: "Our Competence",
      content:
        "With a team of 20+ data scientists and seasoned engineers, with an average experience of 5 years in  Data Science. We offer AI strategy planning and  end to end solutions covering Data preparation to Model development, maintenance and User experience enhancement.",
    },
    {
      title: "Our Experience",
      content:
        "Over 9 years experience building AI driven predictive models at large Investment Management funds in USA and Canada. we build custom AI models for use cases covering optimization, forecasting and recommendations. We have been helping clients to finetune large language models to build custom GPT models",
    },
    {
      title: "Our Solutions",
      content:
        "We offer tailor-made AI solutions that drive growth, efficiency, and innovation. From developing custom algorithms to integrating AI into existing platforms, we ensure our solutions align with your business objectives.",
    },
    {
      title: "Our Approach",
      content:
        "Our methodology is grounded in a partnership model that focuses on understanding client needs, agile development, and ethical AI. We emphasize transparent communication, continuous improvement, and measurable outcomes.",
    },
    {
      title: "Our Partners",
      content:
        "We've collaborated with industry leaders and innovative tech firms to augment our offerings, ensuring we provide cutting-edge solutions. Our partnerships reflect our commitment to excellence and staying ahead of the AI curve.",
    },
    {
      title: "Our Global Presence",
      content:
        "Headquartered in Vancouver, Canada, we are strategically positioned to serve clients across North America. Our robust network of international partners extends our reach, enabling us to support customers in various regions with localized expertise.",
    },
  ];

  const clientData = [
    {
      name: "Genus Capital Management",
      services:
        "Ongoing AI research services including advanced forecasting models and Large Language Models.",
      duration: "2+ years",
      location: "North America",
    },
    {
      name: "Circle Up",
      services:
        "Information extraction and retrieval for large text assets, pricing, and credit risk models.",
      duration: "1 year project",
      location: "USA",
    },
    {
      name: "X-Margin",
      services: "Crypto collateral management solutions.",
      duration: "1 year project",
      location: "USA",
    },
    {
      name: "FoodX",
      services:
        "E-commerce recommendations, AI-assisted warehouse operations models, and substitution algorithms for 100+ customers.",
      duration: "2 year assignment",
      location: "North America",
    },
    // ... additional clients
  ];

  const co_development_model = [
    {
      title: "AI Bootcamp",
      content:
        "From zero to use case in days. Move past demos, executive and developer collaboration to demonstrate AI platform capability. If there is no valuable output, no need to engage.",
      tags: [
        "AIP Bootcamps",
        "Use Case Development",
        "Executive Collaboration",
        "Developer Collaboration",
        "AI Platform Capability",
      ],
    },
    {
      title: "Value-Based Pricing",
      content:
        "Pricing tier based on value added, not hours spent in developing the product. This ensures clients only pay for the tangible benefits they receive from the AI solutions.",
      tags: ["Pricing", "Value Added", "Client Benefits", "Tangible Outcomes"],
    },
  ];

  const profile_topics = [
    {
      title: "Leadership",
      content: 
          "Pavan Mirla leads the Quant research and custom AI Modelling efforts. He has over 10 years of experience in quantitative finance companies such as CPPIB (Canada Pension Investments), Manulife Asset Management, DataRobot, and Perceptron Solutions AI Research."
  },
    {
        title: "Our Team",
        content:
            "Our team consists of experienced Machine learning data scientists, data engineer, cloud infrastructure experts, and software development.",
    },
    {
        title: "Our Partners",
        content: 
            "We have partners (collectively employing 50+ employees) with teams of certified engineers covering several cloud platforms and providing expertise in niche domains."
    }
];

  return (
    <div>
      <br />
      <br />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></Box>
      <AppHeader />
      <br />
      <br />

      <br />
      {/* <AIBanner
titleLine1="AI Platform"
titleLine2="For Investment Management Firms"
// description="With a deep understanding of the finance industry and proficiency in AI modeling, we facilitate the implementation of AI to advanced investment strategies. Our methodology employs AI to generate insights, refine investment strategies, and optimize risk management"

>
<TwoPartRow cardsContent={cardsContentAIProducts} />

  <div>
    <br />
  </div>
</AIBanner> */}
      <br />
      <MissionStatement />

      <br />
      <br />
      <Header variant="h4" caption="Select those that resonate with you">
        Client Profiles
      </Header>

      {/* <Box sx={{ width: "100%", maxWidth: 1000, paddingX: 2, mx: "auto" }}>
        <GenericAccordion
          title="1) COO Seeking to Add Intelligence To Legacy Systems"
          isCollapsed={true}
          headerColor="#E1F5FE"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                mb: 2,
                maxWidth: "100%", // Ensure text doesn't overflow
                width: "100%", // Take full width of parent
              }}
            >
              We have identified several critical priorities that are important
              for operational efficiencies
            </Typography>
            <Box sx={{ width: "100%", maxWidth: 800, mx: "auto" }}>
              <VideoPlayer url="https://storage.googleapis.com/chapters-ifm/GENAI_COURSEWORK/2024_SEP/GGB/Perceptron.Soltutions.COO.mp4" />
            </Box>
          </Box>
        </GenericAccordion>
      </Box> */}

      <Box sx={{ width: "100%", maxWidth: 1000, paddingX: 2, mx: "auto" }}>
        <GenericAccordion
          title="1) Investment Portfolio Manager/ Trader seeking to use AI in research"
          isCollapsed={true}
          headerColor="#C8E6C9"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                mb: 2,
                maxWidth: "100%", // Ensure text doesn't overflow
                width: "100%", // Take full width of parent
              }}
            >
              Experience in Finance / Asset management Industry differentiates
              us
            </Typography>
            <Box sx={{ width: "100%", maxWidth: 800, mx: "auto" }}>
              <VideoPlayer url="https://storage.googleapis.com/chapters-ifm/GENAI_COURSEWORK/2024_SEP/GGB/Perceptron.Soltutions.PM.mp4" />
            </Box>
          </Box>
        </GenericAccordion>
      </Box>

      <Box sx={{ width: "100%", maxWidth: 1000, paddingX: 2, mx: "auto" }}>
        <GenericAccordion
          title="2) Enterprise Learning and Development Manager"
          isCollapsed={true}
           headerColor="#FFF9C4"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                mb: 2,
                maxWidth: "100%", // Ensure text doesn't overflow
                width: "100%", // Take full width of parent
              }}
            >
              Perceptron Solutions offers a solution to make AI learning an
              engaging and effective experience.
            </Typography>
            <Box sx={{ width: "100%", maxWidth: 800, mx: "auto" }}>
              <VideoPlayer url="https://storage.googleapis.com/chapters-ifm/GENAI_COURSEWORK/2024_SEP/GGB/Perceptron.Soltutions.LRN.mp4" />
            </Box>
          </Box>
        </GenericAccordion>
      </Box>




      <br />
      <br />
      <br />
      <br />


    <Header 
      caption="We combine quantitative finance expertise with advanced AI modeling and deployment, setting us apart from traditional consulting firms."
    >
      Services We Offer
    </Header>

    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
    </Box>

    <Services />
    <br />
 

      {/*  

      <AIBanner
        titleLine1="Thinking AI Agents"
        titleLine2="For Enterprises"
        description="We build, develop, host, and audit AI agents with outcome-based pricing, ensuring maximum value for your business."
      >
        <div>
          <br />
          <TwoPartRow cardsContent={asset_management_problems} />
          <br/>
          <br/>
          <ImageCard img="https://storage.googleapis.com/chapters-ifm/HOME_PAGE/AI_CEO.png" />
        </div>
      </AIBanner>
      <br />
      <br />

      <br />




      <AIBanner
        titleLine1="Trainable AI Agents"
        titleLine2="For Investment Research"
        description="AI Agents + Knowledge Graphs + Reinforcement learning = Gains?? Absolutely."
      >
        <div>
          <br />
          <TwoPartRow cardsContent={investment_management_problems} />
          <br/>
          <br/>
          <ImageCard img="https://storage.googleapis.com/chapters-ifm/HOME_PAGE/AI_research.png" />
        </div>
      </AIBanner>
      <br />
      <br /> */}

      <br />
      {/* <br />
      <VideoMediaCard
        videoUrl="https://storage.googleapis.com/chapters-ifm/GENAI_COURSEWORK/2024_SEP/GGB/Perceptron.Soltutions.mp4"
        title="We develop custom AI solutions"
        description="DeepLearning modelling, AI Agents,AI workshops ."
        link=""
      />
      <br />
      <br /> */}
      <br />
      <br />
      {/* <AIBanner
        titleLine1="Our experience"
        titleLine2="differentiates us"
        // description="Match it with cutting-edge algorithms. Designed for executives and engineers, our bootcamps focus on joint development. Engage in hands-on projects to develop practical AI solutions."
      >
        <div>
          <br />
          <TwoPartRow cardsContent={circle_of_competence} />
        </div>
      </AIBanner>
      <br />
      <br />

      <br />

      <br />

      <br />
      <br />
      <AIBanner
        titleLine1="Co-Development Model"
        titleLine2="enables innovative Partnership"
        // description="Match it with cutting-edge algorithms. Designed for executives and engineers, our bootcamps focus on joint development. Engage in hands-on projects to develop practical AI solutions."
      >
        <div>
          <br />
          <TwoPartRow cardsContent={co_development_model} />
        </div>
      </AIBanner>
      <br />
      <br /> */}

      <br />
      <br />
      <br />

      <Header
        variant="h4"
        caption="We are our core values? What motivates us? What are our credentials? How do we engage with clients?"
      >
        Other Information 
      </Header>

      <Box sx={{ width: "100%", maxWidth: 1000, paddingX: 2, mx: "auto" }}>
        <GenericAccordion
          title="Who are We? What are our credentials?"
          isCollapsed={true} 
          headerColor="#FFE5D4">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
            }}>
            <div>
              <TwoPartRow cardsContent={profile_topics} />
            </div>
          </Box>
        </GenericAccordion>
      </Box>
      <Box sx={{ width: "100%", maxWidth: 1000, paddingX: 2, mx: "auto" }}>
        <GenericAccordion
          title="Advanced AI/ML Workshops for Investments Professionals"
          isCollapsed={true} 
          headerColor="#C8E6C9">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
            }}>
            <div>
            <CourseWork/>

      <br />
   
            </div>
          </Box>
        </GenericAccordion>
      </Box>

      <Box sx={{ width: "100%", maxWidth: 1000, paddingX: 2, mx: "auto" }}>
        <GenericAccordion
          title="Interactive Mathematical Finance - Interactive Options Book"
          isCollapsed={true} 
          headerColor="#E1BEE7">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 2,
            }}>
            <div>
            <ImageCard img="https://storage.googleapis.com/chapters-ifm/GENAI_COURSEWORK/2024_SEP/Rick_Bookstaber.jpg" />
                <RedirectButton
                   url="https://options.21ifm.com"
                  label="Go to Interactive Options Book"
                  />

      <br />
      <br /> 
            </div>
          </Box>
        </GenericAccordion>
      </Box>

    






      <br />
      <br />
    
      <FloatingContactButton />
      <br />



      <br />
      <br />

      <ContactUs />
      <br />
      <br />
      <Box
        sx={{
          width: "100%",
          position: "fixed",
          top: "2%",
          left: "56%",
          zIndex: 100000,
        }}
      >
        <ButtonMailto
          label="Email "
          mailto="mailto:office@perceptron.solutions?subject=Regarding Perceptron Solutions&body=Hello%2C%0A%0A%0A%0APlease send me more information about your product.%0A%0AI want to  ----- %0A%0AI live in  -----%0A%0AI heard about you from  -----%0A%0A Thank you.%0A%0ARegards%2C%0AYour Name"
        />
      </Box>
    </div>
  );
};

export default LandingPage;
