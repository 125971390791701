import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={850}
    height={1100}
    style={{
      backgroundColor: "#e6e6e6",
    }}
    viewBox="-0.5 -0.5 850 1100"
    {...props}
  >
    <path fill="none" d="M80 260h710v360H80z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe flex-start",
            width: 708,
            height: 1,
            paddingTop: 267,
            marginLeft: 82,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              <font
                style={{
                  borderColor: "var(--border-color)",
                  color: "#000",
                  fontFamily: "Helvetica",
                  fontSize: 54,
                  fontStyle: "normal",
                  fontVariantLigatures: "normal",
                  fontVariantCaps: "normal",
                  fontWeight: 400,
                  letterSpacing: "normal",
                  orphans: 2,
                  textIndent: 0,
                  textTransform: "none",
                  widows: 2,
                  wordSpacing: 0,
                  WebkitTextStrokeWidth: 0,
                  backgroundColor: "#fbfbfb",
                  textDecorationThickness: "initial",
                  textDecorationStyle: "initial",
                  textDecorationColor: "initial",
                }}
              >
                {"Advanced Techniques in"}
                <br
                  style={{
                    borderColor: "var(--border-color)",
                  }}
                />
                {"Machine Learning & AI for"}
                <br
                  style={{
                    borderColor: "var(--border-color)",
                  }}
                />
              </font>
              <br
                style={{
                  borderColor: "var(--border-color)",
                  color: "#000",
                  fontFamily: "Helvetica",
                  fontSize: 54,
                  fontStyle: "normal",
                  fontVariantLigatures: "normal",
                  fontVariantCaps: "normal",
                  fontWeight: 400,
                  letterSpacing: "normal",
                  orphans: 2,
                  textIndent: 0,
                  textTransform: "none",
                  widows: 2,
                  wordSpacing: 0,
                  WebkitTextStrokeWidth: 0,
                  backgroundColor: "#fbfbfb",
                  textDecorationThickness: "initial",
                  textDecorationStyle: "initial",
                  textDecorationColor: "initial",
                }}
              />
              <br
                style={{
                  borderColor: "var(--border-color)",
                  color: "#000",
                  fontFamily: "Helvetica",
                  fontSize: 54,
                  fontStyle: "normal",
                  fontVariantLigatures: "normal",
                  fontVariantCaps: "normal",
                  fontWeight: 400,
                  letterSpacing: "normal",
                  orphans: 2,
                  textIndent: 0,
                  textTransform: "none",
                  widows: 2,
                  wordSpacing: 0,
                  WebkitTextStrokeWidth: 0,
                  backgroundColor: "#fbfbfb",
                  textDecorationThickness: "initial",
                  textDecorationStyle: "initial",
                  textDecorationColor: "initial",
                }}
              />
              <font
                style={{
                  borderColor: "var(--border-color)",
                  color: "#000",
                  fontFamily: "Helvetica",
                  fontSize: 54,
                  fontStyle: "normal",
                  fontVariantLigatures: "normal",
                  fontVariantCaps: "normal",
                  fontWeight: 400,
                  letterSpacing: "normal",
                  orphans: 2,
                  textIndent: 0,
                  textTransform: "none",
                  widows: 2,
                  wordSpacing: 0,
                  WebkitTextStrokeWidth: 0,
                  backgroundColor: "#fbfbfb",
                  textDecorationThickness: "initial",
                  textDecorationStyle: "initial",
                  textDecorationColor: "initial",
                }}
              >
                {"Financial Time Series &"}
                <br
                  style={{
                    borderColor: "var(--border-color)",
                  }}
                />
                {"Unstructured Data Analysis."}
              </font>
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={82} y={279} fontFamily="Helvetica" fontSize={12}>
        {"Advanced Techniques in..."}
      </text>
    </switch>
    <path fill="none" d="M80 650h670v110H80z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 705,
            marginLeft: 82,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 28,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {"These customized, interactive, in-person workshops"}
              <br
                style={{
                  fontSize: 28,
                }}
              />
              {"are designed specifically for executives and financial"}
              <br />
              {"professionals without a programming background"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={82} y={713} fontFamily="Helvetica" fontSize={28}>
        {"These customized, interactive, in-person worksho..."}
      </text>
    </switch>
    <path fill="none" d="M75 130h490v50H75z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 1,
            height: 1,
            paddingTop: 155,
            marginLeft: 320,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 28,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {"AI workshop series for Genus Capital"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={320}
        y={163}
        fontFamily="Helvetica"
        fontSize={28}
        textAnchor="middle"
      >
        {"AI workshop series for Genus Capital"}
      </text>
    </switch>
    <path fill="none" d="M75 870h120v40H75z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 890,
            marginLeft: 77,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 18,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {"Pavan Mirla"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={77} y={895} fontFamily="Helvetica" fontSize={18}>
        {"Pavan Mirla"}
      </text>
    </switch>
    <path fill="none" d="M75 900h270v30H75z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 915,
            marginLeft: 77,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {"Lead AI Data Scientist at perceptron Solutions"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={77} y={919} fontFamily="Helvetica" fontSize={12}>
        {"Lead AI Data Scientist at perceptron Solutions"}
      </text>
    </switch>
    <path fill="none" d="M75 940h370v70H75z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 975,
            marginLeft: 77,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              <b>
                {"Past Experience:"}
                <br />
              </b>
              {"Lead ML Data Scientist at Manulife/John Hancock Innovation Lab"}
              <br />
              {"Quant Analyst manulife Asset management"}
              <br />
              {"Quant Analyst CPP Investment Board (CPPIB)"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={77} y={979} fontFamily="Helvetica" fontSize={12}>
        {"Past Experience:..."}
      </text>
    </switch>
    <path fill="none" d="M75 1015h180v40H75z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 1035,
            marginLeft: 77,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              <b>{"Contact"}</b>
              {":"}
              <br />
              {"Office@perceptron.solutions"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={77} y={1039} fontFamily="Helvetica" fontSize={12}>
        {"Contact:..."}
      </text>
    </switch>
    <path fill="none" d="M75 1040h110v30H75z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 1055,
            marginLeft: 77,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {"+1 2369898450"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={77} y={1059} fontFamily="Helvetica" fontSize={12}>
        {"+1 2369898450"}
      </text>
    </switch>
    {/* <switch>
      <a
        xlinkHref="https://www.drawio.com/doc/faq/svg-export-text-problems"
        target="_blank"
        transform="translate(0 -5)"
      >
        <text x="50%" y="100%" fontSize={10} textAnchor="middle">
          {"Text is not SVG - cannot display"}
        </text>
      </a>
    </switch> */}
  </svg>
)
export default SvgComponent
