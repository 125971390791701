import React, { useState, Suspense } from 'react';
import { Button, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // Add Material-UI styles here as needed.
}));

const QuizComponent = ({ mdxModules }) => {
  const classes = useStyles();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const CurrentQuestionMDX = mdxModules[currentQuestionIndex];

  const handleConfirm = () => {
    if (currentQuestionIndex < mdxModules.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Logic for finishing the quiz or moving to a results page
    }
  };

  return (
    <Paper className={classes.root}>
      {/* Tracker */}
      <div className={classes.tracker}>
        {mdxModules.map((_, index) => (
          <span key={index} className={index === currentQuestionIndex ? classes.activeDot : classes.dot}></span>
        ))}
      </div>

      {/* MDX Content */}
      <Suspense fallback={<div>Loading...</div>}>
        <CurrentQuestionMDX />
      </Suspense>

      {/* Confirm Button */}
      <Button variant="contained" color="primary" onClick={handleConfirm}>
        Confirm
      </Button>
    </Paper>
  );
};

export default QuizComponent;
