import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCOQ_gqMjnT1FNhsLQCjGqFUIhhJC2_AlI",
  authDomain: "perceptron-5b3d4.firebaseapp.com",
  projectId: "perceptron-5b3d4",
  storageBucket: "perceptron-5b3d4.appspot.com",
  messagingSenderId: "1030540304343",
  appId: "1:1030540304343:web:8b41cd6056f102d9def592",
  measurementId: "G-J4R7WY5XKJ"
};
// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  if (typeof window !== "undefined") {
    firebase.analytics();
  }
}

export default firebase;
