// import React from 'react';
// import ReactPlayer from 'react-player';

// const ArticleNew = ({ title, content, link, video, image }) => {
//   return (
//     <div className="flip-card">
//       <div className="flip-card-inner">
//         {/* Front side of the card */}
//         <div className="flip-card-front" style={styles.front}>
//           <h2 style={styles.title}>{title}</h2>

//           {/* Prefer video if available, otherwise render image */}
//           {video ? (
//             <div className="media-container" style={styles.videoContainer}>
//               <ReactPlayer 
//                 url={video} 
//                 width="100%" 
//                 height="100%" 
//                 style={{ position: 'absolute', top: 0, left: 0 }} 
//                 controls 
//               />
//             </div>
//           ) : image ? (
//             <div className="media-container" style={styles.imageContainer}>
//               <img src={image} alt={title} style={styles.image} />
//             </div>
//           ) : null}
//         </div>

//         {/* Back side of the card */}
//         <div className="flip-card-back" style={styles.back}>
//           <p style={styles.content}>{content}</p>
//         </div>
//       </div>

//       {/* "Read More" link is placed outside of the flip-card to avoid triggering the flip */}
//       {link && (
//         <a href={link} className="read-more" target="_blank" rel="noopener noreferrer" style={styles.readMore}>
//           Read More
//         </a>
//       )}
//     </div>
//   );
// };

// // CSS-like object styles
// const styles = {
//   front: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backfaceVisibility: 'hidden',
//   },
//   back: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: '#007BFF',
//     color: 'white',
//     transform: 'rotateY(180deg)',
//     padding: '16px',
//     textAlign: 'center',
//   },
//   title: {
//     fontSize: '1.2em',
//     marginBottom: '16px',
//     textAlign: 'center',
//   },
//   videoContainer: {
//     position: 'relative',
//     paddingTop: '56.25%', // Aspect ratio for video (16:9)
//     width: '100%',
//     marginBottom: '16px',
//   },
//   imageContainer: {
//     width: '100%',
//     height: 'auto',
//     marginBottom: '16px',
//     overflow: 'hidden',
//     borderRadius: '8px',
//   },
//   image: {
//     width: '100%',
//     height: 'auto',
//     objectFit: 'cover',
//   },
//   content: {
//     fontSize: '1em',
//     color: '#fff',
//     textAlign: 'center',
//   },
//   readMore: {
//     display: 'block',
//     color: '#007BFF',
//     textDecoration: 'none',
//     marginTop: '16px',
//     textAlign: 'center',
//     fontWeight: 'bold',
//   },
// };

// // CSS in JS for card flip functionality
// const flipCardStyles = `
//   .flip-card {
//     background-color: transparent;
//     width: 300px;
//     height: 400px;
//     perspective: 1000px;
//     margin: 16px;
//   }

//   .flip-card-inner {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     text-align: center;
//     transition: transform 0.6s;
//     transform-style: preserve-3d;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   }

//   .flip-card:hover .flip-card-inner {
//     transform: rotateY(180deg);
//   }

//   .flip-card-front, .flip-card-back {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     backface-visibility: hidden;
//     border-radius: 8px;
//   }

//   .flip-card-back {
//     background-color: #007BFF;
//     color: white;
//     transform: rotateY(180deg);
//   }

//   /* "Read More" link is separated from the flip behavior */
//   .read-more {
//     color: #007BFF;
//     text-decoration: none;
//     font-weight: bold;
//     display: block;
//     margin-top: 8px;
//   }
// `;

// // Adding the custom styles to the document head
// const styleSheet = document.createElement("style");
// styleSheet.type = "text/css";
// styleSheet.innerText = flipCardStyles;
// document.head.appendChild(styleSheet);

// export default ArticleNew;

// Second 

// import React, { useState } from 'react';
// import ReactPlayer from 'react-player';
// import { FaSyncAlt } from 'react-icons/fa'; // Flip icon

// const ArticleNew = ({ title, content, link, video, image }) => {
//   const [isFlipped, setIsFlipped] = useState(false); // Flip state

//   const handleFlip = () => {
//     setIsFlipped(!isFlipped); // Toggle flip state
//   };

//   return (
//     <div className="flip-card">
//       <div className={`flip-card-inner ${isFlipped ? 'flipped' : ''}`}>
//         {/* Front side of the card */}
//         <div className="flip-card-front" style={styles.front}>
//           <h2 style={styles.title}>{title}</h2>

//           {/* Prefer video if available, otherwise render image */}
//           {video ? (
//             <div className="media-container" style={styles.videoContainer}>
//               <ReactPlayer
//                 url={video}
//                 width="100%"
//                 height="100%"
//                 style={{ position: 'absolute', top: 0, left: 0 }}
//                 controls
//               />
//             </div>
//           ) : image ? (
//             <div className="media-container" style={styles.imageContainer}>
//               <img src={image} alt={title} style={styles.image} />
//             </div>
//           ) : null}

//           {/* Flip Icon on the front side */}
//           <FaSyncAlt onClick={handleFlip} style={styles.flipIconFront} />
//         </div>

//         {/* Back side of the card */}
//         <div className="flip-card-back" style={styles.back}>
//           <p style={styles.content}>{content}</p>
//           {/* Flip Icon on the back side */}
//           <FaSyncAlt onClick={handleFlip} style={styles.flipIconBack} />
//         </div>
//       </div>

//       {/* "Read More" link is placed outside of the flip-card to avoid triggering the flip */}
//       {link && (
//         <a href={link} className="read-more" target="_blank" rel="noopener noreferrer" style={styles.readMore}>
//           Read More
//         </a>
//       )}

//       {/* Padding or margin added after Read More */}
//       <div style={styles.bottomPadding} />
//     </div>
//   );
// };

// // CSS-like object styles
// const styles = {
//   front: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backfaceVisibility: 'hidden',
//     position: 'relative',
//   },
//   back: {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: '#007BFF',
//     color: 'white',
//     padding: '16px',
//     textAlign: 'center',
//     position: 'absolute',
//     top: 0,
//     width: '100%',
//     height: '100%',
//     backfaceVisibility: 'hidden',
//   },
//   title: {
//     fontSize: '1.2em',
//     marginBottom: '16px',
//     textAlign: 'center',
//   },
//   videoContainer: {
//     position: 'relative',
//     paddingTop: '56.25%', // Aspect ratio for video (16:9)
//     width: '100%',
//     marginBottom: '16px',
//     pointerEvents: 'auto', // Allow interaction with video
//   },
//   imageContainer: {
//     width: '100%',
//     height: 'auto',
//     marginBottom: '16px',
//     overflow: 'hidden',
//     borderRadius: '8px',
//     pointerEvents: 'auto', // Allow interaction with image
//   },
//   image: {
//     width: '100%',
//     height: 'auto',
//     objectFit: 'cover',
//   },
//   content: {
//     fontSize: '1em',
//     color: '#fff',
//     textAlign: 'center',
//   },
//   readMore: {
//     display: 'block',
//     color: '#007BFF',
//     textDecoration: 'none',
//     marginTop: '16px',
//     textAlign: 'center',
//     fontWeight: 'bold',
//   },
//   flipIconFront: {
//     position: 'absolute',
//     bottom: '10px',
//     right: '10px',
//     cursor: 'pointer',
//     color: '#007BFF', // Front icon color matches the card color
//     fontSize: '1.5rem',
//   },
//   flipIconBack: {
//     position: 'absolute',
//     bottom: '10px',
//     right: '10px',
//     cursor: 'pointer',
//     color: '#fff', // Back icon color is white to match the blue background
//     fontSize: '1.5rem',
//   },
//   bottomPadding: {
//     height: '20px', // Add padding to the bottom for better alignment
//   },
// };

// // Adding the custom styles to the document head
// const flipCardStyles = `
//   .flip-card {
//     background-color: transparent;
//     width: 100%;
//     height: 400px;
//     perspective: 1000px;
//     margin: 16px;
//     position: relative;
//     max-width: 300px;
//   }

//   .flip-card-inner {
//     position: relative;
//     width: 100%;
//     height: 100%;
//     text-align: center;
//     transition: transform 0.6s;
//     transform-style: preserve-3d;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   }

//   .flip-card-inner.flipped {
//     transform: rotateY(180deg);
//   }

//   .flip-card-front, .flip-card-back {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     backface-visibility: hidden;
//     border-radius: 8px;
//   }

//   .flip-card-back {
//     background-color: #007BFF;
//     color: white;
//     transform: rotateY(180deg);
//   }

//   .card-container {
//     display: grid;
//     grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
//     gap: 16px;
//     justify-items: center;
//   }

//   @media (max-width: 768px) {
//     .card-container {
//       grid-template-columns: repeat(2, 1fr); /* Display 2 cards per row on medium screens */
//     }
//   }

//   @media (max-width: 480px) {
//     .card-container {
//       grid-template-columns: repeat(1, 1fr); /* Display 1 card per row on small screens */
//     }
//   }
// `;

// // Adding the custom styles to the document head
// const styleSheet = document.createElement("style");
// styleSheet.type = "text/css";
// styleSheet.innerText = flipCardStyles;
// document.head.appendChild(styleSheet);

// export default ArticleNew;


// ArticleNew.jsx
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { FaSyncAlt } from 'react-icons/fa'; // Flip icon

const ArticleNew = ({ title, content, link, video, image }) => {
  const [isFlipped, setIsFlipped] = useState(false); // Flip state

  const handleFlip = () => {
    setIsFlipped(!isFlipped); // Toggle flip state
  };

  return (
    <div className="flip-card">
      <div className={`flip-card-inner ${isFlipped ? 'flipped' : ''}`}>
        {/* Front side of the card */}
        <div className="flip-card-front" style={styles.front}>
          <h2 style={styles.title}>{title}</h2>

          {/* Prefer video if available, otherwise render image */}
          {video ? (
            <div className="media-container" style={styles.videoContainer}>
              <ReactPlayer
                url={video}
                width="100%"
                height="100%"
                style={{ position: 'absolute', top: 0, left: 0 }}
                controls
              />
            </div>
          ) : image ? (
            <div className="media-container" style={styles.imageContainer}>
              <img src={image} alt={title} style={styles.image} />
            </div>
          ) : null}

          {/* Flip Icon on the front side */}
          <FaSyncAlt onClick={handleFlip} style={styles.flipIconFront} />
        </div>

        {/* Back side of the card */}
        <div className="flip-card-back" style={styles.back}>
          <div style={styles.backContent}>
            <p style={styles.content}>{content}</p>
          </div>
          {/* Flip Icon on the back side */}
          <FaSyncAlt onClick={handleFlip} style={styles.flipIconBack} />
        </div>
      </div>

      {/* "Read More" link is placed outside of the flip-card to avoid triggering the flip */}
      {link && (
        <a href={link} className="read-more" target="_blank" rel="noopener noreferrer" style={styles.readMore}>
          Read More
        </a>
      )}

      {/* Padding or margin added after Read More */}
      <div style={styles.bottomPadding} />
    </div>
  );
};

// CSS-like object styles
const styles = {
  front: {
    display: 'flex',
    flexDirection: 'column', // Ensure column layout
    alignItems: 'center',
    justifyContent: 'flex-start', // Start from top
    backfaceVisibility: 'hidden',
    position: 'absolute', // Set to absolute for alignment
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#FFF1E0',
    borderRadius: '8px',
    padding: '16px',
  },
  back: {
    display: 'flex',
    flexDirection: 'column', // Ensure column layout
    alignItems: 'center',
    justifyContent: 'flex-start', // Start from top
    backfaceVisibility: 'hidden',
    position: 'absolute', // Set to absolute for alignment
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#007BFF',
    color: 'white',
    borderRadius: '8px',
    padding: '16px',
  },
  backContent: {
    marginTop: '13%', // Start content from 1/3 down the card
    width: '100%',
    padding: '0 16px',
  },
  title: {
    marginTop: '13%',
    fontSize: '2em',
    fontFamily: 'Arial, sans-serif',
    marginBottom: '16px',
    textAlign: 'center',
    color: '#333333',
  },
  videoContainer: {
    position: 'relative',
    paddingTop: '56.25%', // Aspect ratio for video (16:9)
    width: '100%',
    marginBottom: '16px',
    pointerEvents: 'auto',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  imageContainer: {
    width: '100%',
    height: 'auto',
    marginBottom: '16px',
    overflow: 'hidden',
    borderRadius: '8px',
    pointerEvents: 'auto',
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  },
  content: {
    fontSize: '1.2em',
    fontFamily: 'Arial, sans-serif',
    color: '#fff',
    textAlign: 'center',
    lineHeight: '1.5',
  },
  readMore: {
    display: 'block',
    color: '#007BFF',
    textDecoration: 'none',
    marginTop: '16px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  flipIconFront: {
    marginTop: 'auto', // Push icon to bottom
    cursor: 'pointer',
    color: '#007BFF',
    fontSize: '1.5rem',
  },
  flipIconBack: {
    marginTop: 'auto', // Push icon to bottom
    cursor: 'pointer',
    color: '#FFFFFF',
    fontSize: '1.5rem',
  },
  bottomPadding: {
    height: '20px',
  },
};

// CSS for card flip functionality
const flipCardStyles = `
  .flip-card {
    background-color: transparent;
    width: 100%;
    height: 400px;
    perspective: 1000px;
    margin: 16px;
    position: relative;
    max-width: 300px;
    flex: 1 1 300px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: '#FFF1E0';
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  .flip-card-inner.flipped {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 8px;
  }

  .flip-card-back {
    transform: rotateY(180deg);
  }
`;

// Adding the custom styles to the document head
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = flipCardStyles;
document.head.appendChild(styleSheet);

export default ArticleNew;

