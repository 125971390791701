import React, { useEffect, useState, lazy, Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import Container from '@material-ui/core/Container';


import { createTheme, ThemeProvider } from '@mui/material/styles';

import Heading from "./components/Heading";

import VideoSection from "./components/thefront/VideoSection";
import Reviews from "./components/Reviews.js";
import Search from "./components/thefront/Search";
import Contact from "./components/thefront/Contact";

import SignUp from "./components/SignUp";
import SignInMagicLink from "./components/SignInMagicLink";
import MagicLinkWait from "./components/MagicLinkWait";
import SignIn from "./components/SignIn";
import { loadStripe } from "@stripe/stripe-js";
import ForgotPassword from "./components/ForgotPassword";
import Design from "./DesignCompany"

// const About = lazy(() => importMDX('./pages/About.mdx'));




import AppHeader from "./components/AppHeader";
import Hero from './components/Hero';
const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");

const theme = createTheme({
  palette: {
    mode: "light"
  },
  typography: {
    subtitle1: {
      fontSize: 12
    },
    body1: {
      fontWeight: 500
    }
  }
});

const componentsMap = {
  h1: Heading.h3, // mapping h3 to h1 so we're having a smaller font-size
  h2: Heading.h4
};


export default function MathApp() {

  const [isDrawerOpen, SetIsDrawerOpen] = useState(false);


  let topicsJSON = [];
  let mathTopicsJSON =[]
  let statsTopicsJSON= []
  let optionsTopicsJSON= []
  try {

  } catch (err) {}

  const handleDrawerClick = () => {
    // SetIsDrawerOpen(false);
    SetIsDrawerOpen(prevCheck => !prevCheck);
  };


  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          {/* {
            componentsAndIds.map(({id, Component}) => (
              <Route key={id} path={"/"+id} > 
                {Component}
              </Route>
            ))
          } */}
        <Route exact path="/">
        <AppHeader handleDrawerToggle={handleDrawerClick}/>
          <Home/>
        </Route>
   




        <Route path="/taxes">
          <h2>Taxes</h2>
        </Route>


        <Route path="/loans">
          <h2>Loans</h2>
        </Route>


        <Route path="/design">
          <Design />
        </Route>

        <Route path="/signup">
          <SignUp />
        </Route>

        <Route path="/signinLink">
          <SignInMagicLink />
        </Route>


        <Route path="/wait">
          <MagicLinkWait />
        </Route>

        <Route path="/forgotPassword">
          <ForgotPassword />
        </Route>



        <Route path="/signin">
          <SignIn />
        </Route>
        <Route path="/about/" render={() => 
            <Suspense fallback={<div>Loading...</div>}>
              {/* <About />  */}
              {/* //https://v0.mdxjs.com/getting-started/create-react-app */}
            </Suspense>
        } />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

function Home() {


  return (
    <>
<div style={{ height: '1000', margin: 0, padding: 0 }}>
         <div> 
           <h2>Topics</h2>
         </div>
  
         <Contact/>

         {/* <Hero/> */}
    

        {/* <VideoSection/> */}



  </div>
    </>
  );
}


