
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import AlphabetCards from "../../../components/AlphabetCards";
import GeogebraDraw_Temp from "../../../components/GeogebraDraw_Temp";
import VAEComponentBeginner from  "../../../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../../../blocks/VAEComponentGraduate.js";
import MyCard from  "../../../components/MyCard.js";
import SVGArrow from  "../../../components/SVGArrow.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import SimpleModalBlockly from  "../../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player';
import Ws1 from '../../../data/Ws_1.js';
import Ws2 from '../../../data/Ws_2.js';
import Ws3 from '../../../data/Ws_3.js';
import Ws4 from '../../../data/Ws_4.js';
import Ws5 from '../../../data/Ws_5.js';
import '../../../data/svg_styles.css';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Genus Workshops`}</h1>
    <div className="centered-content">
  <Ws1 mdxType="Ws1" />
    </div>
    <div className="centered-content">
  <Ws2 mdxType="Ws2" />
    </div>
    <div className="centered-content">
  <Ws3 mdxType="Ws3" />
    </div>
    <div className="centered-content">
  <Ws4 mdxType="Ws4" />
    </div>
    <div className="centered-content">
  <Ws5 mdxType="Ws5" />
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;