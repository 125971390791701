import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import VideoPlayer from '../VideoPlayerSimple'; // Ensure the correct relative path

export default function CardComponent({ topic }) {
  return (
    <Card sx={{ maxWidth: 345, margin: '20px' }}>
      <CardActionArea>
        {/* Display the video if present */}
        {topic.video && (
          <Box sx={{ height: 200, marginBottom: 2 }}>
            <VideoPlayer url={topic.video} width="100%" height="200px" /> {/* Adjust height here */}
          </Box>
        )}
        
        {/* Display the image if present */}
        {topic.image && (
          <Box sx={{ height: 200, marginBottom: 2, overflow: 'hidden' }}>
            <img 
              src={topic.image} 
              alt={topic.title || 'Topic image'} 
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
        )}
        
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {topic.title}
          </Typography>
          <Typography variant="body2" color="text.secondary" gutterBottom>
            <strong>Summary:</strong> {topic.caption}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Details:</strong> {topic.description}
          </Typography>
          <Box mt={2}>
            {topic.tags && topic.tags.map(tag => (
              <Chip key={tag} label={tag} sx={{ margin: '2px' }} />
            ))}
          </Box>
          <Box mt={2}>
            {topic.links && topic.links.map((link, index) => (
              <Typography variant="body2" key={index}>
                <Link href={link.url} target="_blank" rel="noopener">
                  {link.description}
                </Link>
              </Typography>
            ))}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
