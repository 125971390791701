
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import AlphabetCards from "../../../components/AlphabetCards";
import GeogebraDraw_Temp from "../../../components/GeogebraDraw_Temp";
import VAEComponentBeginner from  "../../../blocks/VAEComponentBeginner.js";
import VAEComponentGraduate from  "../../../blocks/VAEComponentGraduate.js";
import MyCard from  "../../../components/MyCard.js";
import SVGArrow from  "../../../components/SVGArrow.js";
import TimeEstimate from  "../../../components/TimeEstimate.js";
import SimpleModalBlockly from  "../../../components/SimpleModalBlockly.js";
import ReactPlayer from 'react-player'
import AIBanner from "../../../components/AIBanner.js";
import FlipCardGrid from "../../../components/FlipCardGrid.js";
import { FaHourglassHalf } from 'react-icons/fa';
import MetaTags from '../../../components/MetaTags';
export const cardsContent = [{
  "title": "What are Bond Yields?",
  "content": "Bond yields represent the return an investor will receive by holding the bond to maturity. It is essentially the amount of interest paid by bond issuers to bondholders."
}, {
  "title": "Bond Yields Determination",
  "content": "Bond yields reflect economic health, expected inflation, and future interest rates."
}, {
  "title": "Federal Funds Rate Meaning",
  "content": "It's the overnight lending rate set by the central bank, crucial for monetary policy."
}, {
  "title": "Term Structure of Interest Rates",
  "content": "Yield curves represent bond yields across different maturities; short-term yields resonate with current central bank rates, while long-term yields reflect inflation and growth expectations."
}, {
  "title": "Expectations Impact on Bond Yields",
  "content": "Long-term bond yields rise with expectations of future inflation or higher short-term rates."
}, {
  "title": "Federal Funds Rate Expectations",
  "content": "Set by central banks based on future inflation, employment predictions, and broader economic objectives."
}, {
  "title": "Economic Indicators' Influence",
  "content": "Strong economic data can lead to higher bond yields, signaling expected inflation and tighter monetary policy."
}, {
  "title": "Supply and Demand Dynamics",
  "content": "High demand for bonds like U.S. Treasuries leads to higher prices and lower yields."
}, {
  "title": "Federal Funds Rate & Demand",
  "content": "Supply and demand can affect the actual federal funds rate, but the target is set by the central bank."
}, {
  "title": "Role of External Factors",
  "content": "Global conditions, geopolitical events, and worldwide interest rates can sway both bond yields and federal funds rate decisions."
}, {
  "title": "Inflation's Influence on Bonds",
  "content": "Expected inflation hikes lead to rising bond yields, ensuring real returns for investors."
}, {
  "title": "Federal Funds Rate & Inflation",
  "content": "Central banks tweak this rate to maintain a target inflation level."
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImgMediaCard = makeShortcode("ImgMediaCard");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const layoutProps = {
  cardsContent
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <br />
    <br />
    <MetaTags title="Yield Curves & Federal Rates Since 1980" description="A curated visual exploration of economic shifts, as told by annual yield curves and federal fund rates from 1980 to 2023." imageUrl="https://storage.googleapis.com/chapters-ifm/YieldCurves4/yield_curves.png" pageUrl="https://perceptron.solutions/yield_curves" mdxType="MetaTags" />
    <br />
    <br />
 <AIBanner titleLine1="Yield Curves & Federal Rates " titleLine2="Since 1980" description="A curated visual exploration of economic shifts, as told by annual yield curves and federal fund rates from 1980 to 2023." mdxType="AIBanner" />
    <br />
    <br />
    <br />
    <br />

    <h1>{`Understanding Bond Yields & Federal Funds Rate`}</h1>
    <br />
    <br />
    <br />
    <FlipCardGrid cardsContent={cardsContent} mdxType="FlipCardGrid" />;
    <br />
    <br />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19800731.png" title="1980" description="The U.S. economy experienced stagflation, a combination of high inflation and stagnant growth. 
    In response, the Federal Reserve, under Paul Volcker, initiated a policy of tight monetary supply to combat inflation.
    Steep upward sloped yield curve, reflecting the high interest rates set by the Federal Reserve to combat inflation." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
  <div style={{
      position: 'relative',
      width: '100%'
    }}>
    <br />
            <VideoMediaCard videoUrl="https://youtu.be/FWcB9-SAYE0?si=0nQarI7L3-WL490f" title="Find out how then Federal Reserve Chairman Paul Volcker contained inflation, spurred economic growth, and reduced unemployment" description="1980." link="" mdxType="VideoMediaCard" />
    </div>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19810731.png" title="1981" description="Economic conditions deteriorated into a severe recession as the Federal Reserve continued its tight monetary policy." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19820731.png" title="1982" description="The economy began recovering from the recession towards the end of the year, in part due to a loosening of the tight monetary policy.
    Flattening yield curve, signaling a transition period with the onset of a recession and declining inflation." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19830731.png" title="1983" description="The U.S. economy rebounded with robust growth, marking an end to the early 1980s recession." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19840731.png" title="1984" description="The economic recovery continued, leading to increased consumer and business confidence." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19850731.png" title="1985" description="The Plaza Accord was signed to depreciate the U.S. dollar in relation to the Japanese yen and German Deutsche Mark." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19860731.png" title="1986" description=" A significant drop in oil prices led to cheaper gas, acting as a form of tax cut and stimulating consumer spending." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19870731.png" title="1987" description="The stock market crashed on October 19th, known as 'Black Monday,' but the broader economy remained resilient." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19880731.png" title="1988" description="The economy continued to grow, but concerns about rising inflation led the Federal Reserve to adopt a tighter monetary stance." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19890731.png" title="1989" description="The savings and loan crisis began, leading to the bailout of numerous financial institutions. The economy started showing signs of slowing down." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19900731.png" title="1990" description="Economic growth stagnated, leading to a mild recession. The Gulf War also began, leading to a spike in oil prices.
    Inverted yield curve, signaling the onset of a recession and concerns about the future economy." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19910731.png" title="1991" description="The economy remained in a recession for the early part of the year but began recovering in the latter half." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19920731.png" title="1992" description="The U.S. saw modest economic growth, but unemployment remained relatively high." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19930731.png" title="1993" description="The economy continued its moderate growth, and the North American Free Trade Agreement (NAFTA) was signed." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19940731.png" title="1994" description="The Federal Reserve began a series of interest rate hikes to preemptively combat potential inflation." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19950731.png" title="1995" description="The U.S. economy continued its expansion, and the dot-com bubble started to inflate as tech stocks soared.
    Upward sloping, representing a robust economy with growth prospects." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19960731.png" title="1996" description="The economy remained strong, with low inflation and unemployment. The stock market saw robust returns, especially in the tech sector." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19970731.png" title="1997" description="The Asian Financial Crisis began, leading to economic turmoil in several Asian markets, but the U.S. economy remained largely unaffected." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19980731.png" title="1998" description="The Long-Term Capital Management (LTCM) hedge fund collapsed, leading to a Federal Reserve-coordinated bailout. The U.S. economy continued its growth." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19990731.png" title="1999" description="The dot-com bubble approached its peak, and concerns about the Y2K bug led to significant investments in tech infrastructure." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20000731.png" title="2000" description="The dot-com bubble burst, leading to a decline in the stock market, especially in tech stocks, which would set the stage for an economic downturn in the following year.
    The bursting of the dot-com bubble led to a recession; the Federal Reserve responded by cutting rates.
    Inverted yield curve, suggesting concerns of a looming recession and the burst of the dot-com bubble." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
  <div style={{
      position: 'relative',
      width: '100%'
    }}>
    <br />
            <VideoMediaCard videoUrl="https://www.youtube.com/watch?v=V5iE-4JsUms" title="The yield curve during this period was generally upward sloping but began to invert in the late stages of the bubble, signaling economic caution ahead." description="2000" link="" mdxType="VideoMediaCard" />
    </div>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20010731.png" title="2001" description="Recession continued due to the aftermath of the dot-com bubble burst and was further exacerbated by the 9/11 terrorist attacks." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20020731.png" title="2002" description=" Economic aftershocks from the previous years persisted, with corporate scandals (e.g., Enron) affecting investor confidence." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20030731.png" title="2003" description="The economy started to recover, aided in part by the Bush tax cuts and continued low interest rates.
    Economic stimuli, including tax cuts and an aggressive monetary policy from the Federal Reserve, contributed to a positive economic outlook and a steepening yield curve." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20040731.png" title="2004" description="The Federal Reserve began to raise rates in response to a strengthening economy." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20050731.png" title="2005" description="The housing market boomed, and the Federal Reserve continued its trend of raising interest rates." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20060731.png" title="2006" description="Early signs of stress in the housing market emerged, but the economy remained strong." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20070731.png" title="2007" description="The subprime mortgage crisis began, leading to credit market disruptions." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20080731.png" title="2008" description="The global financial crisis erupted, resulting in the collapse of major financial institutions and prompting massive central bank intervention.
    Steep upward sloped yield curve. The full-blown financial crisis led the Federal Reserve to slash short-term rates to near zero, while longer-term yields remained higher, reflecting both the policy response and uncertainty about the future." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
  <div style={{
      position: 'relative',
      width: '100%'
    }}>
    <br />
            <VideoMediaCard videoUrl="https://www.youtube.com/watch?v=bluUxKIB4pA" title="Stocks plunge over 40% from their highs on credit concerns." description="2000" link="" mdxType="VideoMediaCard" />
    </div>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20090731.png" title="2009" description="The Great Recession bottomed out, and global central banks introduced significant stimulus measures." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20100731.png" title="2010" description="Recovery from the Great Recession began but was slow and often referred to as a 'jobless recovery'" link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20110731.png" title="2011" description="Concerns about European sovereign debt crisis arose, impacting global markets." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_19800731.png" title="2012" description="The U.S. faced the 'fiscal cliff' concerns, while central banks worldwide continued with accommodative monetary policies." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20130731.png" title="2013" description="The 'taper tantrum' occurred when the Fed hinted at reducing its bond-buying program." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20140731.png" title="2014" description="Oil prices collapsed, and the Federal Reserve wound down its quantitative easing program." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20150731.png" title="2015" description="Concerns about slowing growth in China and a surprise yuan devaluation roiled markets." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20160731.png" title="2016" description="The Brexit vote and the election of Donald Trump as the U.S. president introduced uncertainties into markets." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20170731.png" title="2017" description="A global synchronized economic upswing occurred, with major stock markets reaching record highs." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20180731.png" title="2018" description="Concerns about global trade tensions and Fed rate hikes led to market volatility." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20190731.png" title="2019" description="Central banks around the world, including the Federal Reserve, began to cut rates in response to slowing global growth." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20200731.png" title="2020" description="The COVID-19 pandemic triggered a global recession, prompting unprecedented monetary and fiscal interventions.
    The US economy entered a recession in February 2020, which was the first recession since 2009.
    The US government passed several stimulus packages to support businesses and individuals affected by the pandemic.
    " link="" mdxType="ImgMediaCard" />
    <br />
    <br />
  <div style={{
      position: 'relative',
      width: '100%'
    }}>
    <br />
            <VideoMediaCard videoUrl="https://www.youtube.com/watch?v=E-Fz0wbA3GE" title="Yield curve to flatten further amid coronavirus worries." description="2020" link="" mdxType="VideoMediaCard" />
    </div>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20210731.png" title="2021" description="The US economy continued to recover, with GDP growth of 6.4 percent in the first quarter of the year.
    Inflation became a concern in many countries, including the US, due to supply chain disruptions and increased demand for goods and services.
    " link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20220731.png" title="2022" description="Inflation remained a concern, with the US experiencing the highest inflation rate in over a decade." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/YieldCurves4/YieldCurve_20230731.png" title="2023" description="Inflation is expected to remain a concern, with the US Federal Reserve expected to raise interest rates to combat rising prices.
    Financial sector turmoil, high inflation, ongoing effects of Russia’s invasion of Ukraine, and three years of COVID are expected to impact the global economy." link="" mdxType="ImgMediaCard" />
    <br />
    <br />
  <div style={{
      position: 'relative',
      width: '100%'
    }}>
    <br />
            <VideoMediaCard videoUrl="https://www.youtube.com/watch?v=v0KBWrzNqJY" title="Yield curve sending a strong recessionary signal." description="2023" link="" mdxType="VideoMediaCard" />
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;