import React, { useContext, useState } from 'react';
import PaymentContext from './paymentContext';
import BuyButton from './BuyButton';
import { authMethods } from '../firebase/authmethods';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';


const overlayStyles = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 9999,
  color: 'white',
  flexDirection: 'column',
};


const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
      backgroundColor: '#f5f5f5',
      fontWeight: 'lighter'
    },
    // Other styles...
  }));


const SomeComponent = () => {
  const { user, paymentFlag, setErrors, setUser } = useContext(PaymentContext);
  const [guestCode, setGuestCode] = useState("");
  const [isGuestAccessGranted, setIsGuestAccessGranted] = useState(false);
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const secretCode = "SECRET";
  const classes = useStyles();

  const handleGuestAccess = (e) => {
    e.preventDefault();
    if (guestCode === secretCode) {
      setIsGuestAccessGranted(true);
    } else {
      alert("Invalid code!");
    }
  };



  if ((!user || paymentFlag === false) && !isGuestAccessGranted) {
    return (
      <div style={overlayStyles}>
        {user && <p>Logged in as: {user.email}</p>}
        {/* {!user && <Typography variant="body1">Please log in or enter a guest access code</Typography>} */}
        {!user && (
    <div>
        <Typography variant="body1">Please enter guest access code</Typography>
        <Typography variant="body1">
            For any inquiries, please <br/>
            <a href="https://www.linkedin.com/in/pavanmirla/" target="_blank" rel="noopener noreferrer" style={{color: 'red'}}>
                follow me on LinkedIn.
            </a>.<br/>
            Or email me at office@perceptron.solutions (No .com)
        </Typography>
    </div>
)}




        {isLoggedOut && <p>You have been logged out.</p>}
        {/* <BuyButton /> */}
        <Box mt={4}> {/* This creates a margin-top of 4 units */}

        <form onSubmit={handleGuestAccess}>
        <TextField 
          type="password"  
          value={guestCode}
          onChange={(e) => setGuestCode(e.target.value)}
          placeholder="Enter guest access code"
        />
       <br/>
        <Button variant="contained" className={classes.button} type="submit">
          Access as Guest
        </Button>
        </form>
        </Box>

      </div>
    )
  }
}

export default SomeComponent;
