
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import LearningGoals from "../../../components/LearningGoals";
import SomeComponent from "../../../components/SomeComponent";
import AlphabetCards from "../../../components/AlphabetCards";
import ReactPlayer from 'react-player'
export const EncoderVideo1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/TRANSFORMERS/ENCODER.mp4' mdxType="ReactPlayer" />
  </div>;
export const DecoderVi = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/TRANSFORMERS/DECODER.mp4' mdxType="ReactPlayer" />
  </div>;
export const goals = [{
  id: 'LG01',
  description: "Introduction to Transformers: Understand the basic concepts of Transformers, their role in Natural Language Processing tasks, and how they overcome limitations of previous sequential models like RNNs and LSTMs."
}, {
  id: 'LG02',
  description: "Transformer Architecture: Gain deep insights into the architecture of a Transformer including components like input embeddings, positional encoding, multi-head attention, and feed-forward networks."
}, {
  id: 'LG03',
  description: "Attention Mechanism: Develop a strong understanding of the Attention mechanism, especially the Scaled Dot-Product Attention, and how it helps the model to focus on important words."
}, {
  id: 'LG04',
  description: "Formatting Input Data: Learn about the process of tokenization, batch creation, and preparing input data for Transformers. Understand the importance of masking and padding in this context."
}, {
  id: 'LG05',
  description: "Question Answering with Transformers: Apply the knowledge of Transformers to the specific task of Question Answering. Understand how to predict the start and end positions of an answer in a given context."
}, {
  id: 'LG06',
  description: "Training and Inference: Understand the process of training a Transformer model including backpropagation, optimization, and loss calculation. Learn about model evaluation and inference to generate answers."
}, {
  id: 'LG07',
  description: "Future Directions and Applications: Understand the practical applications and future directions of Transformers. Explore other tasks where Transformers can be applied, such as translation, text generation, and summarization."
}];
export const alphabetWordsTransformers = [{
  letter: 'A',
  words: ['Applications of Transformers', 'Attention Mechanism in Transformers'],
  quiz: [{
    question: 'What are some applications of Transformers?',
    options: ['Translation, text generation, and summarization', 'Speech recognition and object detection', 'Both A and B', 'None of the above'],
    answer: 'Translation, text generation, and summarization'
  }, {
    question: 'In which of the following tasks are Transformers most commonly used?',
    options: ['Natural Language Processing tasks', 'Image recognition tasks', 'Sound recognition tasks', 'None of the above'],
    answer: 'Natural Language Processing tasks'
  }, {
    question: 'What is the primary role of attention mechanisms in Transformers?',
    options: ['Helping the model focus on important words', 'Converting words to vectors', 'Both A and B', 'None of the above'],
    answer: 'Helping the model focus on important words'
  }]
}, {
  letter: 'D',
  words: ['Dot Products in Transformers'],
  quiz: [{
    question: 'How are dot products used in Transformers?',
    options: ['For weight initialization', 'To calculate attention scores', 'For layer normalization', 'None of the above'],
    answer: 'To calculate attention scores'
  }, {
    question: 'What does a higher dot product value indicate in the context of Transformers?',
    options: ['Higher similarity', 'Lower similarity', 'No relation to similarity', 'Can’t be determined'],
    answer: 'Higher similarity'
  }]
}, {
  letter: 'F',
  words: ['Formatting Input Data for Transformers'],
  quiz: [{
    question: 'What is the role of tokenization in Transformers?',
    options: ['Converting words to numerical representations', 'Helping the model focus on important words', 'Both A and B', 'None of the above'],
    answer: 'Converting words to numerical representations'
  }, {
    question: 'Why is masking important in Transformers?',
    options: ['Prevents the model from peeking ahead during training', 'Helps in tokenization', 'Both A and B', 'None of the above'],
    answer: 'Prevents the model from peeking ahead during training'
  }]
}, {
  letter: 'M',
  words: ['Multi-Head Attention'],
  quiz: [{
    question: 'Why is Multi-Head Attention important in Transformers?',
    options: ['It allows the model to focus on different parts of the input sequence', 'It makes the model run faster', 'It reduces the number of parameters', 'All of the above'],
    answer: 'It allows the model to focus on different parts of the input sequence'
  }, {
    question: 'What is the main advantage of using multiple attention heads instead of one?',
    options: ['Model performance', 'Computation speed', 'Allows the model to jointly attend to information from different positions', 'None of the above'],
    answer: 'Allows the model to jointly attend to information from different positions'
  }]
}, {
  letter: 'P',
  words: ['Parameters in Transformers'],
  quiz: [{
    question: 'What is the role of parameters in Transformers?',
    options: ['They store the information learned by the model', 'They help in calculating attention scores', 'They are used to store input data', 'None of the above'],
    answer: 'They store the information learned by the model'
  }, {
    question: 'How are the parameters updated during the training of Transformers?',
    options: ['Using gradient descent', 'By calculating the dot product', 'By calculating the cosine similarity', 'None of the above'],
    answer: 'Using gradient descent'
  }]
}, {
  letter: 'Q',
  words: ['Question Answering with Transformers'],
  quiz: [{
    question: 'In the context of question answering, what are Transformers trained to predict?',
    options: ['The start and end positions of an answer in the context', 'The most relevant words in the context', 'Both A and B', 'None of the above'],
    answer: 'The start and end positions of an answer in the context'
  }, {
    question: 'What is an advantage of using Transformers for question answering?',
    options: ['Ability to model long-range dependencies', 'Reduction in training time', 'Both A and B', 'None of the above'],
    answer: 'Ability to model long-range dependencies'
  }]
}, {
  letter: 'T',
  words: ['Training and Inference in Transformers'],
  quiz: [{
    question: 'What is a critical part of the training process for Transformers?',
    options: ['Backpropagation and optimization', 'Input data formatting', 'Both A and B', 'None of the above'],
    answer: 'Backpropagation and optimization'
  }, {
    question: 'What is inference in the context of Transformers?',
    options: ['The process of generating answers using a trained model', 'The process of training a model', 'Both A and B', 'None of the above'],
    answer: 'The process of generating answers using a trained model'
  }]
}];
export const topics = [{
  title: 'What are TRANSFORMERS?',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <br />
        <br />
        <Header mdxType="Header">BRIEF INTRODUCTION</Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/Transformers_intro_video.mp4" title="Exploring Transformers: The Building Blocks of Modern NLP" description="Transformer models are the core of state-of-the-art NLP architectures like GPT and BERT. Discover their unique characteristics, advantages over traditional models, and their remarkable impact on machine translation, text summarization, and question-answering systems" link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: "Journey of a word in Transformer Model",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
             <Header mdxType="Header">Word to context rich embedding vector</Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/Journey_of_word.mp4" title="Understanding MultiHead Attention: How a Word's Representation is Transformed" description="Dive into the world of Transformers as we explore how the representation of a word, like 'cat', is updated through the mechanism of MultiHead Attention. Learn about the role of Query, Key, and Value vectors, the concept of attention heads, and the journey of 'cat' from a simple word to a context-enriched vector representation." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: "Bird's Eye View",
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
             <Header mdxType="Header">Bird's Eye View in to Q and A model with Transformers</Header>
            <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/transformer_arch.png" title="Transformer Architecture" description="A visual tour of the intricate steps and layers involved in the Transformer model's data processing journey." link="" mdxType="ImgMediaCard" />
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/TRANSFOMER_KEYNOTE_1.m4v" title="Training loop for Transformer Q and A Models" description="This video presents a high-level overview, capturing the fundamental elements of the training loop in a Transformer AQ model. An ideal starting point for those keen on grasping the basics of this innovative machine learning architecture." link="" mdxType="VideoMediaCard" />
         <Header mdxType="Header">little more detail.architecture of the code structure. </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/TRANSFOMER_KEYNOTE_2.m4v" title="Exploring Transformers: The Building Blocks of Modern NLP" description="This follow-up video offers a comprehensive deep dive into the working mechanisms of a Transformer AQ model." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'First steps. Transformer Block',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
           <Header mdxType="Header">First Steps with Transformers: Understanding the Code </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/transformer_code.m4v" title="Walking Through the Transformer: Code Explained" description="Take a deep dive into the code behind the Transformer Model. Discover the key components and understand how they interact in this advanced machine learning algorithm." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Encoder Layer',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
         <Header mdxType="Header">Understanding the Encoder Layer operation  </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/encoder_layer_code.m4v" title="A Walkthrough of the Encoder Layer Code" description="Transformer's Encoder Layer and understand its crucial role in processing and understanding the input sequence." link="" mdxType="VideoMediaCard" />
  </div>
}, {
  title: 'Multihead Mechanism',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '800px',
    maxHeight: '95vh'
  }}>
              <Header mdxType="Header">ultiHead Attention Mechanism Code  </Header>
        <br />
        <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/TRANSFORMERS/multihead_video.m4v" title="A Walkthrough of MultiHead Attention Mechanism" description="Workings of the multihead attention layer in the Transformer model's architecture" link="" mdxType="VideoMediaCard" />
  </div>
}];
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const alphabetWordsTransformersPostLesson = [{
  letter: 'H',
  words: ['Hyperparameters in Transformers'],
  quiz: [{
    question: 'Which hyperparameter in Transformers controls the depth of the network?',
    options: ['Learning rate', 'Batch size', 'Number of layers', 'Embedding size'],
    answer: 'Number of layers'
  }, {
    question: 'Which hyperparameter would you adjust to manage the training speed vs. model performance trade-off in Transformers?',
    options: ['Learning rate', 'Batch size', 'Number of attention heads', 'Embedding size'],
    answer: 'Batch size'
  }]
}, {
  letter: 'K',
  words: ['Keys in Attention Mechanism'],
  quiz: [{
    question: 'In the attention mechanism, what is the role of keys?',
    options: ['Used to calculate attention scores', 'Used to store information about the input sequence', 'Used for position encoding', 'Used for calculating gradients'],
    answer: 'Used to calculate attention scores'
  }, {
    question: 'How are keys in the attention mechanism computed?',
    options: ['By applying a learnable linear transformation to the input embeddings', 'By calculating dot product with queries', 'By performing softmax operation', 'None of the above'],
    answer: 'By applying a learnable linear transformation to the input embeddings'
  }]
}, {
  letter: 'N',
  words: ['Normalization in Transformers'],
  quiz: [{
    question: 'What is the purpose of Layer Normalization in Transformers?',
    options: ['To prevent the model from overfitting', 'To speed up training', 'To ensure the inputs to each layer have zero mean and unit variance', 'All of the above'],
    answer: 'To ensure the inputs to each layer have zero mean and unit variance'
  }, {
    question: 'In which part of the Transformer architecture is Layer Normalization usually applied?',
    options: ['After each sub-layer (self-attention, feed-forward)', 'Before the multi-head attention layer', 'After the positional encoding', 'Before the final output layer'],
    answer: 'After each sub-layer (self-attention, feed-forward)'
  }]
}, {
  letter: 'S',
  words: ['Self-Attention in Transformers'],
  quiz: [{
    question: 'What is the role of Self-Attention in Transformers?',
    options: ['Helps the model to focus on different parts of the input', 'Helps in tokenizing the input', 'Helps in encoding positional information', 'Helps in calculating gradients'],
    answer: 'Helps the model to focus on different parts of the input'
  }, {
    question: 'In the context of Transformers, what does the term "self" in Self-Attention refer to?',
    options: ['The model attends to itself', 'The model attends to all parts of the input', 'The model only attends to the current word', 'None of the above'],
    answer: 'The model attends to all parts of the input'
  }]
}, {
  letter: 'W',
  words: ['Weights in Transformers'],
  quiz: [{
    question: 'What role do weights play in a Transformer model?',
    options: ['They determine the amount of attention to be paid to each word', 'They store the learned information', 'They control the learning rate', 'None of the above'],
    answer: 'They store the learned information'
  }, {
    question: 'How are the weights in a Transformer model updated?',
    options: ['By backpropagation and gradient descent', 'By calculating attention scores', 'By calculating dot products', 'None of the above'],
    answer: 'By backpropagation and gradient descent'
  }]
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const GenAIAppHeader = makeShortcode("GenAIAppHeader");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Header = makeShortcode("Header");
const MyCarousel = makeShortcode("MyCarousel");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const ImgMediaCard = makeShortcode("ImgMediaCard");
const layoutProps = {
  EncoderVideo1,
DecoderVi,
goals,
alphabetWordsTransformers,
topics,
ggbParameters2,
opts,
alphabetWordsTransformersPostLesson
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <GenAIAppHeader mdxType="GenAIAppHeader" />
    <br />
    <br />
    <h1>{`Transformer Architecture`}</h1>


 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/TRANSFORMERS/TRANSFORMERS_GEOGEBRA_2.JSON" ggbParameters={ggbParameters2} appId="id-t_play" email="pavanmirla@gmail.com" data={[{
        component: <EncoderVideo1 mdxType="EncoderVideo1" />,
        button: "ebutton"
      }, {
        component: <DecoderVi mdxType="DecoderVi" />,
        button: "dbutton"
      }]} mdxType="GeogebraDraw" /> 
  </div>
    <h1>{`Transformers for Question and Answering task`}</h1>


    <Header mdxType="Header"> Pre-Lesson Knowledge Check</Header>
    <AlphabetCards alphabetWords={alphabetWordsTransformers} mdxType="AlphabetCards" />
    <Header mdxType="Header">ABOUT TRANSFORMERS</Header>
    <br />
    <MyCarousel topics={topics} mdxType="MyCarousel" />
    <br />
    <br />
    <br />
    <br />

    <h1>{`interactive`}</h1>
 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/GENAI/TRANSFORMER/transformer_geogebra_4.json" ggbParameters={ggbParameters2} appId="id_transformers" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
  </div>



    <Header mdxType="Header"> Post-Lesson Knowledge Check</Header>
    <AlphabetCards alphabetWords={alphabetWordsTransformersPostLesson} mdxType="AlphabetCards" />
    <SomeComponent mdxType="SomeComponent" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;