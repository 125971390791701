import React from 'react';
import App from '../utilities/App';

const topics = ["LHopital","ODE basics","Optimization","PDE basics", "Newton Raphson", "Speed"];

function CalculusApp() {
  return <App topics={topics} baseRoute="calculus" />;
}

export default CalculusApp;
