// ContactUs.jsx
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import {
  TextField,
  Button,
  Typography,
  Link,
  Box,
  Container,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Email, LinkedIn } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#f9f9f9', // Slightly lighter background
    padding: theme.spacing(8, 0),
  },
  formContainer: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(4),
    borderRadius: theme.spacing(1),
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.1)',
    maxWidth: '600px',
    margin: '0 auto',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    padding: theme.spacing(1.5),
    fontSize: '1rem',
    textTransform: 'none',
  },
  successMessage: {
    marginTop: theme.spacing(2),
    color: theme.palette.success.main,
    textAlign: 'center',
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  contactDetails: {
    marginTop: theme.spacing(6),
    textAlign: 'center',
  },
  contactLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.primary,
    fontWeight: 500,
    marginTop: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconButton: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  header: {
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(4),
    fontWeight: 500,
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const templateId = 'template_8mqwp3c';
    const serviceID = 'service_a9yntf5';

    let templateParams = {
      from_name: name,
      reply_to: email,
      message,
    };

    emailjs
      .send(serviceID, templateId, templateParams, 'lgC0Srzi9zC-oNFqD')
      .then((response) => {
        console.log('Email successfully sent!', response);
        setSuccessMessage('Message sent successfully!');
        setName('');
        setEmail('');
        setMessage('');
      })
      .catch((err) => {
        console.error('Email sending failed.', err);
        setSuccessMessage('Oops, there was an error. Please try again later.');
      });
  };

  return (
    <div className={classes.container}>
      <Container>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          className={classes.header}
        >
          Get in Touch
        </Typography>
        <Container className={classes.formContainer}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              className={classes.field}
              label="Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <TextField
              className={classes.field}
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <TextField
              className={classes.field}
              label="Message"
              variant="outlined"
              multiline
              rows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <Button
              className={classes.button}
              variant="contained"
              type="submit"
              disableElevation
            >
              Send Message
            </Button>
            {successMessage && (
              <Typography
                className={
                  successMessage.includes('error')
                    ? classes.errorMessage
                    : classes.successMessage
                }
              >
                {successMessage}
              </Typography>
            )}
          </form>
        </Container>
        <div className={classes.contactDetails}>
          <Typography variant="h6" gutterBottom>
            Contact Information
          </Typography>
          <Link
            href="mailto:office@perceptron.solutions"
            className={classes.contactLink}
          >
            <IconButton className={classes.iconButton}>
              <Email />
            </IconButton>
            office@perceptron.solutions
          </Link>
          <Link
            href="https://www.linkedin.com/in/pavanmirla/"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.contactLink}
          >
            <IconButton className={classes.iconButton}>
              <LinkedIn />
            </IconButton>
            LinkedIn Profile
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default ContactUs;
