import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

// Define the styles
const useStyles = makeStyles((theme) => ({
  listRoot: {
    paddingTop: theme.spacing(8),  // Adjust this value as per your needs
  },
}));

function Sidebar({ topics, baseRoute }) {
  
  // Use the styles
  const classes = useStyles();

  return (
    // Apply the style to the List component
    <List component="nav" className={classes.listRoot}>
      {topics.map((topic, index) => (
        <ListItem button key={index} component={Link} to={`/${baseRoute}?topic=${topic.replace(/\s+/g, '-').toLowerCase()}`}>
          <ListItemText primary={topic} />
        </ListItem>
      ))}
    </List>
  );
}

export default Sidebar;
