import React from 'react';
import App from '../utilities/App';

const topics = ["Backprop","iimb private debt"];

function AIApp() {
  return <App topics={topics} baseRoute="ai" />;
}

export default AIApp;
