import React, { useState } from 'react';

export const LocationComponent = ({ addBlock }) => {
    const [location1, setLocation1] = useState('');
    const [location2, setLocation2] = useState('');
    const handleLocationClick = async () => {
      addBlock(`Calculating distance between ${location1} and ${location2}...`, 'system');
      try {
        // Call the server to get the distance
        const response = await fetch('http://localhost:3001/calculateDistance', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ location1, location2 }),
        });
        const data = await response.json();
        if (data.distance) {
          addBlock(`The distance between ${location1} and ${location2} is ${data.distance}.`, 'system');
        } else {
          addBlock('Sorry, could not calculate distance.', 'system');
        }
      } catch (error) {
        console.error('Error fetching distance:', error);
        addBlock('An error occurred while fetching distance.', 'system');
      }
    };
    return (
      <div>
        {/* Placeholder for Google Map */}
        <div>Your Google Map Here</div>
        {/* Inputs to get the locations */}
        <input type="text" placeholder="Location 1" value={location1} onChange={(e) => setLocation1(e.target.value)} />
        <input type="text" placeholder="Location 2" value={location2} onChange={(e) => setLocation2(e.target.value)} />
        <button onClick={handleLocationClick}>Calculate Distance</button>
      </div>
    );
  };