import React, { useEffect, useState, useReducer } from "react";
import { Helmet } from "react-helmet";
import MyLoader from "./MyLoader";
import YouTube from "react-youtube";
import "../markdown-styles/styles.css";
import Demo from './DraggableDialog';
import Container from './layouts/Container';
export const opts = {
  height: "390",
  width: "640",
  origin: 'http://localhost:3000/',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24,
  },
};



export default function GeogebraComponent(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [element, setElement] = useState("");
  const [clickIndex, setClickIndex]= useState("");
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  
  const clickContent=props.clickContent
  const indexArray= props.indexArray

  //{ count, app_id, newParams }
  useEffect(() => {
    
    setTimeout(() => {
      function clickL(nam) {
          setIsOpen(!isOpen)
          forceUpdate()
          setElement(nam)
          props.onClickHandle(nam)
          props.stateChanger(nam)
   
      }

      var parameters = {
        width: "100%",
        height: "100%",
        showToolBar: false,
        borderColor: "black",
        showMenuBar: false,
        showAlgebraInput: false,
        customToolbar: "0 || 1",
        showResetIcon: false,
        enableLabelDrags: false,
        enableRightClick: false,
        capturingThreshold: null,
        showToolBarHelp: false,
        errorDialogsActive: false,
        useBrowserForJS: false,
        enableShiftDragZoom: true,
        customToolBar: 0,
        appletOnLoad :function(api){ 
          // ()=>{props.dispatch({type:'loaded', payload: 'loaded'})}
          api.registerClickListener(clickL);
          setLoading(false)
        }

        // material_id: "P36DgbhH"
      };

      parameters = { ...parameters, ...props.newParameters };
      var views = {
        is3D: 1,
        AV: 1,
        SV: 0,
        CV: 0,
        EV2: 0,
        CP: 0,
        PC: 0,
        DA: 0,
        FI: 0,
        PV: 0,
        macro: 0
      };

      var ggbApplet = new window.GGBApplet(parameters, true);
      ggbApplet.setHTML5Codebase(
        "https://www.geogebra.org/apps/5.0.498.0/web3d"
      );
      ggbApplet.inject(props.id);
    }, 760);
  }, []);

  return (
    <div>
      <Helmet>
        {!loading && <MyLoader />}
      </Helmet>
      {loading && <MyLoader />}
      {/* Removed Container to not limit the width to its parent */}
      <div
        id={props.id}
        style={{
          position: "relative",
          // borderColor: "#fff4e4",
          // backgroundColor: "#fff4e4",
          // borderStyle: "light",
          width: "100vw", // Set the width relative to the viewport width
          height: "auto", // Set the height to auto or a specific value as needed
          marginLeft: "calc(-50vw + 50%)", // Center the div by pulling it back half its own width
          marginRight: 0, // Remove any margin to the right if not necessary
          marginTop: "0px",
          // Ensure that it does not exceed the viewport height if necessary
          maxHeight: "100vh", // Optional: you can remove this if it's not needed
          overflow: "hidden", // Optional: prevents scrollbars if the content overflows
        }}
      >
        {/* GeoGebra content */}
      </div>
    </div>
  );
  
}