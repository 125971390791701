import React from 'react';

const GoogleForm = () => {
  return (
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSfUOgqyobWhQGcDFckzzydLmfzIqhm8Jj9LjdzZrSuKm21PXw/viewform"
      width="100%"
      height="800" // You can adjust the height as needed
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="My embedded Google Form"
    >
      Loading…
    </iframe>
  );
};

export default GoogleForm;
