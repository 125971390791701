import React, { useState } from 'react';
import { Tabs, Tab, Box, Typography, Paper, Button } from '@mui/material';
import VideoPlayer from '../VideoPlayerSimple'; // Ensure the correct relative path
import CardComponent from './CardComponent';
import './CourseWorkApp.css';

const TabsComponent = ({ data }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabStyles = [
    { backgroundColor: '#FFB3BA' }, // Pastel Red
    { backgroundColor: '#FFDFBA' }, // Pastel Orange
    { backgroundColor: '#FFFFBA' }, // Pastel Yellow
  ];

  if (!data || !Array.isArray(data)) {
    console.error('Invalid data passed to TabsComponent:', data);
    return <div>Error: Invalid data format</div>;
  }

  return (
    <Box>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="AI Agents" style={tabStyles[0]} />
        <Tab label="Deep Learning" style={tabStyles[1]} />
        <Tab label="AI for Executives" style={tabStyles[2]} />
      </Tabs>
      {data.map((section, index) => (
        <div
          role="tabpanel"
          hidden={value !== index}
          key={index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
        >
          {value === index && (
            <Box p={3}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '60%' }}>
                  {/* Display either video or image, or nothing if neither exists */}
                  {section.video ? (
                    <VideoPlayer
                      url={section.video}
                      width="100%"
                      height="400px"
                    />
                  ) : section.image ? (
                    <img
                      src={section.image}
                      alt={section.title || 'Course image'}
                      style={{ width: '100%', height: 'auto', display: 'block' }}
                    />
                  ) : (
                    <Typography variant="body1" color="textSecondary">
                      No media available
                    </Typography>
                  )}
                </div>
                <div className="details-text">
                  <Typography variant="h6" component="p">
                    {section.title}
                  </Typography>
                  <Typography variant="body1" component="p" style={{ marginBottom: '20px' }}>
                    {section.caption}
                  </Typography>
                  <Typography variant="body2" component="p">
                    {section.description}
                  </Typography>
                  <Box mt={2}>
                    {section.tags &&
                      section.tags.map((tag, idx) => (
                        <Paper key={idx} elevation={3} style={{ padding: '10px', marginTop: '10px' }}>
                          <Typography variant="body1" component="p">
                            {tag}
                          </Typography>
                        </Paper>
                      ))}
                  </Box>
                </div>
              </div>
              <div className="cards-container">
                {section.topics && Array.isArray(section.topics) ? (
                  section.topics.map((topic, index) => (
                    <CardComponent key={index} topic={topic} />
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No topics available
                  </Typography>
                )}
              </div>
            </Box>
          )}
        </div>
      ))}
    </Box>
  );
};

export default TabsComponent;
