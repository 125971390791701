import React from 'react';

const SearchBar = ({ onSearch }) => (
  <div style={styles.container}>
    <input
      type="search"
      placeholder="Search articles..."
      onChange={(e) => onSearch(e.target.value)}
      style={styles.input} // Add basic styling
    />
  </div>
);

const styles = {
  container: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  input: {
    padding: '10px',
    width: '80%',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
};

export default SearchBar;
