import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
  IconButton,
  Paper,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const GenericAccordion = ({
  title,
  children,
  isCollapsed = false, // Control if accordion is expanded by default
  mediaContent, // New prop for media content
  headerColor, // Add this prop
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(!isCollapsed);
  const [showMedia, setShowMedia] = useState(false);

  const handleAccordionChange = () => {
    setExpanded((prev) => !prev);
    if (mediaContent) {
      setShowMedia((prev) => !prev);
    }
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={handleAccordionChange}
        sx={{
          borderRadius: '8px',
          marginBottom: '16px',
          '&:before': {
            display: 'none',
          },
          boxShadow: 'none',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${title}-content`}
          id={`${title}-header`}
          sx={{
            backgroundColor: headerColor || theme.palette.action.hover, // Use headerColor if provided
            color: '#333333',
            borderRadius: '8px',
            minHeight: '64px',
            justifyContent: 'center', // Center the content
            '& .MuiAccordionSummary-content': {
              alignItems: 'center',
              justifyContent: 'center', // Center the title
              flexGrow: 1, // Allow the content to grow and take available space
            },
          }}
        >
          <Typography variant="h6" sx={{ textAlign: 'center', width: '100%' }}>
            {title}
          </Typography>
          {mediaContent && (
            <IconButton
              aria-label="Toggle Media"
              onClick={(e) => {
                e.stopPropagation();
                setShowMedia((prev) => !prev);
              }}
            >
              <PlayCircleOutlineIcon />
            </IconButton>
          )}
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: '24px',
            backgroundColor: theme.palette.background.paper,
            maxHeight: '600px',
            overflowY: 'auto',
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>

      {mediaContent && showMedia && (
        <Paper
          elevation={4}
          sx={{
            position: 'fixed',
            top: '20px',
            right: '20px',
            width: '300px',
            maxWidth: '80%',
            zIndex: 1000,
            padding: '16px',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="subtitle1">{title}</Typography>
            <IconButton size="small" onClick={() => setShowMedia(false)}>
              ✕
            </IconButton>
          </div>
          <div style={{ marginTop: '8px' }}>{mediaContent}</div>
        </Paper>
      )}
    </>
  );
};

export default GenericAccordion;