import React from 'react';

const FilterButton = ({ label, isSelected, onToggle }) => (
  <button
    className={`filter-button ${isSelected ? 'selected' : ''}`}
    onClick={() => onToggle(label)}
    style={{
      padding: '8px 16px',
      margin: '4px',
      border: '1px solid #007BFF',
      borderRadius: '20px',
      backgroundColor: isSelected ? '#007BFF' : 'white',
      color: isSelected ? 'white' : '#007BFF',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
    }}
  >
    {label}
  </button>
);

export default FilterButton;