import React, { useState, useEffect } from 'react';
import firebase from '../firebase_new/firebase';
import { Button, TextField, Container, Typography } from '@material-ui/core';

function SignInComponent() {
    const [email, setEmail] = useState('');

    useEffect(() => {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            const email = window.localStorage.getItem('emailForSignIn');
            if (email) {
                firebase.auth().signInWithEmailLink(email, window.location.href)
                    .then((result) => {
                        window.localStorage.removeItem('emailForSignIn');
                    })
                    .catch((error) => {
                        console.error("Error signing in with magic link:", error);
                        alert(error)
                    });
            }
        }
    }, []);

    const handleSignIn = async () => {
        const actionCodeSettings = {
            url: window.location.origin,
            handleCodeInApp: true,
        };
        
        try {
            await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
            alert(email)
            window.localStorage.setItem('emailForSignIn', email);
        } catch (error) {
            console.error("Error sending magic link:", error);
            alert(error)
        }
    };

    return (
        <Container>
            <Typography variant="h5">Sign In</Typography>
            <TextField 
                label="Email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleSignIn}>
                Send Magic Link
            </Button>
        </Container>
    );
}

export default SignInComponent;
