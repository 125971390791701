
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';



const useStyles = makeStyles((theme) => ({
  subtitleSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    maxWidth: '70%',  // Set maximum width to 90%
    margin: 'auto',   // Center the section
    '@media (min-width: 1280px)': { // Apply these styles for screens that are 1280px or wider
      maxWidth: '55%',  // Set maximum width to 70% for wider screens
    },
  },
  subtitleText: {
    fontWeight: 'light',
    color: '#333',
    fontSize: '0.875rem', // Add a custom font size
    textAlign: 'center', // Center the text
    wordBreak: 'break-word', // Break words at the end of the line
  },
}));

const SubtitleSection = ({ subtitle }) => {
  const classes = useStyles();

  return (
    <Box className={classes.subtitleSection}>
      <Typography variant="h6" className={classes.subtitleText}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default SubtitleSection;
