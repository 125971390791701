import React from 'react';
import { Drawer, List, ListItem } from '@mui/material';
import { Link } from 'react-router-dom';

const SideMenu = ({ selectedChapter }) => {
  if (!selectedChapter) return null;

  return (
    <Drawer variant="permanent" anchor="left" open={true}>
      <List>
        {selectedChapter.sections.map((section, sectionIndex) => (
          <ListItem button key={sectionIndex}>
            <Link to={`/chapter/${selectedChapter.title}/section/${sectionIndex}`}>
              {section}
            </Link>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default SideMenu;
