import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: theme.spacing(2),
    },
    header: {
        fontFamily: 'Roboto, sans-serif',
        color: 'black',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '1.25rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.2rem',
        },
    },
    caption: {
        fontFamily: 'Roboto, sans-serif',
        color: 'gray',
        textAlign: 'center',
        fontSize: '0.9rem',
        marginTop: theme.spacing(1),
    },
}));

const Header = ({ children, variant = "h5", caption }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <Typography variant={variant} className={classes.header}>
                {children}
            </Typography>
            {caption && (
                <Typography variant="body2" className={classes.caption}>
                    {caption}
                </Typography>
            )}
        </Box>
    );
};

export default Header;