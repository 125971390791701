import React, { useState } from 'react';
import { Button, Typography, Box, Container, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    container: {
        textAlign: 'center',
        color: '#e0e0e0',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        maxWidth: '80%',
        height: 'auto',
        marginBottom: 16,
    },
    imageWrapper: {
        position: 'relative',
        overflow: 'hidden',
        marginBottom: 16,
    },
    caption: {
        textAlign: 'center',
        marginTop: '10px',
        fontWeight: '300',
        color: '#b0b0b0',
        fontSize: '18px',
        width: '60%',
        margin: '0 auto',
        overflowWrap: 'break-word',
    },
    header: {
        fontWeight: 300,
        maxWidth: '70%',
        whiteSpace: 'normal',
        margin: '0 auto',
    },

    buttonGroup: {
      marginBottom: 16,
      '& button': {
          margin: '8px', // equivalent spacing to theme.spacing(1)
      },
  },
  beforeButton: {
      backgroundColor: '#2196F3', // Blue
      color: '#FFF',
      '&:hover': {
          backgroundColor: '#1976D2', // Darker blue on hover
      },
  },
  afterButton: {
      backgroundColor: '#FFC107', // Amber
      color: '#000',
      '&:hover': {
          backgroundColor: '#FFB300', // Darker amber on hover
      },
  },

});

export default function ImageSwitcher(props) {
    const [imageState, setImageState] = useState('before'); // or 'after'
    const classes = useStyles();

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Typography variant="h4" component="h1" className={classes.header}>
                {props.title}
            </Typography>
            <br/>
            <Box style={{ marginBottom: 16 }}>
                <Button
                    variant={imageState === 'before' ? 'contained' : 'outlined'}
                    color="secondary"
                    onClick={() => setImageState('before')}
                    style={{ marginRight: 8 }}
                >
                    Before
                </Button>
                <Button
                    variant={imageState === 'after' ? 'contained' : 'outlined'}
                    color="primary"
                    onClick={() => setImageState('after')}
                >
                    After
                </Button>
            </Box>
            <div className={classes.imageWrapper}>
                {imageState === 'before' ? (
                    <img src={props.beforeImage} alt="Before Image" className={classes.image} />
                ) : (
                    <img src={props.afterImage} alt="After Image" className={classes.image} />
                )}
            </div>
            <Typography variant="body1" className={classes.caption}>
                {imageState === 'before' ? props.beforeCaption : props.afterCaption}
            </Typography>
        </Container>
    );
}
