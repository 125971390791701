import React from 'react';

const SVGArrow = ({ color = 'black', height = '200px', strokeWidth = 2 }) => {
  // Inline styles for the animation
  const arrowStyle = {
    animation: 'svg-arrow-animation 2s ease-in-out infinite',
    transformOrigin: 'center bottom'
  };

  return (
    <>
      <style>
        {`
          @keyframes svg-arrow-animation {
            0%, 100% {
              transform: translateY(0) scale(1);
            }
            50% {
              transform: translateY(-20px) scale(1.1);
            }
          }
        `}
      </style>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 10 50"
        height={height}
        style={arrowStyle}
      >
        <line
          x1="5" y1="0"
          x2="5" y2="40"
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <polygon points="5,50 0,40 10,40" fill={color} />
      </svg>
    </>
  );
};

export default SVGArrow;
