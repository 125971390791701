import React, { useRef } from 'react';
import { Button } from '@material-ui/core';
import { useStyles } from './useStyles'; // Adjust the import path as necessary

const TableOfContents = ({ items }) => {
  const classes = useStyles();

  const handleScrollToTop = () => {
    const topElement = document.getElementById('top');
    if (topElement) {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <div className={classes.buttonContainer}>
        {items.map((item, index) => (
          <div key={index}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => document.getElementById(item.id).scrollIntoView({ behavior: 'smooth' })}
              className={classes.button}
            >
              {item.title}
            </Button>
            <p className={classes.caption}>{item.caption}</p>
          </div>
        ))}
      </div>
      <Button
        variant="contained"
        color="secondary"
        className={classes.scrollButton}
        onClick={handleScrollToTop}
      >
        Back to Top
      </Button>
    </>
  );
};

export default TableOfContents;
