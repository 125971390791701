import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, CardMedia, Grid, useMediaQuery, useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '80%',
    margin: '0 auto',
    marginBottom: 15,
    backgroundColor: theme.palette.background.default,
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Vertical centering
  },
  cover: {
    height: 350,
    width: '100%',
    objectFit: 'cover',
  },
  text: {
    color: theme.palette.text.primary,
  },
  textContainer: {
    position: 'relative',     // Introduced a relative positioning container
  },
  typewriter: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 0,
    animation: `$type 10s steps(40, end) infinite`,  // Total duration is adjusted
    '&::after': {  // Adding a cursor
      content: "'|'",
      animation: `$blink 1s infinite`,
    }
  },
  staticText: {
    // color: theme.palette.text.disabled,
    color: '#666', // Changed from disabled to secondary for a darker color

  },
  "@keyframes type": {
    '0%, 100%': { width: '0%' },
    '25%': { width: '100%' },
    '50%, 75%': { width: '0%' },
  },
  "@keyframes blink": {
    '0%, 25%, 50%, 75%, 100%': { opacity: 0 },
    '10%, 35%, 60%, 85%': { opacity: 1 }
  }
}));

const CustomCard = ({ heading, subText, imageUrl }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  
  const [isMounted, setIsMounted] = useState(false);
  
  useEffect(() => {
    setIsMounted(true);
  }, []);
  
  return (
    <Card className={classes.card} elevation={3}>
      <Grid container spacing={2} direction={isSmallScreen ? 'column-reverse' : 'row'}>
        <Grid item xs={12} sm={6}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5" className={classes.text} sx={{ mt: isSmallScreen ? 2 : 0 }}>
              <div className={classes.textContainer}>
                <span className={classes.staticText}>{heading}</span>
                {isMounted && 
                  <span className={classes.typewriter}>{heading}</span>
                }
              </div>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
              {subText}
            </Typography>
          </CardContent>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardMedia
            component="img"
            className={classes.cover}
            image={imageUrl}
            title="Cover Image"
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default CustomCard;
