import React from 'react';
import {
  Typography,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: '80%',
      margin: '40px auto',
    },
  },
  table: {
    minWidth: 320, // reduced from 400 for smaller screens
  },
  heading: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  subHeading: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#E0E0E0',
    backgroundColor: '#333333',
    '&:hover': {
      backgroundColor: '#292929'
    },
    padding: theme.spacing(1),
    borderRadius: '8px',
    boxShadow: '0px 3px 10px #111111',
    border: '1px solid #444444',
    letterSpacing: '0.1em',
    lineHeight: '1.5em'
  },
  intro: {
    marginBottom: theme.spacing(3),
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const categories = [
  {
    title: 'Educational Approach',
    traditional: 'Fixed curriculum',
    interactive: 'Adaptive learning paths',
  },
  {
    title: 'Student Engagement',
    traditional: 'Passive listening and note-taking',
    interactive: 'Hands-on experience with interactive simulations, live coding sessions, and collaborative projects',
  },
  {
    title: 'Assessment Techniques',
    traditional: 'Scheduled Examinations with Post-assessment Feedback',
    interactive: 'Real-time, adaptive quizzes and instant peer reviews for immediate feedback and improvement'
  }
  
  // ... more categories
];

export default function IntroAndComparison() {
  const classes = useStyles();
  
  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={12}>
        <Typography variant="h2" align="center" className={classes.heading}>
          Welcome to the Interactive Book on Generative AI!
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" className={classes.intro}>
          Discover the world of Generative AI through an interactive and engaging experience.
        </Typography>
      </Grid>
      <Grid item xs={12} md={10} lg={8}>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.subHeading}>Aspects</TableCell>
                <TableCell align="center" className={classes.subHeading}>Traditional</TableCell>
                <TableCell align="center" className={classes.subHeading}>Interactive</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories.map((category, index) => (
                <TableRow key={index} className={classes.row}>
                  <TableCell align="center">{category.title}</TableCell>
                  <TableCell align="center">{category.traditional}</TableCell>
                  <TableCell align="center">{category.interactive}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
