// import React from 'react';
import styled from 'styled-components';

const Header2 = styled.h2`
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
    font-family: 'Roboto', sans-serif;
    color: #A9A9A9; // This is a hex code for a dark gray color
    // text-transform: uppercase;
    text-align: left;
    // margin-left: 60px
`

export default Header2;
