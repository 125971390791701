import React, { useState } from 'react';
import firebase from '../firebase_new/firebase';
import { Button, TextField, Container, Typography } from '@material-ui/core';

function SignUpComponent() {
    const [email, setEmail] = useState('');

    const handleSignUp = async () => {
        const actionCodeSettings = {
            url: window.location.origin,
            handleCodeInApp: true,
        };
        
        try {
            await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
        } catch (error) {
            console.error("Error sending magic link:", error);
        }
    };

    return (
        <Container>
            <Typography variant="h5">Sign Up</Typography>
            <TextField 
                label="Email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={handleSignUp}>
                Send Magic Link
            </Button>
        </Container>
    );
}

export default SignUpComponent;
