import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, Box, Button } from '@mui/material';
import styled from '@emotion/styled';

const StyledContainer = styled(Container)`

  border-radius: 8px;
  padding: 2rem;
  color: #333; /* Dark text for contrast */
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
`;

const TitleSpan = styled('span')`
  color: #333; /* Consistent dark text color for high contrast */
`;

const ResponsiveTypography = styled(Typography)`
  @media (max-width: 600px) {
    font-size: 2.0rem; // Adjust as needed
  }
`;

const SleekTypography = styled(Typography)`
  font-weight: 300; // This will make the font thinner and sleeker
`;


// The ShowMoreContent component should no longer have a button; it just renders children
const ShowMoreContent = ({ children, isOpen }) => {
  if (!isOpen) return null; // If the section should be hidden, return nothing
  return <div>{children}</div>;
};

ShowMoreContent.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const AIBanner = ({ titleLine1, titleLine2, description, children }) => {
  const [showMore, setShowMore] = useState(true); // State to manage content visibility

  const toggleShowMore = () => {
    setShowMore(!showMore); // Toggle the showMore state
  };

  return (
    <StyledContainer>
      <Box display="flex" flexDirection="column" alignItems="center" py={5}>
        <ResponsiveTypography variant="h1" gutterBottom align="center">
          <TitleSpan>{titleLine1}</TitleSpan><br />
          {titleLine2}
        </ResponsiveTypography>
        <SleekTypography variant="h6" align="center" color="inherit">
          {description}
        </SleekTypography>
        <br/>

        {/* Single button to toggle visibility */}
        {/* <Button onClick={toggleShowMore}
          style={{ color: 'blue' }} // Inline styling to add gray color
          >
          {showMore ? 'Show Less' : 'Show More'}
        </Button> */}
        {/* Render extra content based on `showMore` state */}
        <ShowMoreContent isOpen={showMore}>
          {children}
        </ShowMoreContent>
      </Box>
    </StyledContainer>
  );
};

AIBanner.propTypes = {
  titleLine1: PropTypes.string.isRequired,
  titleLine2: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node, // `children` is optional
};

export default AIBanner;