import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
    gap: '10px'
  },
  button: {
    fontSize: '16px',
    letterSpacing: '0.5px',
    textTransform: 'none',
    textAlign: 'center',
    width: '300px',
    height: '75px',
    fontWeight: '500',
    fontFamily: '"Roboto", sans-serif'
  },
  caption: {
    fontSize: '14px', // Smaller font size for caption
    color: '#333', // Greyish color, can be adjusted
    textAlign: 'center',
    maxWidth: '300px', // Aligns with the button width
    marginTop: '8px', // Space between button and caption
    fontFamily: '"Roboto", sans-serif'
  },
  scrollButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    fontSize: '16px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: '#fff',
    borderRadius: '4px',
    cursor: 'pointer',
    fontFamily: '"Roboto", sans-serif',
    zIndex: 1000
  }
});
