import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TabsComponent from './TabsComponent';
import './CourseWorkApp.css';

const App = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/data.json');
        console.log('Fetched data:', response.data);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
      }
    };
    fetchData();
  }, []);

  if (!data) return <div>Loading...</div>;

  return (
    <div className="app">
      <TabsComponent data={data} />
    </div>
  );
};

export default App;
