
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import AskBot from "./components/VanmatesScreen";
import { PersonaComponent } from './components/PersonaComponent';
import { LocationComponent } from './components/LocationComponent';
import {StudentInfoComponent} from './components/StudentInfoComponent';
import {QdrantQueryComponent} from './components/QdrantQueryComponent';
import {QuerySpineHealth} from './components/QuerySpineHealth.js';
import { useState } from 'react';
export const Intro_Videos = props => <div style={{
  padding: 0,
  backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const VideoComponent = () => <div style={{
  position: 'relative',
  paddingBottom: '56.25%',
  height: '0'
}}>
    <iframe title="YouTube Video" style={{
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%'
  }} src="https://www.youtube.com/embed/esodqUt1DlM" frameBorder="0">
    </iframe>
  </div>;
export const MapComponent = <div>Your Map Component Here</div>;
export const menuItems = [{
  label: 'Select Persona',
  action: addBlock => addBlock('Select a persona:', 'system', null, <PersonaComponent mdxType="PersonaComponent" />)
}, {
  label: 'Select Location',
  action: addBlock => addBlock('Select a location:', 'system', null, <LocationComponent mdxType="LocationComponent" />)
}, {
  label: 'Select Video',
  action: addBlock => addBlock('Select a Video:', 'system', null, <VideoComponent mdxType="VideoComponent" />)
}, {
  label: 'Provide Info',
  action: addBlock => addBlock('Tell us about you:', 'system', null, <StudentInfoComponent mdxType="StudentInfoComponent" />)
}, {
  label: 'Query Startups DB',
  action: addBlock => addBlock('Fetch Startup data:', 'system', null, <QdrantQueryComponent mdxType="QdrantQueryComponent" />)
}, {
  label: 'Query Spine Health',
  action: addBlock => addBlock('Ask Spine health website:', 'system', null, <QuerySpineHealth mdxType="QuerySpineHealth" />)
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ReactPlayer = makeShortcode("ReactPlayer");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
VideoComponent,
MapComponent
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <AskBot menuItems={menuItems} mdxType="AskBot" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;