import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    position: 'sticky',
    top: 0,
    display: 'flex',
    overflowX: 'auto',
    padding: theme.spacing(2),
    // backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
  },
  card: {
    minWidth: '20%',
    maxWidth: '20%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
  },
}));

function TopicsBar({ topics, baseRoute }) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(true);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <>
      <IconButton onClick={toggleVisibility} className={classes.closeButton}>
        {isVisible ? <CloseIcon /> : <CloseIcon />}
      </IconButton>
      {isVisible && (
        <div className={classes.cardContainer}>
          {topics.map((topic, index) => (
            <Card className={classes.card} key={index}>
              <CardContent>
                <Typography variant="h6" component={Link} to={`/${baseRoute}?topic=${topic.replace(/\s+/g, '-').toLowerCase()}`}>
                  {topic}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </div>
      )}
    </>
  );
}

export default TopicsBar;
