import React from 'react';
import { Typography, Box, Container } from '@mui/material';
import { styled } from '@mui/system';

const MissionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: theme.spacing(8, 0),
  textAlign: 'center',
}));

const MissionText = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 400,
  fontStyle: 'italic',
  color: '#333',
  maxWidth: '800px',
  margin: '0 auto',
  lineHeight: 1.6,
}));

const MissionStatement = () => {
  return (
    <MissionContainer>
      <Container>
        <MissionText variant="h5">
          "Through integrative thinking and design, we combine technical expertise and business understanding to create AI solutions for investment research that deliver long-lasting value."
        </MissionText>
      </Container>
    </MissionContainer>
  );
};

export default MissionStatement;
