
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const Box1 = () => <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <VideoPlayer width="80%" // Set width to be 80% of the parent div
  height="80%" // Set height to be 80% of the parent div
  url="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide3.m4v" mdxType="VideoPlayer" />
</div>;
export const Box2 = () => <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <VideoPlayer width="80%" // Set width to be 80% of the parent div
  height="80%" // Set height to be 80% of the parent div
  url="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide4.m4v" mdxType="VideoPlayer" />
</div>;
export const Box3 = () => <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <VideoPlayer width="80%" // Set width to be 80% of the parent div
  height="80%" // Set height to be 80% of the parent div
  url="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide14.m4v" mdxType="VideoPlayer" />
</div>;
export const Box4 = () => <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <VideoPlayer width="80%" // Set width to be 80% of the parent div
  height="80%" // Set height to be 80% of the parent div
  url="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide6.m4v" mdxType="VideoPlayer" />
</div>;
export const Box5 = () => <div style={{
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}}>
  <VideoPlayer width="80%" // Set width to be 80% of the parent div
  height="80%" // Set height to be 80% of the parent div
  url="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN/slide7.m4v" mdxType="VideoPlayer" />
</div>;
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1950,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const SubtitleSection = makeShortcode("SubtitleSection");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const VideoPlayer = makeShortcode("VideoPlayer");
const layoutProps = {
  Box1,
Box2,
Box3,
Box4,
Box5,
ggbParameters2,
opts
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <>
      <SubtitleSection subtitle="Navigate through each section in sequence and follow the instructions in the video" mdxType="SubtitleSection" />
    </>
 <div>
      <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/GENAI/TRANSFORMER/geogebra-GAN_2.json" ggbParameters={ggbParameters2} appId="id_GAAN_quiz" email="pavanmirla@gmail.com" data={[{
        component: <Box1 mdxType="Box1" />,
        button: "button1"
      }, {
        component: <Box2 mdxType="Box2" />,
        button: "button2"
      }, {
        component: <Box3 mdxType="Box3" />,
        button: "button3"
      }, {
        component: <Box4 mdxType="Box4" />,
        button: "button4"
      }, {
        component: <Box5 mdxType="Box5" />,
        button: "button5"
      }]} mdxType="GeogebraDraw" /> 
  </div>







    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;