import React from 'react';

const ExpandableList = ({ title, data }) => {
  const [expanded, setExpanded] = React.useState(false);

  const containerStyle = {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    maxWidth: '600px',
    margin: '20px auto',
    backgroundColor: '#f8f8f8',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: '#007BFF',
    color: '#fff',
    cursor: 'pointer',
    marginBottom: '16px',
    fontWeight: 'bold'
  };

  const listStyle = {
    listStyleType: 'none',
    padding: '0'
  };

  const listItemStyle = {
    marginBottom: '12px',
    padding: '15px',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
  };

  const titleStyle = {
    fontSize: '24px',
    marginBottom: '16px',
    color: '#333'
  };

  const itemTitleStyle = {
    fontSize: '18px',
    margin: '0 0 8px 0',
    color: '#007BFF',
    fontWeight: 'bold'
  };

  const itemDescriptionStyle = {
    fontSize: '16px',
    margin: '0',
    color: '#555'
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{title}</h2>
      <button style={buttonStyle} onClick={() => setExpanded(!expanded)}>
        {expanded ? 'Collapse' : 'Expand'}
      </button>
      {expanded && (
        <ul style={listStyle}>
          {data.map((item, index) => (
            <li key={index} style={listItemStyle}>
              <h4 style={itemTitleStyle}>{item.title}</h4>
              <p style={itemDescriptionStyle}>{item.description}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ExpandableList;