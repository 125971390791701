import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import styled from "@emotion/styled";

// Define color variables for a lighter theme
const fontColor = "#333";
const frontCardBg = "#E1BEE7"; // Light purple
const backCardBg = "#FFE5D4"; // Light peach
const hoverCardBg = "#F3D9FF"; // Lighter purple for hover
const gridBg = "#F5F5F5"; // Light grey background

// Styled components
const FlipCardGridContainer = styled(Grid)`
  background-color: ${gridBg};
  border-radius: 8px;
  padding: 2rem;
  color: ${fontColor};
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: auto;
`;

const StyledGridItem = styled(Grid)`
  height: 100%;
`;

const FlipCard = styled.div`
  height: 250px;
  cursor: pointer;
  perspective: 1000px; /* Create a 3D space */
`;

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d; /* Allow 3D transforms */
`;

const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden; /* Hide the back face when not visible */
  -webkit-backface-visibility: hidden; /* For Safari */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.bgcolor};
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CardFront = styled(CardFace)`
  transform: rotateY(0deg);
`;

const CardBack = styled(CardFace)`
  transform: rotateY(180deg);
`;

const CardTitle = styled(Typography)`
  color: ${fontColor};
  font-weight: 500;
  font-size: 1.3rem;
`;

const CardContentTypography = styled(Typography)`
  color: ${fontColor};
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
`;

const FlipCardGrid = ({ cardsContent }) => {
  const [flipIndex, setFlipIndex] = useState(null);

  const handleCardClick = (index) => {
    setFlipIndex(flipIndex === index ? null : index);
  };

  return (
    <FlipCardGridContainer container spacing={3}>
      {cardsContent.map((card, index) => (
        <StyledGridItem item xs={12} sm={6} md={4} key={index}>
          <FlipCard onClick={() => handleCardClick(index)}>
            <FlipCardInner
              style={{
                transform:
                  flipIndex === index ? "rotateY(180deg)" : "rotateY(0deg)",
              }}
            >
              <CardFront bgcolor={frontCardBg}>
                <CardTitle variant="h5" component="h2">
                  {card.title}
                </CardTitle>
              </CardFront>

              <CardBack bgcolor={backCardBg}>
                <CardContentTypography variant="body2" component="p">
                  {card.content}
                </CardContentTypography>
              </CardBack>
            </FlipCardInner>
          </FlipCard>
        </StyledGridItem>
      ))}
    </FlipCardGridContainer>
  );
};

export default FlipCardGrid;
