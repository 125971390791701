import React from 'react';
import ReactPlayer from 'react-player';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  videoPlayer: {
    position: 'relative',
    paddingTop: '56.25%', // 16:9 Aspect Ratio
    width: '100%',
    height: '0',
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

const VideoPlayer = ({ url }) => {
  const classes = useStyles();

  return (
    <Box className={classes.videoPlayer}>
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height="100%"
        className={classes.reactPlayer}
      />
    </Box>
  );
}

export default VideoPlayer;