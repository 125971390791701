import React, { useState } from "react";
import { withRouter, Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  list: {
    display: "flex",
    flexGrow: 1
  },
  listItem1: {
    marginRight: "0.5em",
    opacity: 0.8,
    textTransform: "uppercase",
    fontWeight: 600
  },
  appbar: {
    backgroundColor: "black",
    color: "lightgray",
    zIndex: theme.zIndex.drawer + 1,
    minHeight: 20
  },
  menuButton: {
    position: "absolute",
    right: 10
  }
}));

function AppHeader(props) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    firebase.auth().signOut()
      .then(() => {
        history.push("/");
      })
      .catch(error => {
        console.error("Error signing out: ", error);
      });
  };

  return (
    <AppBar position="fixed" elevation={1} className={classes.appbar}>
      <Toolbar>
        <img src="/PerceptronLogo3.png" alt="Perceptron Logo" style={{height: '40px', marginRight: '10px'}} />
        <List className={classes.list}>
          <ListItem button key={"PERCEPTRON"} className={classes.listItem1} component={Link} to={'/'}>
            <ListItemText primary={"PERCEPTRON SOLUTIONS "} />
          </ListItem>
          <IconButton className={classes.menuButton} edge="end" aria-label="account of current user" aria-haspopup="true" onClick={handleMenuClick}>
            <MenuIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleLogout}>LOGOUT</MenuItem>
            <MenuItem onClick={handleMenuClose}>CONTACT</MenuItem>
          </Menu>
        </List>
      </Toolbar>
      {props.children}
    </AppBar>
  );
}

export default withRouter(AppHeader);
