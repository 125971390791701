import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActionArea, CardContent, Typography, CardActions, Button } from '@material-ui/core';
import ReactPlayer from 'react-player';

const useStyles = makeStyles({
    root: {
        maxWidth: '50%',
        backgroundColor: '#333',  // Dark theme color
        color: 'white',
        margin: 'auto',
        marginBottom: '1em',  // Reduced from '2em' to '1em'
        '@media (max-width:600px)': {
            maxWidth: '100%',
        },
    },
    scrollContainer: {
        overflowX: 'auto',
        whiteSpace: 'normal',
        '@media (min-width:600px)': {
            overflowX: 'hidden',
        },
    },
    playerWrapper: {
        position: 'relative',
        paddingTop: '56.25%', /* Player ratio: 100 / (1280 / 720) */
        margin: 0,  // Add or adjust this if needed
    },
    reactPlayer: {
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 0,  // Add or adjust this if needed
    },
    moreDetailsButton: {
        backgroundColor: '#757575', // A gray color
        color: '#fff', // White text color for the button
        '&:hover': {
            backgroundColor: '#616161', // Darken the color a bit when hovered
        }
    },
    cardActions: {
        justifyContent: 'center'  // Center the items
    }
});


export default function VideoMediaCard({ videoUrl, title, description, link, thumbnail }) {
    const classes = useStyles();

    // maintain a state for whether the video is playing
    const [playing, setPlaying] = useState(false);

    return (
        <div className={classes.scrollContainer}>
            <Card className={classes.root}>
                <CardActionArea>
                    <div className={classes.playerWrapper}>
                        <ReactPlayer
                            className={classes.reactPlayer}
                            url={videoUrl}
                            width='100%'
                            height='100%'
                            controls={true}
                            playing={playing}   // pass the state to the playing prop
                            light={thumbnail ? thumbnail : false}  // Conditional check for thumbnail
                            onClickPreview={() => setPlaying(true)}  // set playing to true when the preview is clicked
                        />
                    </div>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.cardActions}>
    {link ? (
        <Button size="small" className={classes.moreDetailsButton} href={link} target="_blank">
            Click
        </Button>
    ) : null}
</CardActions>
            </Card>
        </div>
    );
}