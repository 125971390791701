
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const specialization_topics = [{
  "title": "AI Custom Modeling",
  "content": "I provide end-to-end full-stack AI solutions, including data fine-tuning, custom model development, agent configuration, inference hosting, hosting on cloud infrastructure, and knowledge graphs. All models are custom-built to meet specific needs and objectives."
}];
export const profile_topics = [{
  "title": "Perceptron Solutions (2018 - Present)",
  "content": "AI consultant for quantitative investment funds, developing forecasting models and researching alpha generation opportunities with AI.",
  "link": "https://perceptron.solutions"
}, {
  "title": "Manulife Asset Management Innovation Lab (2015 - 2018)",
  "content": "Led the AI research team, pioneering innovative AI applications in finance, utilizing knowledge graphs and unstructured text to give a quantitative score to events, and using deep learning models such as RNN and LSTM for forecasting.",
  "link": "https://www.manulifeim.com/us/en"
}, {
  "title": "John Hancock Asset Management (2012 - 2015)",
  "content": "Senior Quant Analyst, focusing on advanced quantitative models and AI techniques to enhance asset management strategies.",
  "link": "https://www.jhinvestments.com/"
}, {
  "title": "CPP Investment Board (2009 - 2012)",
  "content": "Developed AI-driven solutions for capital market products including derivatives and equities."
}, {
  "title": "Cisco Systems (2005 - 2006)",
  "content": "Worked on routing algorithms (BGP) for routing products, and network optimization at Cisco.",
  "link": "https://www.cppinvestments.com/"
}, {
  "title": "Nokia/Lucent Bell Labs (2003 - 2005)",
  "content": "Worked on queuing algorithms, development and enhancement of priority queuing, and low latency traffic prioritization.",
  "link": "https://www.bell-labs.com/#gref"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const AIBanner = makeShortcode("AIBanner");
const TwoPartRow = makeShortcode("TwoPartRow");
const ImageCard = makeShortcode("ImageCard");
const RedirectButton = makeShortcode("RedirectButton");
const LinkedInEmbed = makeShortcode("LinkedInEmbed");
const layoutProps = {
  specialization_topics,
profile_topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <AIBanner titleLine1="Pavan Mirla" titleLine2="Lead AI Data Scientist" description="Specializing in creating custom AI-driven models for various industries, including finance, asset management, and technology. These models include forecasting tools, recommendation engines, and optimization algorithms, all designed to meet specific client needs." mdxType="AIBanner">
  {/* This is the additional content that will be shown when 'Show More' is clicked */}
  <div>
  <TwoPartRow cardsContent={specialization_topics} mdxType="TwoPartRow" />
  </div>
    </AIBanner>

    <br />
    <br />
    <br />
    <AIBanner titleLine1="Profile" titleLine2="" description="Led succesful AI inititives at leading financial institutions and tech companies." mdxType="AIBanner">
  {/* This is the additional content that will be shown when 'Show More' is clicked */}
  <div>
  <TwoPartRow cardsContent={profile_topics} mdxType="TwoPartRow" />
  </div>
    </AIBanner>
    <br />
    <br />
    <br />
    <br />
    <h1>{`Generative AI: A Course and Book by Pavan Mirla`}</h1>
    <br />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/projector_embedding.gif" mdxType="ImageCard" />
    <RedirectButton url="https://perceptron.solutions/dl?topic=ai" label="Go to Generative AI Book" mdxType="RedirectButton" />
    <br />
    <br />
    <h1>{`Launch of Options Learning Platform`}</h1>
    <LinkedInEmbed src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6974888303363850240" height="1435" title="Embedded post 1" mdxType="LinkedInEmbed" />
    <h1>{`Launch of AI workshops`}</h1>
    <LinkedInEmbed src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7152756473159159808" height="1342" title="Embedded post 2" mdxType="LinkedInEmbed" />
    <h1>{`Public Speaking Highlights`}</h1>
    <LinkedInEmbed src="https://www.linkedin.com/embed/feed/update/urn:li:activity:6383054221348007936" height="600" title="Geoffrey Hinton's Capsule Networks Post" mdxType="LinkedInEmbed" />
    <h1>{`Talk on AI in Finance in Bay Area`}</h1>
    <LinkedInEmbed src="https://www.linkedin.com/embed/feed/update/urn:li:share:6271369444077948928" height="668" width="504" title="Embedded LinkedIn Post" mdxType="LinkedInEmbed" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;