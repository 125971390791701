import React, { useState } from 'react';
import axios from 'axios';

// Adjusted SearchResultCard to handle a text result
const SearchResultCard = ({ result }) => (
  <div style={{ margin: '10px', border: '1px solid #ccc', padding: '10px', maxWidth: '300px' }}>
    <p>{result}</p>
  </div>
);

export const QuerySpineHealth = () => {
  const [queryInput, setQueryInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState('');

  const queryQdrant = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('http://localhost:8080/api/search', {
        query: queryInput,
        collection_name: "my_books"
      }, {
        headers: {
          "Content-Type": "application/json"
        }
      });
      setSearchResult(response.data.result);
      setLoading(false);
    } catch (error) {
      console.error('Error making the query:', error);
      setLoading(false);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Enter search query"
        value={queryInput}
        onChange={(e) => setQueryInput(e.target.value)}
      />
      <button onClick={queryQdrant} disabled={loading || !queryInput.trim()}>
        {loading ? 'Searching...' : 'Search with Qdrant'}
      </button>
      {loading && <p>Loading...</p>}
      {searchResult && <SearchResultCard result={searchResult} />}
    </div>
  );
};
