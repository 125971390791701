import React, { useState } from 'react';
import { makeStyles, Typography, Link, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60%',
    margin: 'auto',
    marginTop: theme.spacing(4),
    textAlign: 'center',
  },
  heading: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  content: {
    marginBottom: theme.spacing(2),
  },
  link: {
    color: '#3f51b5',  // Adjust the hyperlink color as needed
  },
  sectionDivider: {
    borderBottom: '1px solid #ccc',
    marginBottom: theme.spacing(2),
  },
}));

export default function AboutAuthorToggle() {
  const [showDetails, setShowDetails] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.heading} align="center">
        About the Author: Pavan Mirla
      </Typography>
      <Button variant="outlined" onClick={() => setShowDetails(!showDetails)}>
        {showDetails ? 'Hide Details' : 'Show Details'}
      </Button>
      {showDetails && (
        <div style={{ textAlign: 'left' }}> {/* Ensuring the content is left-aligned */}
          <div className={classes.sectionDivider}>
            <Typography className={classes.subtitle}>Work Experience</Typography>
            <Typography className={classes.content}>
            Pavan Mirla is an AI consultant with experience at some of the largest asset management firms in Canada and the USA. He has served as a Quant Analyst at CPP Investment, a $500 billion fund, Lead Data Scientist 
            at Manulife Asset Management, Data Scientist at DataRobot, and AI Consultant for Genus Capital.            </Typography>
          </div>
          
          <div className={classes.sectionDivider}>
            <Typography className={classes.subtitle}>Contact Information</Typography>
            <Typography className={classes.content}>
              <Link href="mailto:office@perceptron.solutions" className={classes.link}>Email: office@perceptron.solutions</Link><br />
              <Link href="https://www.linkedin.com/in/pavanmirla/" className={classes.link}>LinkedIn</Link><br />
              <Link href="https://twitter.com/Pavanmirla" className={classes.link}>Twitter: @Pavanmirla</Link>
            </Typography>
          </div>

          <div className={classes.sectionDivider}>
            <Typography className={classes.subtitle}>Passion and Motivation</Typography>
            <Typography className={classes.content}>
            Pavan's motivation is to help others realize their potential and spark a passion for learning through 
            interactive explainers and algorithms. His aim is to solve real-world problems.
            </Typography>
          </div>

          <Typography className={classes.subtitle}>Books by the Author</Typography>
          <Typography className={classes.content}>
            <Link href="http://options.21ifm.com" className={classes.link}>Options - A Comprehensive Guide</Link>
          </Typography>
        </div>
      )}
    </div>
  );
}
