import React, { useState } from 'react';

export const PersonaComponent = ({ addBlock }) => {
  const [loading, setLoading] = useState(false);

  const handlePersonaClick = async (persona) => {
    setLoading(true);
    addBlock(`You selected ${persona}`, 'system');
    try {
      const response = await fetch('http://localhost:3001/getQuestions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ persona }),
      });
      const data = await response.json();
      if (data.questions) {
        // Concatenate the AI-generated questions and add as a single block
        const concatenatedQuestions = data.questions.join('\n');
        addBlock(concatenatedQuestions, 'system');
      } else {
        addBlock('Sorry, no questions generated.', 'system');
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      addBlock('An error occurred while fetching questions.', 'system');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button onClick={() => handlePersonaClick('Foodie')} disabled={loading}>
        Foodie
      </button>
      <button onClick={() => handlePersonaClick('Traveler')} disabled={loading}>
        Traveler
      </button>
      {loading && <p>Loading questions...</p>}
    </div>
  );
};
