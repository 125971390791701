
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import LearningGoals from "../../components/LearningGoals";
import FlipCardGrid from "../../components/FlipCardGrid";
import TitleSection from "../../components/TitleSection";
import SubtitleSection from "../../components/SubtitleSection";
import FollowLink from "../../components/FollowLink";
import TitleComponent from "../../components/TitleComponent";
import HeadingBold from "../../components/HeadingBold";
import About from "../../components/About";
import ScrollCard from "../../components/ScrollCard";
import TOC from "../../components/TOC";
import LearningComparison from "../../components/LearningComparison";
import DragAndDrop from '../../components/games/DragAndDropGame';
import BuyButton from "../../components/BuyButton";
// import PaymentContext from '../../components/paymentContext';
import { useContext } from 'react';
import SomeComponent from "../../components/SomeComponent";
import Perceptron from "!babel-loader!@mdx-js/loader!./ML/Perceptron.mdx";
import ActivationFunctions from "!babel-loader!@mdx-js/loader!./ML/ActivationFunctions.mdx";
import DActivationFunctions from "!babel-loader!@mdx-js/loader!./ML/DActivationFunctions.mdx";
import Backprop from "!babel-loader!@mdx-js/loader!./ML/BackPropagation.mdx";
import PartialDerivatives from "!babel-loader!@mdx-js/loader!./ML/PartialDerivatives.mdx";
import Gradient from "!babel-loader!@mdx-js/loader!./ML/Gradient.mdx";
import DotProduct from "!babel-loader!@mdx-js/loader!./ML/DotProduct.mdx";
import BackPropagation_Buckets from "!babel-loader!@mdx-js/loader!./ML/BackPropagation_Buckets.mdx";
import Activations from "!babel-loader!@mdx-js/loader!./ML/Activations.mdx";
import LogisticRegression from "!babel-loader!@mdx-js/loader!./ML/LogisticRegression.mdx";
import "../../markdown-styles/styles.css";
export const Intro_Videos = props => <div style={{
  padding: 0,
  // backgroundColor: "#1e1e1e",
  width: "100%",
  marginTop: '50px',
  margin: "auto",
  zIndex: 9999
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url={props.url} mdxType="ReactPlayer" />
    <br />
  </div>;
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  // material_id: "",
  width: 300,
  height: 450,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "510",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: "green"
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: "yellow"
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const cardsContentServices = [{
  title: 'AI for Traders',
  content: 'You are already in the workforce but considering a switch to tech-related fields will find this course a great way to familiarize  with AI without having to commit to a full degree or intensive program.'
}, {
  title: 'Upskill in AI for Computer programmers',
  content: 'Regardless of your major, you are curious about AI and want to expand your AI skill set'
}];
export const buttons = [{
  label: "Agenda",
  time: 0
}, {
  label: "What is optimization?",
  time: .09
}, {
  label: "cardboard experiment",
  time: .36
}, {
  label: "Volume as a function of height",
  time: 1.14
}, {
  label: "First derivative",
  time: 1.41
}, {
  label: "What is critical point?",
  time: 1.59
}, {
  label: "What is slope of tangent line?",
  time: 2.09
}, {
  label: "Solving for height h to optimize volume",
  time: 2.15
}, {
  label: "Summary",
  time: 2.51
}];
export const goals = [{
  id: 'LG01',
  description: "Understand the concept of optimization in the context of maximizing volume."
}, {
  id: 'LG02',
  description: "Identify the optimization function and its role in optimization problems."
}, {
  id: 'LG03',
  description: "Recognize the constraints in an optimization problem and understand their significance."
}, {
  id: 'LG04',
  description: "Grasp the concept of a derivative and how it can be applied in optimization."
}, {
  id: 'LG05',
  description: "Comprehend the idea of a tangent and its relationship with derivatives."
}, {
  id: 'LG06',
  description: "Master the formula for derivatives and its application in solving optimization problems."
}, {
  id: 'LG07',
  description: "Understand what an inflection point is and how to identify it."
}, {
  id: 'LG08',
  description: "Define a critical point and explain its relevance in optimization."
}];
export const topics = [{
  title: "Question 1",
  component: () => <div>
        <QuizSlide mediaType="image" mediaSrc="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/Aboutus_4.png" question="What is this? Who was the first president of the USA? Was it Donald Trump?" options={["Option A there was a crcodile in the pond. It was hungry. A monkey was watching this crocodile: ", "Option B", "Option C"]} onAnswer={selected => selected === 0} correctFeedback="<p>Well done! <a href='link_to_some_resource'>Learn More</a></p>" wrongFeedback="<p>Oops! Try again. <a href='link_to_hint'>Hint</a></p>" mdxType="QuizSlide" />
      </div>
}, {
  title: "Question 2",
  component: () => <div>
        <QuizSlide mediaType="video" mediaSrc="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/youtube_intro_video.mp4" question="What is happening in this video?" options={["Option D", "Option E", "Option F"]} onAnswer={selected => selected === 1} correctFeedback="<p>Well done! <a href='link_to_some_resource'>Learn More</a></p>" wrongFeedback="<p>Oops! Try again. <a href='link_to_hint'>Hint</a></p>" mdxType="QuizSlide" />
      </div>
}, {
  title: "Question 3",
  component: () => <div>
        <QuizSlide mediaType="image" mediaSrc="https://raw.githubusercontent.com/aws-samples/amazon-bedrock-workshop/b886384153a387218fe0fae175586aa8a061f86c/04_Chatbot/images/chatbot_bedrock.png" question="What is Amazon Bedrock?" options={["Option D", "Option E", "Option F"]} onAnswer={selected => selected === 1} correctFeedback="<p>Well done! <a href='link_to_some_resource'>Learn More</a></p>" wrongFeedback="<p>Oops! Try again. <a href='link_to_hint'>Hint</a></p>" mdxType="QuizSlide" />
      </div>
}, {
  title: "Question 3",
  component: () => <div id="activation_functions">
    <Header mdxType="Header">Activation Functions</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/SIG_GIF.gif" title="Activation Functions" description="Understanding Activation Functions, Sigmoid Function,Tanh Function, ReLU Function, Softmax Function,  Choosing Activation Functions" link="activation_functions" mdxType="ImgMediaCard" />
  </div>
}
// Add more quiz slides as needed...
];
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" playing controls config={{
      file: {
        attributes: {
          id: "audio-element"
        }
      },
      playerVars: {}
    }} width="320px" height="180px" mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>
        What is optimization
      </button>
      <br />
      <button onClick={() => ref.current.seekTo(20)}>
        What is critical point?
      </button>
    </div>;
};
export const ClipVideo = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url="https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4" startTime={10} endTime={20} playing controls mdxType="ReactPlayer" />
  </div>;
export const Box11 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="FAwDrUqpGUI" opts={opts} mdxType="YouTube" />
  </div>;
export const Box22 = () => <div style={{
  padding: 20,
  backgroundColor: "tomato"
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const GenAIAppHeader = makeShortcode("GenAIAppHeader");
const Header = makeShortcode("Header");
const ImgMediaCard = makeShortcode("ImgMediaCard");
const VideoMediaCard = makeShortcode("VideoMediaCard");
const ReactPlayer = makeShortcode("ReactPlayer");
const QuizSlide = makeShortcode("QuizSlide");
const layoutProps = {
  Intro_Videos,
meta,
ggbParameters,
ggbParameters2,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
cardsContentServices,
buttons,
goals,
topics,
Player,
ClipVideo,
Box11,
Box22
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
























    <TOC mdxType="TOC" />
    <GenAIAppHeader mdxType="GenAIAppHeader" />
 <br />
    <div id="introduction">
 <LearningComparison mdxType="LearningComparison" />
    </div>
 <br />
  <br />
    <br />
      <br />
        <br />
  <div id="about_me">
 <About mdxType="About" />
  </div>
  <br />
 <br />
  <br />
    <div id="chapter1">
  <HeadingBold mdxType="HeadingBold">Chapter 1: Foundations of Machine Learning</HeadingBold>
  <div id="PartialDerivatives">
    <Header mdxType="Header">Calculus for Deep Learning</Header>
      <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/CALCULUS.gif" title="Calculus for Deep Learning" description="Chain Rule, Gradient, Hessian Matrix and Second Order Derivatives, Integrals,Multivariable Calculus, Optimization and Loss Functions " link="PartialDerivatives" mdxType="ImgMediaCard" />
  </div>
 <br />
   <div id="vector_arithmetic">
    <Header mdxType="Header">Mastering Vector Operations</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/VEC_GIF.gif" title="Vector Arithmetic" description="Vector Norms, Vector Spaces and Subspaces, Orthogonal Projections,Linear Independence and Dependence, Matrix-Vector multiplication, Eigenvalues and Eigenvectors, Understanding angles between vectors" link="vector_arithmetic" mdxType="ImgMediaCard" />
  </div>
  <br />
  <div id="single_layer_perceptron">
    <Header mdxType="Header">Single Layer Perceptron</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/perceptron_card.png" title="Perceptron Binary Classifier" description="Introduction to Vectors,Understanding Hyperplanes,Exploration of Logits,Parameter Updation,The Step Function,Perceptron Learning Algorithm" link="single_layer_perceptron" mdxType="ImgMediaCard" />
  </div>
    </div>
    <div id="chapter2">
  <HeadingBold mdxType="HeadingBold">Chapter 2: Exploring Neural Networks</HeadingBold>
  <div id="Perceptron">
    <Header mdxType="Header">Multilayer Perceptrons</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/MLP_card.png" title="MultiLayer Perceptron" description="Single-Layer Limitations, Introduction to Multi-Layer Perceptron,Advantages of Additional Layers, Visualizing Decision Boundaries, Understanding Sigmoid Activation, Learning in Hidden Layers and Data Transformation Across Layers" link="Perceptron" mdxType="ImgMediaCard" />
  </div>
  <div id="activation_functions">
    <Header mdxType="Header">Activation Functions</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/SIG_GIF.gif" title="Activation Functions" description="Understanding Activation Functions, Sigmoid Function,Tanh Function, ReLU Function, Softmax Function,  Choosing Activation Functions" link="activation_functions" mdxType="ImgMediaCard" />
  </div>
  <div id="d_activation_functions">
    <Header mdxType="Header">Derivatives of Activation Functions</Header>
    
  <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/Sigmoid_Derivative.png" title="Derivatives of Activation Functions" description="Understanding Derivatives of Activation Functions, Derivative of Sigmoid, Derivatives of other Activation Functions,Vanishing & Exploding Gradients, Role in Backpropagation " link="d_activation_functions" mdxType="ImgMediaCard" />
  </div>
    </div>
    <div id="chapter3">
  <HeadingBold mdxType="HeadingBold">Chapter 3: Training Neural Networks</HeadingBold>
  <div id="BackPropagation">
    <Header mdxType="Header">Backpropagation Algorithm</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/backprop_keynote.gif" title="Backpropagation" description="Understanding Backpropagation,Forward Propagation, Loss Calculation, Backward Propagation, Weight Updates, Role of Activation Functions  " link="BackPropagation" mdxType="ImgMediaCard" />
  </div>
  <div id="Gradient">
    <Header mdxType="Header">Interactive Exploration of Gradient Descent</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/GRAD_GIF.gif" title="Gradient Descent Algorithm" description="Understanding Gradient Descent, Role in Machine Learning, Gradient Descent Variants, Learning Rate, Convergence, Advanced Optimizers  " link="Gradient" mdxType="ImgMediaCard" />
  </div>
    </div>
    <div id="chapter4">
  <HeadingBold mdxType="HeadingBold">Chapter 4: Core Concepts in Deep Learning</HeadingBold>
  <div id="LogisticRegression">
    <Header mdxType="Header">AI Core Concepts: Embeddings, Tensors and More</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/LOGI_GIF.gif" title="A Comprehensive interactive Guide to Embeddings, Tensors, Batches, Loss Functions, and Gradient Descent" description="Introduction to Embedings,Neural nets for Logistic Regression,Understanding Cross Entropy Loss, Gradient Propagation, Probability-based Learning, Weight Updates, Visualizing Losses, 3D Visualization of prediction algorithm" link="LogisticRegression" mdxType="ImgMediaCard" />
  </div>
  <div id="embeddings">
    <Header mdxType="Header">Understanding Embeddings</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/projector_embedding.gif" title="The Art and Science of Embeddings with interactive lessons" description="Whether it's understanding human language, recommending products, or uncovering hidden patterns, embeddings act as a bridge, translating discrete information into a form that machines can understand and act upon." link="embeddings" mdxType="ImgMediaCard" />
  </div>
    </div>
    <div id="Transformers">
  <HeadingBold mdxType="HeadingBold">Chapter 5: Transformers</HeadingBold>
  <div id="intro_transformers">
    <Header mdxType="Header">Introduction to Transformers</Header>
    {/* Section content */}
  </div>
  <div id="attention_mechanisms">
    <Header mdxType="Header">Attention Mechanisms</Header>
   <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/transformer.png" title="Transformer Networks" description="Understanding Transformers, GPT Architecture, Attention Mechanism, Language Modeling, Question Answering, Fine-tuning GPT " link="/transformers" mdxType="ImgMediaCard" />
  </div>
    </div>
    <div id="GANs">
  <HeadingBold mdxType="HeadingBold">Chapter 6: Generative Adversarial Networks (GANs)</HeadingBold>
  <div id="intro_gans">
    <Header mdxType="Header">Introduction to GANs</Header>
    {/* Section content */}
  </div>
  <div id="architecture_gans">
    <Header mdxType="Header">Architecture and Components</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/GAN_Intro.png" title="A Comprehensive Guide to GANs: From Theory to Practice" description="Understanding the GAN Architecture, Implementing a GAN, Training a GAN, Optimizing a GAN and Exploring Advanced GANs" link="/gan" mdxType="ImgMediaCard" />
  </div>
    </div>
    <div id="VAEs">
  <HeadingBold mdxType="HeadingBold">Chapter 7: Variational Autoencoders (VAEs)</HeadingBold>
  <div id="intro_vaes">
    <Header mdxType="Header">Introduction to VAEs</Header>
    {/* Section content */}
  </div>
  <div id="architecture_vaes">
    <Header mdxType="Header">VAE Architecture</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/DAVINCIDYNAMICS/ML/autoencoders.gif" title="3D Illustration of an Autoencoder" description="Explore this 3D illustration and get an intuitive grasp of how autoencoders learn to compress and uncompress data, a skill indispensable for dimensionality reduction, noise reduction, and feature extraction in anomaly detection " link="/vae" mdxType="ImgMediaCard" />
  </div>
    </div>
    <div id="Large_Language_Models">
  <HeadingBold mdxType="HeadingBold">Chapter 8: Large Language Models</HeadingBold>
  <div id="intro_language_models">
    <Header mdxType="Header">Introduction to Language Models</Header>
    <ImgMediaCard image="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/llama2.png" title="Finetuning for absolute beginners" description="You will understand LLM jargon: QLoRA, Superised Fine Tuning (SFT), Hosting your own GPT, Quantization, Merging weights etc " link="" mdxType="ImgMediaCard" />
  </div>
  <div id="fine_tuning_openai">
    <HeadingBold mdxType="HeadingBold">Fine-tuning OpenAI Model</HeadingBold>
    {/* Section content */}
  </div>
  <div id="fine_tuning_llama">
    <Header mdxType="Header">Fine-tuning LLAMA Model</Header>
    {/* Section content */}
  </div>
    </div>
    <div id="gen_ai_companies">
  <HeadingBold mdxType="HeadingBold">Chapter 9: Generative AI Companies</HeadingBold>
  <div id="intro_gen_ai_companies">
    <Header mdxType="Header">Introduction</Header>
    {/* Section content */}
  </div>
  <div id="types_of_companies">
    <Header mdxType="Header">Types of Companies Using Generative AI</Header>
    {/* Section content */}
  </div>
  <div id="case_studies">
    <Header mdxType="Header">Case Studies</Header>
    {/* Section content */}
  </div>
  <div id="investment_landscape">
    <Header mdxType="Header">Investment Landscape</Header>
    {/* Section content */}
  </div>
  <div id="challenges_ethics">
    <Header mdxType="Header">Challenges and Ethical Considerations</Header>
    {/* Section content */}
  </div>
  <div id="future_outlook">
    <Header mdxType="Header">Future Outlook</Header>
    {/* Section content */}
  </div>
    </div>
    <br />
    <div style={{
      display: "none"
    }}>
      <VideoMediaCard videoUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/PERCEPTRON/VAE/VIDEOS/INT_ML_3.mp4" title="What's Different?" description="Our course-book isn't a quick skim through the topic; it's an intensive, hands-on exploration of Generative AI. You'll gain a comprehensive understanding of how this technology works, why it's important, and how you can use it to drive real-world results..." link="" mdxType="VideoMediaCard" />
    </div>
    <br />
    <TitleSection title="Launch date: Aug 18, 2023" mdxType="TitleSection" />
    <FollowLink subtitle="Follow Pavan Mirla on: " url="https://www.linkedin.com/in/pavanmirla/" mdxType="FollowLink" />
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;