import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    // You can adjust the font-size to fit your design, for example:
    fontSize: '1.5rem', // This sets the font size to 1.5 times the base font size
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem', // This sets the font size to 2 times the base font size on medium devices and up
    },
    // Add any other styles you need
  },
}));

const CustomHeader = ({ text }) => {
  const classes = useStyles();

  return <h2 className={classes.header}>{text}</h2>;
};

export default CustomHeader;
