import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={850}
    height={1100}
    style={{
      backgroundColor: "#fff",
    }}
    viewBox="-0.5 -0.5 850 1100"
    {...props}
  >
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M55 150.5h10v890h10.5l-15.5 19-15.5-19H55Z"
      pointerEvents="all"
    />
    <path fill="none" d="M150 50h570v90H150z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 568,
            height: 1,
            paddingTop: 95,
            marginLeft: 151,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 32,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                fontWeight: 700,
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"Goal: Gain familiarity with"}
              <br />
              {"Machine Learning and AI Modelling"}
              <br />
              {"with Pytorch"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={435}
        y={105}
        fontFamily="Helvetica"
        fontSize={32}
        fontWeight="bold"
        textAnchor="middle"
      >
        {"Goal: Gain familiarity with..."}
      </text>
    </switch>
    <path fill="none" d="M120 177h510v40H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 197,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"Where traditional regression models struggle with volume and variety,'
              }
              <br />
              {
                'machine learning thrives, turning massive and diverse data sets into a gold mine of insights."'
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={201} fontFamily="Helvetica" fontSize={12}>
        {
          '"Where traditional regression models struggle with volume and variety,...'
        }
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 230h120v90H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 237,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"L08A: Introduction to Machine learning and Common Frameworks"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={200}
        y={249}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L08A: Introduction t..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 277h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 277-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M280 230h120v90H280z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 237,
            marginLeft: 281,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L08B: Overview of Supervised, Unsupervised, and Reinforcement learning"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={340}
        y={249}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L08B: Overview of Su..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M410 230h120v90H410z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 237,
            marginLeft: 411,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L08C: Handling categorical data, Outliers and Common Feature Engineering Methods"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={470}
        y={249}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L08C: Handling categ..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 640h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 640-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 589h120v90H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 596,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"L10A: Introduction to Tensorflow, Pytorch and Scikit learn"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={200}
        y={608}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L10A: Introduction t..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M540 230h120v90H540z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 237,
            marginLeft: 541,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"L08D: Introduction to Vectors and Embeddings"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={600}
        y={249}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L08D: Introduction t..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M280 589h120v90H280z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 596,
            marginLeft: 281,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L10B: Introduction to Tensors and Operations and Modelling Loss Functions"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={340}
        y={608}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L10B: Introduction t..."}
      </text>
    </switch>
    <path fill="none" d="M123 520h470v60H123z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 550,
            marginLeft: 125,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"Relying on black-box models is a gamble; using tools like PyTorch and TensorFlow'
              }
              <br />
              {
                "is an investment. The former might give quick answers, but the latter"
              }
              <br />
              <div>
                <span
                  style={{
                    backgroundColor: "initial",
                  }}
                >
                  {'builds long-term AI intuition and skill."'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={125} y={554} fontFamily="Helvetica" fontSize={12}>
        {
          '"Relying on black-box models is a gamble; using tools like PyTorch and TensorF...'
        }
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 804.5h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 804.5-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M670 230h120v90H670z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 237,
            marginLeft: 671,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L08E: Evaluation Metrics. Cross-Validation, Precision, Recall, F1-Score etc"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={730}
        y={249}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L08E: Evaluation Met..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 760h120v90H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 805,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L11A: Implementing Gradient Descent and Backpropagation in PyTorch"
              }
              <br />
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={200}
        y={809}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L11A: Implementing G..."}
      </text>
    </switch>
    <path fill="none" d="M120 340h560v60H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 370,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                "\"In the art of machine learning, it's not just the algorithm's elegance that matters, but the meticulous"
              }
              <br />
              {
                "documentation of our experiments, the diligent versioning of data and models, the vigilant monitoring"
              }
              <br />
              {
                "for data drift, the thorough exploration of scenarios, and the strategic hosting of models."
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={374} fontFamily="Helvetica" fontSize={12}>
        {
          "\"In the art of machine learning, it's not just the algorithm's elegance that matters, but the..."
        }
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M127 420h160v90H127z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 158,
            height: 1,
            paddingTop: 427,
            marginLeft: 128,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L09A: Iterative approach to Model development. Data drift monitoring, Model hosting for Inference, Model versioning"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={207}
        y={439}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L09A: Iterative approach t..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 470h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 470-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M280 760h150v90H280z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 148,
            height: 1,
            paddingTop: 767,
            marginLeft: 281,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L11B: Implementing Sequential Models, CNN, RNN, MLP and Trasformer models in Pytorch"
              }
              <br />
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={355}
        y={779}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L11B: Implementing Sequen..."}
      </text>
    </switch>
    <path fill="none" d="M120 865h460v40H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 885,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"AI algorithms adept at parsing through financial news and social media can'
              }
              <br />
              {
                'gauge market sentiment, offering investment managers a pulse on market trends"'
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={889} fontFamily="Helvetica" fontSize={12}>
        {
          '"AI algorithms adept at parsing through financial news and social media can...'
        }
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 970h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 970-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M140 920h130v110H140z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 128,
            height: 1,
            paddingTop: 975,
            marginLeft: 141,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L12A:\xA0Overview of BERT, GPT, and Other Transformer Models.Fine-Tuning Pretrained Models"
              }
              <br />
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={205}
        y={979}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L12A:\xA0Overview of BER..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M290 920h210v110H290z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 208,
            height: 1,
            paddingTop: 975,
            marginLeft: 291,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L12B:\xA0Using OpenAI CLIP for Image Classification and Retrieval.\xA0Leveraging CLIP\u2019s strength in understanding images without explicit training on specific image classes."
              }
              <br />
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={395}
        y={979}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L12B:\xA0Using OpenAI CLIP for Image C..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M317 420h160v90H317z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe flex-start",
            justifyContent: "unsafe center",
            width: 158,
            height: 1,
            paddingTop: 427,
            marginLeft: 318,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L09B: Identifying the appropriate Loss function to use to model."
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={397}
        y={439}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L09B: Identifying the appr..."}
      </text>
    </switch>
    <path fill="none" d="M25 1058h80v30H25z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 1,
            height: 1,
            paddingTop: 1073,
            marginLeft: 65,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                fontWeight: 700,
                whiteSpace: "nowrap",
              }}
            >
              {"Go to L7A"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={65}
        y={1077}
        fontFamily="Helvetica"
        fontSize={12}
        fontWeight="bold"
        textAnchor="middle"
      >
        {"Go to L7A"}
      </text>
    </switch>
    <path fill="none" d="M120 690h530v60H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 720,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                "\"Backpropagation is the key to unlocking deep learning's potential."
              }
              <br />
              {
                "By implementing backpropagation in PyTorch or Python, one can gain a deeper understanding"
              }
              <br />
              <div>
                <span
                  style={{
                    backgroundColor: "initial",
                  }}
                >
                  {'of how deep neural networks learn and evolve."'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={724} fontFamily="Helvetica" fontSize={12}>
        {
          "\"Backpropagation is the key to unlocking deep learning's potential...."
        }
      </text>
    </switch>
    {/* <switch>
      <a
        xlinkHref="https://www.drawio.com/doc/faq/svg-export-text-problems"
        target="_blank"
        transform="translate(0 -5)"
      >
        <text x="50%" y="100%" fontSize={10} textAnchor="middle">
          {"Text is not SVG - cannot display"}
        </text>
      </a>
    </switch> */}
  </svg>
)
export default SvgComponent
