import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog'; // import Dialog component for full-screen view
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'; // import Close icon for the button
import './styles/QuizSlide.css';
import FeedbackContent from './FeedbackContent';

const QuizSlide = ({ mediaType, mediaSrc, question, options, onAnswer, correctFeedback, wrongFeedback }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [openFullScreen, setOpenFullScreen] = useState(false); // State to handle full-screen mode

  const handleSubmit = () => {
    const result = onAnswer(selectedOption);
    setIsCorrect(result);
  };

  const handleImageClick = () => {
    setOpenFullScreen(true); // Open the image in full-screen mode
  };

  const handleClose = () => {
    setOpenFullScreen(false); // Close the full-screen view
  };

  const customBlue = '#5c85d6';

  return (
    <div className="quiz-slide">
      <div className="media-panel">
        {mediaType === 'image' && (
                    <>
                    <img src={mediaSrc} alt="quiz media" onClick={handleImageClick} style={{ cursor: 'zoom-in' }} />
                    <p className="click-to-enlarge">Click to enlarge</p>
                  </>
        
        )}
        {mediaType === 'video' && (
          <video controls>
            <source src={mediaSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="question-panel">
        <h3>{question}</h3>
        {options.map((option, idx) => (
          <button
            key={idx}
            onClick={() => setSelectedOption(idx)}
            className={selectedOption === idx ? "selected" : ""}
          >
            {option}
          </button>
        ))}
        <Button 
          variant="contained" 
          style={{ backgroundColor: customBlue, color: '#fff' }} 
          onClick={handleSubmit}
        >
          Confirm
        </Button>
        {isCorrect !== null && (
          <div className={`feedback-container ${isCorrect ? 'correct' : 'incorrect'}`}>
            {isCorrect ? correctFeedback : wrongFeedback}
          </div>
        )}
      </div>
      {/* Full-screen image dialog */}
      <Dialog
        open={openFullScreen}
        onClose={handleClose}
        aria-labelledby="full-screen-image-dialog"
        fullWidth
        maxWidth="lg" // You can adjust this value as needed
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 0, top: 0, color: 'white' }}
        >
          <CloseIcon />
        </IconButton>
        <img src={mediaSrc} alt="Full screen" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </div>
  );
};

export default QuizSlide;


