import React, { useState } from 'react';
import axios from 'axios';

const SearchResultCard = ({ result }) => (
    <div style={{ margin: '10px', border: '1px solid #ccc', padding: '10px', maxWidth: '300px' }}>
        <img src={result.images} alt={result.alt} style={{ maxWidth: '100%' }} />
        <h3>{result.name}</h3>
        <p>{result.description}</p>
        <p>{result.city}</p>
        <a href={result.link} target="_blank" rel="noopener noreferrer">Learn More</a>
    </div>
);

export const QdrantQueryComponent = ({ addBlock }) => {
    const [queryInput, setQueryInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchHistory, setSearchHistory] = useState([]);
    const [historyMinimized, setHistoryMinimized] = useState({});

    const queryQdrant = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post('https://qdrant-service-o4yzxx3w5q-uw.a.run.app/api/search', {
                query: queryInput
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            setSearchResults(response.data.result);
            setSearchHistory([{ query: queryInput, results: response.data.result }, ...searchHistory]);
            setLoading(false);
        } catch (error) {
            console.error('Error making the query:', error);
            setLoading(false);
            addBlock('An error occurred while making the Qdrant query.', 'system');
        }
    };

    const toggleHistoryMinimize = (index) => {
        setHistoryMinimized(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    return (
        <div>
            <input type="text" placeholder="Enter search query" value={queryInput} onChange={(e) => setQueryInput(e.target.value)} />
            <button onClick={queryQdrant} disabled={loading}>
                {loading ? 'Searching...' : 'Search with Qdrant'}
            </button>
            {loading && <p>Loading...</p>}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                {searchResults.map((result, index) => (
                    <SearchResultCard key={index} result={result} />
                ))}
            </div>
            {searchHistory.length > 0 && <h2>Search History</h2>}
            {searchHistory.map((history, index) => (
                <div key={index} style={{ marginBottom: '20px' }}>
                    <button onClick={() => toggleHistoryMinimize(index)} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer', marginBottom: '5px' }}>
                        {historyMinimized[index] ? 'Expand' : 'Minimize'} "{history.query}" Results
                    </button>
                    {!historyMinimized[index] && (
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {history.results.map((result, resultIndex) => (
                                <SearchResultCard key={`${index}-${resultIndex}`} result={result} />
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
