import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={850}
    height={1100}
    style={{
      backgroundColor: "#fff",
    }}
    viewBox="-0.5 -0.5 850 1100"
    {...props}
  >
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M55 150.5h10v890h10.5l-15.5 19-15.5-19H55Z"
      pointerEvents="all"
    />
    <path fill="none" d="M80 42h640v90H80z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 638,
            height: 1,
            paddingTop: 87,
            marginLeft: 81,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 32,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                fontWeight: 700,
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "Goal: Gain expertise in Machine Learning and AI based Time Series Modelling"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={400}
        y={97}
        fontFamily="Helvetica"
        fontSize={32}
        fontWeight="bold"
        textAnchor="middle"
      >
        {"Goal: Gain expertise in Machine Learning..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M151 390h120v90H151z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 435,
            marginLeft: 152,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L04A: Components of Time Series. Trends, Seasonality, Cycles and Noise"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={211}
        y={439}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L04A: Components of..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M60 434.5h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m138.88 434.5-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path fill="none" d="M120 330h500v40H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 350,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"Time series analysis in Python is all about adding a temporal dimension to data analysis,'
              }
              <br />
              {
                'providing a deeper understanding of volatility and trends over time."'
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={354} fontFamily="Helvetica" fontSize={12}>
        {
          '"Time series analysis in Python is all about adding a temporal dimension to data an...'
        }
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M300 390h140v90H300z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 138,
            height: 1,
            paddingTop: 435,
            marginLeft: 301,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L04B: Introduction to Cross Sectional, Longitudinal Time Series and Mutivariate Time series"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={370}
        y={439}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L04B: Introduction to C..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M151 550h191.25v90H151z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 595,
            marginLeft: 152,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L05B: Classical Time series Models. AutoRegerssive (AR), Moving Average (MA). ARIMA and Seasonal ARIMA and Vector Regression (VAR)"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={247}
        y={599}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L05B: Classical Time series Mode..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M64 594.5h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m142.88 594.5-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path fill="none" d="M120 657h450v40H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 677,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"The power of machine learning in time series lies in its ability to uncover hidden'
              }
              <br />
              {"patterns in data that the human eye simply can't see.\""}
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={681} fontFamily="Helvetica" fontSize={12}>
        {
          '"The power of machine learning in time series lies in its ability to uncove...'
        }
      </text>
    </switch>
    <path fill="none" d="M120 500h540v40H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 520,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"In the rush towards complex AI models, we must not forget the power of classical statistics'
              }
              <br />
              {
                'in time series analysis. They often provide the clarity and simplicity needed for robust forecasts."'
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={524} fontFamily="Helvetica" fontSize={12}>
        {
          '"In the rush towards complex AI models, we must not forget the power of classical statisti...'
        }
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M72.5 751v-10h60v-10.5l19 15.5-19 15.5V751Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M151 843h191.25v90H151z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 888,
            marginLeft: 152,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"L07A: XGBoost/LightGBM."}
              <br />
              {"Gradient boosting frameworks"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={247}
        y={892}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L07A: XGBoost/LightGBM...."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M359 961h191.25v90H359z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 1006,
            marginLeft: 360,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L07E: DeepAR. A probabilistic forecasting framework with recurrent neural network"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={455}
        y={1010}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L07E: DeepAR. A probabilistic fo..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M560 843h191.25v90H560z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 888,
            marginLeft: 561,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L07C: Dynamic Time Wrapping (DTW). A algorithms for time series classification. LSTMS and RNN"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={656}
        y={892}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L07C: Dynamic Time Wrapping (DTW..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M151 963h191.25v90H151z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 1008,
            marginLeft: 152,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L07D: Temporal Convolution networks (TCNs, Attention and Transformer Models. TCNs are good at handling long range data"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={247}
        y={1012}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L07D: Temporal Convolution netwo..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M358 843h191.25v90H358z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 888,
            marginLeft: 359,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L07B: Decision Tree models for capturing non-linear patterns and providing interpretable models"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={454}
        y={892}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L07B: Decision Tree models for c..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M67 887.5h73.63"
      pointerEvents="stroke"
    />
    <path
      stroke="#000"
      strokeMiterlimit={10}
      d="m145.88 887.5-7 3.5 1.75-3.5-1.75-3.5Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M151 703h191.25v90H151z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 748,
            marginLeft: 152,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {
                "L06: Introduction to Machine Learning and AI Frameworks (Detour. Come back to this point)"
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={247}
        y={752}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L06: Introduction to Machine Lea..."}
      </text>
    </switch>
    <path
      fill="#FFF"
      stroke="#000"
      d="M560 963h191.25v90H560z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 189,
            height: 1,
            paddingTop: 1008,
            marginLeft: 561,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"L07F: Dimensionality reduction with PCA"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={656}
        y={1012}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L07F: Dimensionality reduction w..."}
      </text>
    </switch>
    <path
      fill="none"
      stroke="#000"
      strokeMiterlimit={10}
      d="M343.75 757v-10h60v-10.5l19 15.5-19 15.5V757ZM70.5 265v-10h60v-10.5l19 15.5-19 15.5V265Z"
      pointerEvents="all"
    />
    <path
      fill="#FFF"
      stroke="#000"
      d="M147 220h120v90H147z"
      pointerEvents="all"
    />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 118,
            height: 1,
            paddingTop: 265,
            marginLeft: 148,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "normal",
                overflowWrap: "normal",
              }}
            >
              {"L01: Python Data"}
              <br />
              {"Wrangling"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={207}
        y={269}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"L01: Python Data..."}
      </text>
    </switch>
    <path fill="none" d="M60 270h60v30H60z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 1,
            height: 1,
            paddingTop: 285,
            marginLeft: 90,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {"Detour"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={90}
        y={289}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"Detour"}
      </text>
    </switch>
    <path fill="none" d="M60 760h60v30H60z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe center",
            width: 1,
            height: 1,
            paddingTop: 775,
            marginLeft: 90,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "center",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {"Detour"}
            </div>
          </div>
        </div>
      </foreignObject>
      <text
        x={90}
        y={779}
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
      >
        {"Detour"}
      </text>
    </switch>
    <path fill="none" d="M120 140h390v60H120z" pointerEvents="all" />
    <switch transform="translate(-.5 -.5)">
      <foreignObject
        width="100%"
        height="100%"
        pointerEvents="none"
        requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
        style={{
          overflow: "visible",
          textAlign: "left",
        }}
      >
        <div
          xmlns="http://www.w3.org/1999/xhtml"
          style={{
            display: "flex",
            alignItems: "unsafe center",
            justifyContent: "unsafe flex-start",
            width: 1,
            height: 1,
            paddingTop: 170,
            marginLeft: 122,
          }}
        >
          <div
            data-drawio-colors="color: rgb(0, 0, 0);"
            style={{
              boxSizing: "border-box",
              fontSize: 0,
              textAlign: "left",
            }}
          >
            <div
              style={{
                display: "inline-block",
                fontSize: 12,
                fontFamily: "Helvetica",
                color: "#000",
                lineHeight: 1.2,
                pointerEvents: "all",
                whiteSpace: "nowrap",
              }}
            >
              {
                '"In the dynamic world of time series data, Python enhances machine'
              }
              <br />
              {
                "learning's continuous learning capability, enabling models to adapt"
              }
              <br />
              {
                'to changing patterns and trends efficiently with its robust libraries."'
              }
            </div>
          </div>
        </div>
      </foreignObject>
      <text x={122} y={174} fontFamily="Helvetica" fontSize={12}>
        {'"In the dynamic world of time series data, Python enhances machin...'}
      </text>
    </switch>
    {/* <switch>
      <a
        xlinkHref="https://www.drawio.com/doc/faq/svg-export-text-problems"
        target="_blank"
        transform="translate(0 -5)"
      >
        <text x="50%" y="100%" fontSize={10} textAnchor="middle">
          {"Text is not SVG - cannot display"}
        </text>
      </a>
    </switch> */}
  </svg>
)
export default SvgComponent
