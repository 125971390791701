import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MyCarousel from "../../../components/MyCarousel";
// import MySlider from "./MySlider.js";
import MarkdownWithVideo from "./MarkdownWithVideo"
import FeedbackContent from "./FeedbackComponent"

const useStyles = makeStyles({

  root: {
    backgroundColor: '#fff4e4', // This will set the background color for the entire component
    // ... other styles
  },
  paragraph: {
    color: "black",
    fontWeight: 'normal', // Add this line to set font weight to normal
  },
  header: {
    color: "lightgray",
  },
});


const P = props => {
  const classes = useStyles();
  return <Typography variant="body1" className={classes.paragraph} {...props} />;
};

const H1 = props => {
  const classes = useStyles();
  return <Typography variant="h1" className={classes.header} {...props} />;
};

const H2 = props => {
  const classes = useStyles();
  return <Typography variant="h2" className={classes.header} {...props} />;
};


const components = {
  a: props => <Link {...props} />,
  p: P,
  // h1: H1,
  // h2: H2,
  MyCarousel,
  // MySlider,
  MarkdownWithVideo,
  FeedbackContent
  // ... other overrides or custom components
};

export default components;
