import React, { useState } from 'react';
import { Dialog, DialogContent, IconButton, Typography, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    border: '2px solid white',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[200],  // Added a subtle background
    padding: theme.spacing(1),                // Increased padding for a larger touch area
    borderRadius: '50%',                      // Circle shape
    '&:hover': {
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error.light, // More noticeable hover effect
      transform: 'scale(1.1)',                // Slightly increase the size on hover
      transition: 'transform 0.3s ease',      // Smooth transition for transform
    }
  },
  marker: {
    position: 'absolute',
    left: props => props.left,
    top: props => props.top,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50px',
    height: '50px',
    border: '3px solid white',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      border: '3px solid white',
    },
  },
}));

const MarkerDialog = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMarkerClick = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className={classes.marker} onClick={handleMarkerClick} aria-label="marker">
        <Typography variant="h6" style={{ fontWeight: 'bold', color: theme.palette.common.white }}>{props.text}</Typography>
      </div>

      <Dialog fullScreen open={isModalOpen} onClose={handleClose} classes={{paper: classes.dialog}}>
        <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MarkerDialog;
