// import React, { useContext, useState, useEffect, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
// import GoogleSignInButton from './GoogleSignInButton';
// import PaymentContext from './paymentContext';
// import { Link as RouterLink } from 'react-router-dom';

// // Material-UI imports
// import { Button, Typography, Container, CircularProgress, Link, Tooltip } from '@mui/material';
// import FileCopyIcon from '@mui/icons-material/FileCopy';
// import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

// const AccessPage = ({ nextRoute = "/int_ml_p" }) => {

//     const { user, paymentFlag, setPaymentFlag, checkPaymentStatus } = useContext(PaymentContext);
//     const [loading, setLoading] = useState(true);
//     const [isCopied, setIsCopied] = useState(false); // For copy feedback
//     const history = useHistory();
//     const stripeUrl = "http://buy.stripe.com/test_14k6ryaW8bRJgso004";

//     useEffect(() => {
//         if (user) {
//             checkPaymentStatus(user.email).then(paymentStatus => {
//                 setPaymentFlag(paymentStatus);
//                 setLoading(false);
//                 if (paymentStatus) {
//                     history.push(nextRoute);
//                 }
//             }).catch(error => {
//                 console.error("Error checking payment status:", error);
//                 setLoading(false);
//             });
//         } else {
//             setLoading(false);
//         }
//     }, [user, history, nextRoute]);

//     const handleCopyToClipboard = () => {
//         navigator.clipboard.writeText(stripeUrl).then(() => {
//             setIsCopied(true);
//             setTimeout(() => setIsCopied(false), 2000); // Hide the tooltip after 2 seconds
//         });
//     };

//     if (loading) {
//         return (
//             <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
//                 <CircularProgress />
//             </Container>
//         );
//     }

//     return (
//         <Container maxWidth="sm" style={{ padding: '40px 0' }}>
//             {!user ? (
//                 <div>
//                     <Typography variant="h4" gutterBottom>
//                         Welcome to Our Platform!
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                         Please sign in to access premium content.
//                     </Typography>
//                     <GoogleSignInButton />
//                 </div>
//             ) : !paymentFlag ? (
//                 <div>
//                     <Typography variant="h4" gutterBottom>
//                         Welcome back, {user.displayName}! {user.email} {paymentFlag}
//                     </Typography>
//                     <Typography variant="body1" gutterBottom>
//                         Complete your payment to access premium content.
//                     </Typography>

//                     <Tooltip title="Link Copied!" open={isCopied}>
//                         <Button 
//                             variant="contained" 
//                             color="primary" 
//                             startIcon={<FileCopyIcon />} 
//                             onClick={handleCopyToClipboard}
//                         >
//                             Copy Link
//                         </Button>
//                     </Tooltip>

//                     <Typography variant="body2" gutterBottom style={{ margin: '20px 0' }}>
//                         After copying, you can paste the link into your browser to complete the payment.
//                     </Typography>

//                     <Button 
//                         variant="outlined" 
//                         color="primary" 
//                         startIcon={<OpenInBrowserIcon />} 
//                         onClick={() => window.open(stripeUrl, "_blank")}
//                     >
//                         Try Opening in Browser
//                     </Button>
//                     <Link href={stripeUrl} target="_blank" variant="body1">
//                         Access Premium Content
//                     </Link>
//                 </div>
//             ) : (
//                 <Link component={RouterLink} to={nextRoute} variant="body1">
//                     Access Premium Content
//                 </Link>
//             )}
//         </Container>
//     );
// };

// export default AccessPage;




import React, { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import GoogleSignInButton from './GoogleSignInButton';
import PaymentContext from './paymentContext';
import { Link as RouterLink } from 'react-router-dom';

// Material-UI imports
import { Button, Typography, Container, CircularProgress, Link, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';

const AccessPage = ({ nextRoute = "/int_ml_p" }) => {

    const { user, paymentFlag, setPaymentFlag, checkPaymentStatus } = useContext(PaymentContext);
    const [loading, setLoading] = useState(true);
    const [isCopied, setIsCopied] = useState(false); // For copy feedback
    const history = useHistory();
    const stripeUrl = "http://buy.stripe.com/test_14k6ryaW8bRJgso004";

    useEffect(() => {
        if (user) {
            checkPaymentStatus(user.email).then(paymentStatus => {
                setPaymentFlag(paymentStatus);
                setLoading(false);
                // If user has made a payment, redirect to the provided nextRoute
                if (paymentStatus) {
                    history.push(nextRoute);
                }
            }).catch(error => {
                console.error("Error checking payment status:", error);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, [user, history, nextRoute]);

    const handleCopyToClipboard = () => {
        navigator.clipboard.writeText(stripeUrl).then(() => {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000); // Hide the tooltip after 2 seconds
        });
    };

    if (loading) {
        return (
            <Container maxWidth="sm" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="sm" style={{ padding: '40px 0' }}>
            {!user ? (
                <div>
                    <Typography variant="h4" gutterBottom>
                        Welcome to Our Platform!
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Please sign in to access premium content.
                    </Typography>
                    <GoogleSignInButton />
                </div>
            ) : !paymentFlag ? (
                <div>
                    <Typography variant="h4" gutterBottom>
                        Welcome back, {user.displayName}! {user.email} {paymentFlag}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        Complete your payment to access premium content.
                    </Typography>

                    <Tooltip title="Link Copied!" open={isCopied}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            startIcon={<FileCopyIcon />} 
                            onClick={handleCopyToClipboard}
                        >
                            Copy Link
                        </Button>
                    </Tooltip>

                    <Typography variant="body2" gutterBottom style={{ margin: '20px 0' }}>
                        After copying, you can paste the link into your browser to complete the payment.
                    </Typography>

                    <Button 
                        variant="outlined" 
                        color="primary" 
                        startIcon={<OpenInBrowserIcon />} 
                        onClick={() => window.open(stripeUrl, "_blank")}
                    >
                        Try Opening in Browser
                    </Button>
                    <Link href={stripeUrl} target="_blank" variant="body1">
                        Access Premium Content
                    </Link>
                </div>
            ) : (
                <Link component={RouterLink} to={nextRoute} variant="body1">
                    Access Premium Content
                </Link>
            )}
        </Container>
    );
};

export default AccessPage;





