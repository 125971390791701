// MetaTags.js

import React from 'react';

const MetaTags = ({ title, description, imageUrl, pageUrl }) => (
  <>
    {/* Open Graph tags */}
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={imageUrl} />
    <meta property="og:url" content={pageUrl} />

    {/* Twitter Card tags (optional but recommended) */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={imageUrl} />
  </>
);

export default MetaTags;
