import React, { useState } from 'react';
import './MySliderstyles.css';

const MySlider = ({ topics }) => {
    const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);

    const goToNextSlide = () => {
        setSelectedTopicIndex((prevIndex) => 
            prevIndex < topics.length - 1 ? prevIndex + 1 : prevIndex
        );
    }

    const goToPrevSlide = () => {
        setSelectedTopicIndex((prevIndex) => 
            prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
    }

    return (
        <div className="slider-container">
            <div className="slide-navigation">
                <button className="nav-button" onClick={goToPrevSlide}>Previous</button>
                <button className="nav-button" onClick={goToNextSlide}>Next</button>
            </div>
            {topics.map(({ component: Component }, index) => (
                index === selectedTopicIndex && <Component key={index} />
            ))}
        </div>
    );
};

export default MySlider;
