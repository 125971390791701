import React, { useState, useEffect } from 'react';
import { MDXProvider } from '@mdx-js/react';
import components from './mdx-components';

function Content({ topic, basePath }) {
  const [MdxContent, setMdxContent] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Check if topic is null or empty
    if (!topic || topic.trim() === '') {
      setError("No topic selected.");
      return;
    }

    // Dynamically import the MDX file using Babel and MDX loader based on the topic and basePath
    import(`!babel-loader!@mdx-js/loader!../${basePath}/mdx-content/${topic}.mdx`)
      .then(module => setMdxContent(() => module.default))
      .catch(err => {
        console.error(err);
        setError("Sorry, the content could not be loaded.");
      });
  }, [topic, basePath]);

  if (error) return <div>{error}</div>;
  if (!MdxContent) return <div>Loading...</div>;

  return (
    <MDXProvider components={components}>
      <MdxContent />
    </MDXProvider>
  );
}

export default Content;
