import React, { useEffect } from 'react';

const StripeBuyButton = () => {
  const centerStyle = {
    display: 'flex',
    flexDirection: 'column', // to ensure vertical stacking
    justifyContent: 'center',
    alignItems: 'center',
    height: '40vh'
  };

  const noteStyle = {
    padding: '20px',
    backgroundColor: '#f7f7f7',
    borderRadius: '5px',
    boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
    textAlign: 'center',
    marginBottom: '10px',
    width: '100%', // to ensure it takes full width of the parent
    maxWidth: '400px', // to limit its width for better readability
    color: 'black' // to fit your dark theme
  };

  useEffect(() => {
    // Append the Stripe script to the document
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/buy-button.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Remove the Stripe script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={centerStyle}>
      <div style={noteStyle}>
        <p>
          <strong>Note:</strong> Clicking the button below will open a new, secure Stripe link.
          Please ensure you enter the same email address as used for Gmail/Google. This will be a subscription.
        </p>
      </div>
      <stripe-buy-button
        buy-button-id="buy_btn_1O3VuaG0QNwLjqA66EibvwIP"
        publishable-key="pk_test_51KUGSVG0QNwLjqA6Dtkq6F38ASWrfGwt3ArwzF6f60Gn2DLsWR3nPmfqaYXiGLlSU7W4A8IJ4OoJrzlJMUMgNwRJ00KAa6sdYl"
      />
    </div>
  );
};

export default StripeBuyButton;
