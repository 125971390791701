import React from 'react';
import { Card, CardActionArea, CardContent, Typography, List, ListItem } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const ScrollCard = ({ title, bulletPoints, sectionId, color }) => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <CardActionArea onClick={() => scrollToSection(sectionId)} sx={{ 
        width: { xs: '100%', sm: '60%', md: '30%' }, 
        margin: '0 2%'
      }}>
      <Card sx={{ bgcolor: color ? color : 'grey.900', color: 'white' }}>
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' }
            }}>
            {title} <ArrowForwardIcon sx={{ marginLeft: 'auto' }} />
          </Typography>
          <List sx={{ marginTop: 1 }}>
            {bulletPoints && bulletPoints.map((point, index) => (
              <ListItem key={index} sx={{ padding: '0.5rem 0', fontSize: '0.9rem' }}>
                <Typography variant="body2">{point}</Typography>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export default ScrollCard;
