import React from 'react';
import PropTypes from 'prop-types';
import { Container, Typography, Box, Avatar } from '@mui/material';
import styled from '@emotion/styled';

const StyledContainer = styled(Container)`
  background-color: #f4f4f7;
  border-radius: 8px;
  padding: 3rem 2rem;
  color: #333;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const TitleSpan = styled('span')`
  color: #333;
`;

const ResponsiveTypography = styled(Typography)`
  @media (max-width: 600px) {
    font-size: 2.0rem;
  }
`;

const SleekTypography = styled(Typography)`
  font-weight: 300;
  margin-bottom: 1rem;
`;

const AuthorExperience = ({ experience }) => {
  return experience.map((item, index) => (
    <Box key={index} mb={4}>
      <Typography variant="h6" gutterBottom>
        {item.role} at {item.company}
      </Typography>
      {item.department && (
        <Typography variant="subtitle1">
          Department: {item.department}
        </Typography>
      )}
      <Typography variant="body1" color="textSecondary">
        {item.expertise}
      </Typography>
    </Box>
  ));
};

AuthorExperience.propTypes = {
  experience: PropTypes.arrayOf(
    PropTypes.shape({
      company: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      department: PropTypes.string,
      expertise: PropTypes.string.isRequired
    })
  ).isRequired
};

const AuthorSection = ({ author }) => {
  return (
    <StyledContainer>
      <Box display="flex" flexDirection="column" alignItems="center" py={5}>
        {author.picture && (
          <Avatar alt={author.name} src={author.picture} sx={{ width: 120, height: 120, mb: 3 }} />
        )}
        <ResponsiveTypography variant="h4" gutterBottom align="center">
          <TitleSpan>{author.name}</TitleSpan>
        </ResponsiveTypography>
        <SleekTypography variant="h6" align="center" color="inherit">
          {author.description}
        </SleekTypography>
        <Box mt={4} width="100%">
          <AuthorExperience experience={author.experience} />
        </Box>
      </Box>
    </StyledContainer>
  );
};

AuthorSection.propTypes = {
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    picture: PropTypes.string,
    experience: PropTypes.arrayOf(
      PropTypes.shape({
        company: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        department: PropTypes.string,
        expertise: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired
};

export default AuthorSection;
