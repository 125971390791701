import React from 'react';
import FilterButton from './FilterButton';

const Filters = ({ filters, selectedFilters, onToggleFilter, onClearAll }) => (
  <div style={{ marginBottom: '20px' }}>
    <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
      {filters.map((filter) => (
        <FilterButton
          key={filter}
          label={filter}
          isSelected={selectedFilters.includes(filter)}
          onToggle={onToggleFilter}
        />
      ))}
    </div>
    <button
      onClick={onClearAll}
      style={{
        padding: '8px 16px',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        borderRadius: '4px',
        cursor: 'pointer',
      }}
    >
      Clear All
    </button>
  </div>
);

export default Filters;