import React from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const FullWidthImageCard = (props) => {
    return (
        <Grid container justifyContent="center" alignItems="center">
            <Box
                component="img"
                sx={{
                    width: '100%', // Set width to 100% to fill the container
                    height: 'auto', // Set height to auto to maintain aspect ratio
                }}
                alt="A figure."
                src={props.img}
            />
        </Grid>
    );
};

export default FullWidthImageCard;
